<template>
  <v-list-item
    link
    lines="two"
    :class="{ 'v-list-item--active': item.active }"
    @click="emit('click')"
  >
    <template #prepend>
      <v-img :src="item.avatar" height="50" width="50" class="mr-3" />
    </template>
    <v-list-item-title>
      {{ item.title }}
    </v-list-item-title>
    <v-list-item-subtitle style="font-size: 1rem; line-height: 18px; -webkit-line-clamp: 10; line-clamp: 10">
      {{ item.text }}
    </v-list-item-subtitle>
  </v-list-item>
</template>

<script setup>
import { toRef } from "vue";
const props = defineProps({
  item: { type: Object, required: true }
});

const item = toRef(props, "item");

const emit = defineEmits(["click"]);
</script>
