<template>
  <v-fade-transition mode="out-in">
    <v-card v-if="loading" key="loader" flat>
      <v-skeleton-loader type="card" />
      <v-skeleton-loader type="text" class="mx-6 pa-3" />
      <v-card-text>
        <v-skeleton-loader type="list-item-avatar-three-line@2" />
      </v-card-text>
    </v-card>
    <v-card v-else key="task" align="start" flat>
      <slot name="header" />
      <v-card-subtitle v-if="$slots['task-header']" class="py-3">
        <slot name="task-header" />
      </v-card-subtitle>
      <v-row class="ma-0 px-3" dense>
        <v-col cols="12">
          <v-card flat class="pa-3" variant="outlined">
            <h4 style="font-size: 1.2em" class="mb-1">Description</h4>
            <div class="mx-n3 my-3">
              <v-divider />
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div style="font-size: 1rem !important">
              <div
                data-testid="task-description"
                class="mb-3"
                style="white-space: pre-wrap"
                v-html="sanitizedDescription"
              />
            </div>

            <v-scroll-y-transition mode="out-in">
              <div v-if="allDocuments.length">
                <div class="mx-n3 my-3">
                  <v-divider />
                </div>

                <div data-testid="related-documents">
                  <h4 style="font-size: 1.2em" class="mb-1">
                    Related Documents
                  </h4>
                  <div class="mx-n3 my-3">
                    <v-divider />
                  </div>
                  <a
                    v-for="(document, index) in allDocuments"
                    :key="document.uid"
                    class="ma-1"
                    :data-testid="`document-${index}`"
                    v-bind="downloadRelatedDocument(document.uid)"
                  >
                    {{ document.name }}
                    <span v-if="document.fileSize" class="text-grey">
                      ({{ document.fileSize }})
                    </span>
                    <v-icon color="primary" :icon="mdiDownload" />
                  </a>
                </div>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </v-col>

        <v-scroll-y-transition mode="out-in">
          <v-col
            v-if="messages.length"
            cols="12"
            class="my-3"
            data-testid="task-messages"
          >
            <div ref="parentElement" class="task-body pa-0">
              <task-message
                v-for="(message, index) in messages"
                :key="message.id"
                class="mb-3"
                :data-testid="`message-${index}`"
                :message="message"
                @delete-message="$emit('delete-message', $event)"
              />
              <div ref="scrollToElement"></div>
            </div>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
      <v-divider class="mt-3" />
      <v-card-actions class="px-1 py-0">
        <task-input
          :key="inputKey"
          :can-change-status="canChangeStatus"
          :is-complete="isComplete"
          :create-func="createFunc"
        >
          <template #extra-actions>
            <slot name="extra-actions" />
          </template>
        </task-input>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
</template>

<script setup>
import { sanitize } from "@/html-sanitizer";
import TaskMessage from "@/components/shared/tasks/TaskMessage.vue";
import TaskInput from "@/components/shared/tasks/TaskInput.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { setNoteFromRequest } from "@/models/Note";
import { initSocketForChat } from "@/util/socket-helper-service";
import { getDocumentUrl } from "@/api/documents.service";

import { mdiDownload } from "@mdi/js";

import { computed, nextTick, onUnmounted, ref, toRefs, watch } from "vue";
import { useDisplay } from "vuetify";

const emit = defineEmits(["submit", "new-note", "delete-message"]);
const props = defineProps({
  canChangeStatus: Boolean,
  isComplete: Boolean,
  loading: Boolean,
  pusherId: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  messages: {
    type: Array,
    default: () => []
  },
  documents: {
    type: Array,
    default: () => []
  },
  createFunc: {
    type: Function,
    required: true
  }
});

const { mdAndDown } = useDisplay();

const { pusherId, messages, documents } = toRefs(props);

const inputKey = ref(new Date().getTime());

const allDocuments = computed(() => {
  return messages.value.reduce(
    (acc, message) => {
      if (message.documents.length) acc.push(...message.documents);
      return acc;
    },
    [...documents.value]
  );
});

const sanitizedDescription = computed(() => {
  if (!props.description) return "";
  return sanitize(props.description.trim());
});

function handleSub(v) {
  inputKey.value = new Date().getTime();
  unsubscribe();
  if (v) subscribe();
}

function downloadRelatedDocument(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}

function unsubscribe() {
  if (pusher?.disconnect) pusher.disconnect();
}

let pusher = null;
function subscribe() {
  const newNoteHandler = ({ note: rawNote }) => {
    emit("new-note", setNoteFromRequest(rawNote));
  };
  pusher = initSocketForChat(pusherId.value, { newNoteHandler });
}

watch(pusherId, handleSub, { immediate: true });
onUnmounted(unsubscribe);

const scrollToElement = ref(null); // templatref
const parentElement = ref(null);
watch(
  [messages, scrollToElement, parentElement],
  () => {
    nextTick(() => {
      if (!scrollToElement.value || !scrollToElement.value) return;
      parentElement.value.scrollTo(0, scrollToElement.value.offsetTop);
    });
  },
  { immediate: true }
);
</script>

<style lang="scss">
.task-body {
  min-height: min(calc(10vh - 61.6px), 9rem);
  max-height: min(calc(60vh - 61.6px), 35rem);
  overflow: hidden;
  overflow-y: auto;
}
</style>
