<template>
  <v-card>
    <v-card-title> Update Task </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="my-0">
        <v-col cols="12" md="6">
          <date-input
            v-model="task.day"
            success
            hide-details
            data-testid="follow-up-date"
            label="Follow Up Day"
          />
        </v-col>

        <v-col cols="12" md="6">
          <text-field
            v-model="task.time"
            :prepend-inner-icon="mdiClockOutline"
            data-lpignore="true"
            success
            hide-details
            data-testid="follow-up-time"
            label="Follow Up Time"
            type="time"
          />
        </v-col>

        <v-col v-if="props.isPhysicianStatement || props.isExam" cols="12">
          <text-field
            v-model="task.orderNumber"
            :prepend-inner-icon="mdiPound"
            data-lpignore="true"
            :success="Boolean(task.orderNumber)"
            hide-details
            data-testid="order-number"
            label="Order Number"
          />
        </v-col>

        <v-col cols="12">
          <autocomplete-field
            v-model="task.assignedTo"
            success
            hide-details
            label="Assigned To"
            item-title="name"
            return-object
            data-testid="assigned-to"
            :prepend-inner-icon="mdiAccount"
            :items="assignableOptions"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none mr-2"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>

      <app-button
        class="text-none"
        color="primary"
        data-testid="save-task"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";
import { mdiClockOutline, mdiPound, mdiAccount } from "@mdi/js";
import { parseErrorMessage } from "@/util/helpers";
import { parse, format } from "@/util/date-util";
import { ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

const props = defineProps({
  followUpDate: {
    type: String,
    required: true
  },
  assignedTo: {
    type: Object,
    required: true
  },
  updateFn: {
    type: Function,
    required: true
  },
  assignedToOptions: {
    type: Array,
    required: true
  },
  orderNumber: {
    type: String,
    required: false,
    default: null
  },
  isPhysicianStatement: Boolean,
  isExam: Boolean
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const saving = ref(false);

const assignableOptions = [...props.assignedToOptions];
assignableOptions.sort((a, b) => a.name.localeCompare(b.name));

const date = new Date(props.followUpDate);
const task = ref({
  day: format(date, "yyyy-MM-dd"),
  time: format(date, "HH:mm"),
  assignedTo: {
    id: props.assignedTo.id,
    name: props.assignedTo.name,
    type: props.assignedTo.type
  },
  orderNumber: props.orderNumber
});

async function save() {
  try {
    saving.value = true;

    const date = parse(
      `${task.value.day} ${task.value.time}`,
      "yyyy-MM-dd HH:mm"
    );

    await props.updateFn({
      assignedTo: task.value.assignedTo,
      date,
      orderNumber: task.value.orderNumber
    });
    dialog.closeDialog({ task: { followUp: date } });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
