import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";
import {
  setProductFromRequest,
  setTemporaryInsuranceProfileFromRequest,
  setProductAliasFromRequest
} from "@/models/Product";

const baseUrl = "/api/boss/products";

export async function uploadConsumerProductGuide(id, file) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({
      file,
      category: "Consumer Product Guide"
    })
  );
}

export async function productCreate(params) {
  const { data } = await getHttpClient().post(`${baseUrl}`, params);

  return data.product.id;
}
export function updateProduct(id, product) {
  return getHttpClient().put(`${baseUrl}/${id}`, { product });
}

export async function getProduct(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setProductFromRequest(data.product);
}

export async function getProducts({
  carrierId = null,
  primaryProduct = null,
  lines = null,
  name = null
}) {
  const params = new URLSearchParams();
  if (carrierId) params.append("carrier", carrierId);
  if (primaryProduct) params.append("primary_product", "true");
  if (lines && Array.isArray(lines)) {
    lines.forEach(line => {
      params.append("lines[]", line);
    });
  }
  if (name) params.append("name", name);
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.products.map(p => ({
    name: p.name,
    id: p.id,
    line: p.line,
    ltcRider: p.ltc_rider
  }));
}

let productAliasCancelToken = null;
export async function getProductAliases(productId) {
  if (productAliasCancelToken !== null) {
    productAliasCancelToken.cancel("Operation canceled due to new request.");
  }
  productAliasCancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(`${baseUrl}/${productId}/aliases`, {
    cancelToken: productAliasCancelToken.token
  });

  if (!res?.data) return;
  const data = res.data;

  return data.map(setProductAliasFromRequest);
}

export const createAlias = (productId, body) => {
  return getHttpClient().post(`${baseUrl}/${productId}/aliases`, body);
};

export const deleteAlias = (productId, aliasId) => {
  return getHttpClient().delete(`${baseUrl}/${productId}/aliases/${aliasId}`);
};

export const updateAlias = (productId, aliasId, body) => {
  return getHttpClient().put(
    `${baseUrl}/${productId}/aliases/${aliasId}`,
    body
  );
};

export const createTemporaryInsuranceProfile = async (productId, body) => {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${productId}/temporary-insurance-profiles`,
    body
  );
  return setTemporaryInsuranceProfileFromRequest(data);
};

export const removeTemporaryInsuranceProfile = (productId, id) => {
  return getHttpClient().delete(
    `${baseUrl}/${productId}/temporary-insurance-profiles/${id}`
  );
};
