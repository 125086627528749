<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="carrier-status-files-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Carrier Status Files</h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="carrier-status-files-table-refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip v-if="user.isGroupTwoPlus" location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiPlus"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="carrier-status-files-table-create"
              @click="createNewCarrierStatusFile"
            />
          </template>
          <span>Upload Carrier Status File</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #[`item.statusDate`]="{ item }">
      {{ timestampFormatter(item.statusDate, "sole-day") }}
    </template>

    <template #[`item.document`]="{ item }">
      <a v-if="item.document" v-bind="downloadDocument(item.document)">
        {{ item.document.name }}
      </a>
    </template>

    <template #[`item.errorsDocument`]="{ item }">
      <a
        v-if="item.errorsDocument"
        v-bind="downloadDocument(item.errorsDocument)"
      >
        {{ item.errorsDocument.name }}
      </a>
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>
  </v-data-table-server>
</template>

<script setup>
import CarrierStatusFileCreate from "@/components/carrier-status-files/CarrierStatusFileCreate.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import {
  parseErrorMessage,
  downloadFileAsLink,
  timestampFormatter
} from "@/util/helpers";
import { useTable } from "@/composables/table.composable";

import { getDocumentUrl } from "@/api/documents.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiRefresh, mdiPlus } from "@mdi/js";
import { markRaw } from "vue";
import { useDisplay } from "vuetify";

import { getCarrierStatusFiles } from "@/api/carrier-status-files.service.js";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { mdAndDown } = useDisplay();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier.name",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status Date",
      value: "statusDate",
      map: "statusDate",
      sortFilterMap: "status_date",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Document",
      value: "document",
      map: "document"
    }),
    new TableHeader({
      text: "Errors Document",
      value: "errorsDocument",
      map: "errorsDocument"
    })
  ],
  options: TableOptions({
    sortBy: [{ key: "statusDate", order: "desc" }]
  }),
  getData: getCarrierStatusFiles
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function createNewCarrierStatusFile() {
  const result = await dialog.showDialog({
    component: markRaw(CarrierStatusFileCreate),
    scrollable: true
  });

  if (result?.created) getData();
}

function downloadDocument({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}
</script>
