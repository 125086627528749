<template>
  <v-card tile flat color="section">
    <v-data-table
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      item-value="additional.id"
      data-testid="quote-history-table"
      :mobile="null"
      mobile-breakpoint="sm"
      :loading="table.loading.value"
      :items="table.mappedItems.value"
      :headers="table.tableHeaders.value"
      class="transparent-data-table"
    >
      <template #[`item.avatar`]="{ item }">
        <v-img
          :src="item.avatar"
          max-height="50"
          max-width="100"
          contain
          class="my-3"
        />
      </template>
      <template #[`item.amount`]="{ item }">
        <div v-if="item.additional.faceAmount">
          <currency-formatter
            :model-value="item.additional.faceAmount"
            :decimal-length="0"
          />
          <span class="text-grey"> (Death Benefit) </span>
        </div>
        <div v-if="item.additional.poolOfMoney">
          <currency-formatter
            :model-value="item.additional.poolOfMoney"
            :decimal-length="0"
          />
          <span class="text-grey"> (Pool of Money) </span>
        </div>
      </template>
      <template #[`item.premium`]="{ item }">
        <currency-formatter :model-value="item.premium" :decimal-length="2" />
        <div class="text-grey inline-block">
          /<mode-formatter
            :model-value="item.additional.mode"
            format="abbreviated"
            lowercase
          />
        </div>
      </template>
      <template #[`item.createdAt`]="{ item }">
        <timestamp-formatter
          :model-value="item.createdAt"
          parser="none"
          format="date-time"
        />
      </template>
      <template #[`item.insured`]="{ item }">
        {{ item.additional.firstName }} {{ item.additional.lastName }}
      </template>
      <template #[`item.illustration`]="{ item }">
        <app-button
          v-if="item.illustration"
          :icon="mdiFilePdfBox"
          color="error"
          variant="text"
          density="comfortable"
          v-bind="downloadDocument(item.illustration)"
        />
      </template>
      <template
        #[`item.data-table-expand`]="{
          item,
          internalItem,
          toggleExpand,
          isExpanded
        }"
      >
        <div class="row ma-0 align-center justify-center">
          <app-button
            v-if="item.additional.canDelete"
            :icon="mdiDelete"
            color="error"
            variant="text"
            density="comfortable"
            size="small"
            data-testid="delete-quote-button"
            @click="deleteQuote(item.additional)"
          />

          <app-button
            :icon="isExpanded(internalItem) ? mdiChevronUp : mdiChevronDown"
            size="small"
            variant="text"
            density="comfortable"
            @click="toggleExpand(internalItem)"
          />
        </div>
      </template>
      <template #expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="py-4">
          <electronic-application-view-quote-history-item
            :item="item.additional"
          />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import ElectronicApplicationViewQuoteHistoryItem from "@/components/electronic-applications/ElectronicApplicationViewQuoteHistoryItem.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ModeFormatter from "@/components/shared/formatters/ModeFormatter.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";

import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { useDialogStore } from "@/stores/dialog";

import { useTable } from "@/composables/table.composable";

import {
  mdiFilePdfBox,
  mdiChevronUp,
  mdiChevronDown,
  mdiDelete
} from "@mdi/js";
import { useDisplay } from "vuetify";
import { markRaw } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const eapp = useEappViewStore();

const headers = [
  new TableHeader({
    text: "Carrier",
    value: "avatar",
    map: "carrier.avatarUrl",
    width: "150px"
  }),
  new TableHeader({
    text: "Product",
    value: "product",
    map: "product.name"
  }),
  new TableHeader({
    text: "Insured",
    value: "insured",
    map: "insured.name"
  }),
  new TableHeader({
    text: "Amount",
    value: "amount",
    map: "amount",
    ...TableHeader.IS_SORTABLE,
    sortRaw: (a, b) => {
      if (!a || !b) return 0;
      return (
        a.additional.faceAmount +
        a.additional.poolOfMoney -
        b.additional.faceAmount -
        b.additional.poolOfMoney
      );
    }
  }),
  new TableHeader({
    text: "Premium",
    value: "premium",
    map: "premium"
  }),
  new TableHeader({
    text: "Illustration",
    value: "illustration",
    map: "illustration.uid",
    align: "center"
  }),
  new TableHeader({
    text: "Created",
    value: "createdAt",
    map: "createdAt",
    align: "center",
    ...TableHeader.IS_SORTABLE,
    sortRaw: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  }),
  new TableHeader({
    text: "Actions",
    value: "data-table-expand",
    width: "125px",
    align: "center"
  })
];

const table = useTable({
  headers,
  getData: eapp.getQuoteHistory,
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] })
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function deleteQuote(quote) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want delete this quote?",
    subtitle: "Please confirm your intent",
    func: async () => {
      await eapp.deleteQuote(quote.id);
      await getData();
    }
  });
}

const { mdAndDown } = useDisplay();
function downloadDocument(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}

getData();
</script>
