import {
  add,
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse as dateFnsParse,
  roundToNearestMinutes,
  sub,
  subYears
} from "date-fns";

const parse = (value, format) => {
  if (!value) return null;
  return dateFnsParse(value, format, new Date());
};

export {
  add,
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
  roundToNearestMinutes,
  sub,
  subYears
};
