<template>
  <v-card flat tile>
    <v-card-title
      class="font-weight-light text-h4 pb-0"
      data-testid="employee-name"
    >
      {{ name }}
    </v-card-title>
    <v-card-subtitle
      class="font-weight-light text-h6 pb-3"
      data-testid="employee-position"
    >
      {{ title }}, {{ department }}
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pa-1">
      <card-items :items="points" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";
import { textItem } from "@/components/shared/card-items/card-items";
import { computed } from "vue";

import { timestampFormatter, numberFormat } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useEmployeeView } from "@/stores/employee-view";

const {
  anniversary,
  contractType,
  status,
  maxCases,
  manager,
  terminatedDate,
  name,
  title,
  department
} = storeToRefs(useEmployeeView());

const points = computed(() => {
  const points = [];

  if (anniversary.value) {
    points.push(
      textItem({
        title: "Anniversary",
        text: timestampFormatter(anniversary.value, "sole-day")
      })
    );
  }

  if (contractType.value) {
    points.push(
      textItem({
        title: "Contract Type",
        text: contractType.value
      })
    );
  }

  if (status.value) {
    points.push(
      textItem({
        title: "Status",
        text: status.value
      })
    );
  }

  let maxCasesText = "N/A";
  if (![null, undefined].includes(maxCases.value)) {
    maxCasesText = numberFormat(maxCases.value);
  }

  points.push(
    textItem({
      title: "Max Cases",
      text: maxCasesText
    })
  );

  if (manager.value?.name) {
    points.push(
      textItem({
        title: "Manager",
        text: manager.value?.name
      })
    );
  }

  if (terminatedDate.value) {
    points.push(
      textItem({
        title: "Terminated",
        text: timestampFormatter(terminatedDate.value, "sole-day")
      })
    );
  }

  return points;
});
</script>
