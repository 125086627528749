<template>
  <v-form class="ma-3" @submit.prevent="createApplicationRequest">
    <v-card>
      <v-card-title>Application Request</v-card-title>
      <v-card-text>
        <v-row class="ma-0">
          <v-col cols="12">
            <select-field
              v-model="model.insuranceType"
              label="Line of Insurance"
              data-testid="insurance-type"
              :prepend-inner-icon="mdiHexagonMultiple"
              :items="INSURANCE_TYPES"
              v-bind="insuranceTypeValidation"
            />
          </v-col>
          <template v-if="model.insuranceType">
            <v-col cols="12" lg="6">
              <carrier-search
                v-model="model.carrier"
                label="Carrier"
                data-testid="carrier"
                :lines="[model.insuranceType]"
                v-bind="carrierValidation"
                @update:model-value="debounceAndGetProducts"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <autocomplete-field
                v-model="model.product"
                label="Product"
                item-title="name"
                item-value="id"
                data-testid="product"
                :disabled="!model.carrier?.id"
                :prepend-inner-icon="mdiCube"
                :items="products"
                :loading="loadingProducts"
                v-bind="productValidation"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <date-input
                v-model="model.dateOfBirth"
                data-testid="date-of-birth"
                :prepend-inner-icon="mdiCalendar"
                :label="dateOfBirthLabel"
                v-bind="dateOfBirthValidation"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <autocomplete-field
                v-model="model.state"
                label="State"
                data-testid="state"
                :prepend-inner-icon="mdiSignRealEstate"
                :items="STATES"
                v-bind="stateValidation"
              />
            </v-col>
            <v-col v-if="showFaceAmount" cols="12">
              <currency-input
                v-model="model.faceAmount"
                label="Death Benefit"
                data-testid="face-amount"
                :prepend-inner-icon="mdiCurrencyUsd"
                v-bind="faceAmountValidation"
              />
            </v-col>
            <v-col cols="12">
              <textarea-field
                v-model="model.notes"
                label="Notes"
                data-testid="notes"
                :prepend-inner-icon="mdiTextBox"
                v-bind="notesValidation"
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-button
          type="submit"
          color="primary"
          variant="elevated"
          class="text-none mx-3"
          data-testid="submit"
          :loading="creatingApplication"
        >
          Create Application Request
        </app-button>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script setup>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { STATES } from "@/data/states";
import { getProducts } from "@/api/products.service";

import {
  ApplicationRequest,
  ApplicationRequestToCreateRequest,
  INSURANCE_TYPES
} from "@/models/ApplicationRequest";
import { createApplication } from "@/api/applications.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import {
  mdiHexagonMultiple,
  mdiCube,
  mdiCalendar,
  mdiSignRealEstate,
  mdiCurrencyUsd,
  mdiTextBox
} from "@mdi/js";
import { computed, nextTick, ref, watch } from "vue";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import useVuelidate from "@vuelidate/core";

useHead({ title: "Application Request" });

const snackbar = useSnackbarStore();
const loadingProducts = ref(false);
const creatingApplication = ref(false);
const products = ref([]);
const model = ref(ApplicationRequest());

const showFaceAmount = computed(() => model.value.insuranceType === "life");
const dateOfBirthLabel = computed(() => {
  const prefix =
    model.value.insuranceType === "annuity" ? "Annuitant" : "Insured";
  return `${prefix} Date of Birth`;
});

const v$ = useVuelidate(
  {
    model: {
      insuranceType: { required: Boolean },
      carrier: { required: v => Boolean(v?.id) },
      product: { required: Boolean },
      dateOfBirth: { required: Boolean },
      state: { required: Boolean },
      notes: { required: v => someTextValidator(true, v, 2) },
      faceAmount: { required: v => !showFaceAmount.value || Boolean(v) }
    }
  },
  {
    model
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const insuranceTypeValidation = computedValidation(
  v$.value.model.insuranceType,
  {
    required: "Required"
  }
);
const carrierValidation = computedValidation(v$.value.model.carrier, {
  required: "Required"
});
const productValidation = computedValidation(v$.value.model.product, {
  required: "Required"
});
const dateOfBirthValidation = computedValidation(v$.value.model.dateOfBirth, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.model.state, {
  required: "Required"
});
const faceAmountValidation = computedValidation(v$.value.model.faceAmount, {
  required: "Required"
});
const notesValidation = computedValidation(v$.value.model.notes, {
  required: "Required"
});

async function createApplicationRequest() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    creatingApplication.value = true;
    await createApplication(ApplicationRequestToCreateRequest(model.value));
    snackbar.showSuccessSnackbar({
      message: "Successfully Created Application Request",
      timeout: 5000
    });
    resetData();
    await nextTick();
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingApplication.value = false;
  }
}

let timer;
function debounceAndGetProducts() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(fetchProducts, 10);
}

async function fetchProducts() {
  model.value.product = null;
  loadingProducts.value = true;

  try {
    const fetchedProducts = await getProducts({
      carrierId: model.value.carrier.id,
      lines: [model.value.insuranceType],
      primaryProduct: true
    });
    products.value.splice(0, products.value.length);
    products.value.push(...fetchedProducts);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingProducts.value = false;
  }
}

function resetData() {
  loadingProducts.value = false;
  creatingApplication.value = false;
  products.value.splice(0, products.value.length);
  model.value = ApplicationRequest();
}

watch(
  () => model.value.insuranceType,
  (newVal, oldVal) => {
    if (newVal === oldVal) return;
    model.value.carrier = null;
    model.value.product = null;
  }
);

watch(showFaceAmount, () => {
  model.value.faceAmount = null;
});
</script>
