<template>
  <v-card flat color="section">
    <v-card-title> {{ title }} </v-card-title>
    <v-card-text class="pb-0">
      <slot />
    </v-card-text>
    <v-card-text
      v-if="isExpired || training.id"
      :data-testid="`${title}-status-text`"
      class="pb-0"
    >
      <template v-if="isExpired">
        Your {{ title }} has expired. Please upload a new one.
      </template>
      <template v-else-if="training.id">
        {{ currentText }}
      </template>
    </v-card-text>
    <v-card-actions class="mx-1 flex-wrap">
      <app-button
        class="text-none ma-1"
        color="accent"
        :data-testid="`${title}-create`"
        :variant="training.id ? 'outlined' : undefined"
        @click="emit('create')"
      >
        <v-icon :icon="mdiTextBoxPlus" /> Upload {{ title }}
      </app-button>
      <template v-if="training.id">
        <app-button
          color="primary"
          variant="outlined"
          class="text-none ma-1"
          :data-testid="`${title}-download`"
          v-bind="download"
        >
          <v-icon :icon="mdiDownload" /> Download {{ title }}
        </app-button>
        <app-button
          color="error"
          variant="outlined"
          class="text-none ma-1"
          :data-testid="`${title}-delete`"
          @click="emit('delete')"
        >
          <v-icon :icon="mdiDelete" /> Delete {{ title }}
        </app-button>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { getDocumentUrl } from "@/api/documents.service";
import { downloadFileAsLink, isMobile } from "@/util/helpers";
import { differenceInMonths, addMonths, format, parse } from "@/util/date-util";
import { toRef, computed, watch } from "vue";
import { mdiTextBoxPlus, mdiDownload, mdiDelete } from "@mdi/js";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  training: {
    type: Object,
    default: () => {}
  },
  expiresInMonths: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["create", "delete", "valid"]);

const title = toRef(props, "title");
const training = toRef(props, "training");
const expiresInMonths = toRef(props, "expiresInMonths");

const currentDate = computed(() => {
  if (!training.value.completedAt) return null;
  return parse(training.value.completedAt, "yyyy-MM-dd");
});

const isExpired = computed(() => {
  if (!currentDate.value || !expiresInMonths.value) return null;
  return (
    differenceInMonths(new Date(), currentDate.value) >= expiresInMonths.value
  );
});

watch(isExpired, () => emitValid());

function emitValid() {
  emit("valid", isExpired.value !== true);
}
emitValid();

const completedAt = computed(() => {
  if (!currentDate.value) return;
  return format(currentDate.value, "MM/dd/yyyy");
});
const expiresAt = computed(() => {
  if (!currentDate.value || !expiresInMonths.value) return;
  const expirationDate = addMonths(currentDate.value, expiresInMonths.value);
  return format(expirationDate, "MM/dd/yyyy");
});

const currentText = computed(() => {
  let text = `Your ${title.value} course "${training.value.courseName}" from ${training.value.providerName} was completed on ${completedAt.value}`;
  if (expiresAt.value) text += ` and will expire ${expiresAt.value}`;
  return `${text}.`;
});

const download = computed(() =>
  downloadFileAsLink(getDocumentUrl(training.value.documentUid), isMobile())
);
</script>
