<template>
  <v-row dense align="start" justify="center" no-gutters>
    <v-col
      cols="12"
      class="w-100 h-100"
      :md="vertical ? 12 : 6"
      :order="order.editor"
      :class="{ 'pl-0': !vertical, 'px-0': vertical }"
    >
      <v-row :class="`ma-0 ${color}`" align="center">
        <v-tabs
          v-if="leftTabs.length > 1"
          v-model="activeTab"
          :background-color="color"
          show-arrows
          style="width: unset !important"
          color="primary"
        >
          <v-tab v-for="tab in leftTabs" :key="tab.title" class="text-none">
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <v-spacer />
        <mapped-form-view-question-positional-navigation
          v-if="vertical"
          :form-id="formId"
        />
      </v-row>
      <v-tabs-window v-model="activeTab" touchless>
        <v-tabs-window-item v-for="tab in leftTabs" :key="tab.slot">
          <component :is="tab.component" v-bind="tab.componentProps" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-col>
    <v-col
      cols="12"
      class="w-100"
      :order="order.details"
      :md="vertical ? 12 : 6"
      :class="{ 'pr-0': !vertical, 'px-0': vertical, 'h-100': !vertical }"
    >
      <mapped-form-view-aqls-by-visibility
        v-if="isEappSubmission"
        all-questions
        :form-id="formId"
      />
      <mapped-form-view-aqls-pdf
        v-else
        :key="`form-${formId}`"
        :form-id="formId"
      />
    </v-col>
  </v-row>
</template>
<script setup>
import MappedFormViewQuestionPositionalNavigation from "@/components/form-mapping/MappedFormViewQuestionPositionalNavigation.vue";
import MappedFormViewAqls from "@/components/form-mapping/MappedFormViewAqls.vue";
import MappedFormViewAqlsPdf from "@/components/form-mapping/MappedFormViewAqlsPdf.vue";
import MappedFormViewAqlsByVisibility from "@/components/form-mapping/MappedFormViewAqlsByVisibility.vue";

import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { useFormMappingView } from "@/stores/form-mapping-view";
import { computed, ref, markRaw } from "vue";
import { useDisplay } from "vuetify";

const props = defineProps({
  formId: { type: [Number, String], required: true }
});

const activeTab = ref(0);

const leftTabs = computed(() => {
  const tabs = [
    {
      title: "Question Editor",
      component: markRaw(MappedFormViewAqls),
      componentProps: {
        formId: props.formId
      }
    }
  ];

  if (!isEappSubmission.value) {
    tabs.push({
      title: "Not on PDF",
      component: markRaw(MappedFormViewAqlsByVisibility),
      componentProps: {
        formId: props.formId
      }
    });
  }

  return tabs;
});

const { smAndDown } = useDisplay();

const vertical = computed(
  () => Boolean(copyingFromFormId.value) || smAndDown.value
);

const order = computed(() => ({
  editor: vertical.value ? 2 : 1,
  details: vertical.value ? 1 : 2
}));

const { copyingFromFormId } = storeToRefs(useFormMappingView());
const { isEappSubmission, color } = storeToRefs(
  useMappedFormStore(props.formId)
);
</script>
