<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    data-testid="email-exception-list-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Email Exception List</h1>
        <v-spacer />
        <text-field
          v-model="search"
          hide-details
          label="Search"
          data-lpignore="true"
          :prepend-inner-icon="mdiMagnify"
          data-testid="email-exception-list-table-search"
        />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="email-exception-list-table-refresh"
              :loading="table.loading.value"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiPlusCircle"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="email-exception-list-table-add"
              @click="create"
            />
          </template>
          <span>Add To List</span>
        </v-tooltip>
      </v-row>
    </template>
    <template #[`item.actions`]="{ item }">
      <app-button
        color="error"
        data-testid="delete-email"
        class="inline-block"
        density="comfortable"
        variant="text"
        :icon="mdiDelete"
        @click="removeEmail(item.additional)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import { TableOptions } from "@/classes/data-table/TableOptions";
import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { markRaw, ref } from "vue";
import { mdiDelete, mdiMagnify, mdiPlusCircle, mdiRefresh } from "@mdi/js";

import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import EmailExceptionListDialog from "@/components/email-exception-list/EmailExceptionListDialog.vue";
import {
  getEmailExceptionList,
  removeFromEmailExceptionList
} from "@/api/email-exception-list.service";

useHead({
  title: "Email Exception List"
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const search = ref("");

const table = useTable({
  getData: async () => {
    const items = await getEmailExceptionList();
    return { items };
  },
  options: TableOptions({ sortBy: [{ key: "email", order: "asc" }] }),
  headers: [
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function removeEmail({ email }) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Remove Email Exception",
    subtitle: "This cannot be undone",
    func: async () => {
      await removeFromEmailExceptionList(email);
      await getData();
    }
  });
}

async function create() {
  const res = await dialog.showDialog({
    component: markRaw(EmailExceptionListDialog)
  });

  if (!res?.created) return;
  getData();
}

getData();
</script>
