<template>
  <div class="bg-section pa-3">
    <div class="d-flex align-center justify-space-between mb-3">
      <h2 class="text-h5 inline-block">{{ STAGE_TEXT[props.stage] }} Tasks</h2>
      <app-button
        v-if="!tasks.length && STAGE.DEPROVISIONING === props.stage"
        class="text-none inline-block ml-3"
        color="error"
        data-testid="deprovision"
        text="Deprovision"
        :prepend-icon="mdiAccountRemove"
        @click="deprovisionUser"
      />
      <app-button
        v-else
        class="text-none inline-block ml-3"
        color="primary"
        data-testid="add-task"
        :prepend-icon="mdiPlus"
        @click="addTask"
      >
        Add Task
      </app-button>
    </div>
    <span v-if="tasks.length === 0"> No Tasks </span>
    <v-expansion-panels>
      <employee-view-provisioning-task
        v-for="task in tasks"
        :key="task.id"
        :model-value="task"
        :update-fn="employeeView.updateTask"
        @delete-task="deleteTask(task.id)"
        @update:model-value="updateTask"
      />
    </v-expansion-panels>
  </div>
</template>

<script setup>
import EmployeeViewProvisioningTask from "@/components/employees/EmployeeViewProvisioningTask.vue";
import EmployeeViewProvisioningTasksAdd from "@/components/employees/EmployeeViewProvisioningTasksAdd.vue";
import { useEmployeeView } from "@/stores/employee-view";
import { useDialogStore } from "@/stores/dialog";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { STAGE_TEXT, STAGE } from "@/models/EmployeeProvisioningTask.js";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";
import { mdiAccountRemove, mdiPlus } from "@mdi/js";

const props = defineProps({
  stage: {
    type: String,
    required: true
  }
});

const employeeView = useEmployeeView();
const dialog = useDialogStore();

const { provisioningTasks } = storeToRefs(employeeView);

const tasks = computed(() =>
  provisioningTasks.value.filter(t => t.provisioningTaskStage === props.stage)
);

function updateTask(value) {
  const index = provisioningTasks.value.findIndex(t => t.id === value.id);
  if (index > 0) provisioningTasks.value[index] = value;
}

function deleteTask(taskId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete this Task?",
    subtitle: "Please confirm your intent",
    func: () => employeeView.deleteTask(taskId)
  });
}

async function addTask() {
  const res = await dialog.showDialog({
    component: markRaw(EmployeeViewProvisioningTasksAdd),
    employeeId: employeeView.id,
    stage: props.stage
  });

  if (!res?.task) return;

  employeeView.addTask(res.task);
}

function deprovisionUser() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    persistent: true,
    title: "Initiate Deprovisioning Process?",
    subtitle: "Please confirm your intent",
    func: employeeView.deprovisionUser
  });
}
</script>
