<template>
  <v-row class="ma-0" justify="center" style="position: relative">
    <div
      :id="resizerDivId"
      style="position: absolute; left: 0; right: 0; top: 0; height: 100vh"
    ></div>
    <v-col
      cols="12"
      justify="center"
      align="center"
      style="position: relative; overflow: auto"
    >
      <high-dpi-canvas
        v-if="loaded"
        v-bind="resolutionizer.canvasDimensions"
        :id="canvasId"
        :style="{
          filter: theme.current.value.dark ? 'invert(1)' : 'invert(0)'
        }"
        @redraw="paintCurrentPage"
        @ready="paintCurrentPage"
      />

      <v-col
        v-if="$slots['edit-field-overlay']"
        style="width: 100%; position: absolute; z-index: 2; inset: 0"
      >
        <slot name="edit-field-overlay" />
      </v-col>
      <v-col style="width: 100%; position: absolute; z-index: 1; inset: 0">
        <slot name="pdf-overlay" />
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-row class="ma-0">
        <app-button
          class="text-none mr-1"
          size="small"
          :disabled="!canGoPrevious"
          @click="changePage(-1)"
        >
          Previous Page
        </app-button>
        <slot name="footer-menu" />
        <v-spacer />
        <app-button
          v-if="canDelete"
          class="text-none mr-1"
          size="small"
          color="error"
          @click="emit('delete-page')"
        >
          Delete Page
        </app-button>
        <v-spacer />
        <app-button
          class="text-none"
          size="small"
          :disabled="!canGoNext"
          @click="changePage(1)"
        >
          Next Page
        </app-button>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";
import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useSnackbarStore } from "@/stores/snackbar";
import { getHttpClient } from "@/http-client";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from "vue";
import { useDisplay, useTheme } from "vuetify";
const props = defineProps({
  formId: { type: [String, Number], required: true },
  canDelete: Boolean
});

const emit = defineEmits(["page-count", "viewport-dimensions", "delete-page"]);
const theme = useTheme();
const snackbar = useSnackbarStore();
const formStore = useMappedFormStore(props.formId);
const {
  pdfSvgUrl,
  currentPage,
  window: win,
  viewport
} = storeToRefs(formStore);
const loaded = ref(false);
const pages = ref({});
const maxHeight = ref(0);
const maxWidth = ref(0);
const isMounted = ref(false);
const canvasId = computed(() => {
  return `pdf-viewer-${props.formId}`;
});
const resizerDivId = computed(() => {
  return `resizer-${canvasId.value}`;
});
const canGoNext = computed(() => {
  return currentPage.value < Object.keys(pages.value).length;
});
const canGoPrevious = computed(() => {
  return currentPage.value > 1;
});

const resolutionizer = computed(() => {
  const heightScale = +(maxHeight.value / viewport.value.height).toFixed(2);
  const widthScale = +(maxWidth.value / viewport.value.width).toFixed(2);

  if (widthScale < 1) {
    return {
      canvasDimensions: {
        width: maxWidth.value,
        height: Math.floor(viewport.value.height * widthScale)
      }
    };
  }

  return {
    canvasDimensions: {
      width: Math.floor(viewport.value.width * heightScale),
      height: maxHeight.value
    }
  };
});

function changePage(offset) {
  currentPage.value += offset;
  updatePage();
}

function updatePage() {
  calcCurrentViewport();
  paintCurrentPage();
}

function getCurrentCanvas() {
  return document.getElementById(canvasId.value);
}

async function fetchPdf() {
  try {
    const { data } = await getHttpClient().get(pdfSvgUrl.value);
    for (let i = 0; i < data.length; i++) {
      const image = await initPage(data[i]);
      pages.value[i + 1] = {
        image,
        dimensions: {
          width: image.width,
          height: image.height
        }
      };
    }
    loaded.value = true;
    calcCurrentViewport();
    emit("page-count", Object.keys(pages.value).length);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function initPage(page) {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = page;
  });
}

function calcCurrentViewport() {
  const { height, width } = pages.value[currentPage.value].dimensions;
  const ptToPxConversion = 0.75;
  viewport.value.width = width * ptToPxConversion;
  viewport.value.height = height * ptToPxConversion;
  emit("viewport-dimensions", viewport.value);
}

function paintCurrentPage() {
  const { image } = pages.value[currentPage.value];
  const canvas = getCurrentCanvas();
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    0,
    0,
    resolutionizer.value.canvasDimensions.width,
    resolutionizer.value.canvasDimensions.height
  );
}

let resizerRef;
function instantiateResizeObserver() {
  const el = document.getElementById(resizerDivId.value);
  resizerRef = new ResizeObserver(handleWindowDimensions);
  resizerRef.observe(el);
}

const { sm } = useDisplay();

function handleWindowDimensions() {
  const el = document.getElementById(resizerDivId.value);

  let padding = 48;
  if (sm.value) padding = 80;
  maxWidth.value = el.clientWidth - padding;
  maxHeight.value = document.documentElement.clientHeight - padding;
}

watch(resolutionizer, () => {
  win.value.height = resolutionizer.value.canvasDimensions.height;
  win.value.width = resolutionizer.value.canvasDimensions.width;
});

onMounted(() =>
  nextTick(() => {
    isMounted.value = true;
    fetchPdf();
    instantiateResizeObserver();
  })
);

onBeforeUnmount(() => {
  if (resizerRef?.disconnect) resizerRef.disconnect();
});

watch(currentPage, () => {
  if (isMounted.value) updatePage();
});
</script>

<style lang="scss">
.svg-container {
  line-height: 0;
  canvas {
    border: 1px solid lightgrey;
    width: 100%;
    height: unset;
  }
}
</style>
