<template>
  <v-card>
    <v-card-title>
      {{ model.id ? "Update" : "Create" }} Provisioning Task
    </v-card-title>
    <v-card-text>
      <!-- INSERT STAGES -->
      <p class="mb-0">Is this a Provisioning or Deprovisioning Task?</p>
      <v-chip-group v-model="model.stage" class="mb-3" column>
        <v-chip
          v-for="stage in STAGES"
          :key="stage.title"
          :text="stage.title"
          :value="stage.value"
          :color="activeColor(model.stage === stage.value)"
          :data-testid="`stage-${stage.title}`"
        />
      </v-chip-group>

      <p class="mb-3">What needs to be done?</p>
      <textarea-field
        v-model="model.text"
        label="Instructions"
        data-testid="text-input"
        v-bind="textValidation"
      />

      <v-fade-transition mode="out-in">
        <div v-if="!model.excludedDepartments.length">
          <p class="mb-0">Specify departments this applies to (default: all)</p>
          <v-chip-group
            v-model="model.includedDepartments"
            multiple
            class="mb-3"
            column
          >
            <v-chip
              v-for="department in includedDepartments"
              :key="department.text"
              v-bind="department"
              :data-testid="`included-department-${department.text}`"
            />
          </v-chip-group>
        </div>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <div v-if="!model.includedDepartments.length">
          <p class="mb-0">
            Specify departments this does not apply to (default: none)
          </p>
          <v-chip-group
            v-model="model.excludedDepartments"
            multiple
            class="mb-3"
            column
          >
            <v-chip
              v-for="department in excludedDepartments"
              :key="department.text"
              v-bind="department"
              :data-testid="`excluded-department-${department.text}`"
            />
          </v-chip-group>
        </div>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <div v-if="!model.excludedTitles.length">
          <p class="mb-0">Specify titles this applies to (default: all)</p>
          <v-chip-group
            v-model="model.includedTitles"
            multiple
            class="mb-3"
            column
          >
            <v-chip
              v-for="title in includedTitles"
              :key="title.text"
              v-bind="title"
              :data-testid="`included-title-${title.text}`"
            />
          </v-chip-group>
        </div>
      </v-fade-transition>

      <v-fade-transition mode="out-in">
        <div v-if="!model.includedTitles.length">
          <p class="mb-0">
            Specify titles this does not apply to (default: none)
          </p>
          <v-chip-group
            v-model="model.excludedTitles"
            multiple
            class="mb-3"
            column
          >
            <v-chip
              v-for="title in excludedTitles"
              :key="title.text"
              v-bind="title"
              :data-testid="`excluded-title-${title.text}`"
            />
          </v-chip-group>
        </div>
      </v-fade-transition>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none mr-1"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save"
        :loading="loading"
        @click="save"
      >
        {{ model.id ? "Update" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  STAGES,
  EmployeeProvisioningTask,
  setRequestBodyFromEmployeeProvisioningTask
} from "@/models/EmployeeProvisioningTask";
import {
  createProvisioningTask,
  updateProvisioningTask
} from "@/api/employee-provisioning-tasks.service";
import { DEPARTMENTS, TITLES } from "@/models/Employee";

const props = defineProps({
  modelValue: {
    type: Object,
    optional: true,
    default: () => EmployeeProvisioningTask()
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const model = ref(props.modelValue);
const loading = ref(false);
const v$ = useVuelidate(
  {
    model: {
      text: {
        required: v => someTextValidator(true, v, 3)
      }
    }
  },
  { model },
  { $scope: null, $autoDirty: true }
);

const textValidation = computedValidation(v$.value.model.text, {
  required: "Required"
});

function activeColor(v) {
  return v ? "primary" : null;
}

const includedDepartments = computed(() =>
  DEPARTMENTS.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        text: v,
        value: v,
        color: activeColor(model.value.includedDepartments.includes(v))
      }
    }),
    {}
  )
);

const excludedDepartments = computed(() =>
  DEPARTMENTS.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        text: v,
        value: v,
        color: activeColor(model.value.excludedDepartments.includes(v))
      }
    }),
    {}
  )
);

const includedTitles = computed(() =>
  TITLES.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        text: v,
        value: v,
        color: activeColor(model.value.includedTitles.includes(v))
      }
    }),
    {}
  )
);

const excludedTitles = computed(() =>
  TITLES.reduce(
    (acc, v) => ({
      ...acc,
      [v]: {
        text: v,
        value: v,
        color: activeColor(model.value.excludedTitles.includes(v))
      }
    }),
    {}
  )
);

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    const body = setRequestBodyFromEmployeeProvisioningTask(model.value);
    if (model.value.id) {
      await updateProvisioningTask(model.value.id, body);
    } else {
      await createProvisioningTask(body);
    }

    dialog.closeDialog({ refresh: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
