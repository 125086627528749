<template>
  <span :ref="setAnimatedRef"></span>
</template>
<script setup>
import { ref, toRef, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: "0",
    required: true
  },
  formatValue: {
    type: Function,
    default: value => value
  },
  duration: {
    type: Number,
    default: 1000
  }
});

const modelValueProp = toRef(props, "modelValue");

const animatedRef = ref(null);

function animateValue(obj, initialValue, endValue, duration) {
  let startTimestamp = null;
  const step = timestamp => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    let value = Math.round(progress * (endValue - initialValue) + initialValue);
    if (props.formatValue) value = props.formatValue(value);
    obj.innerHTML = value;
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

function setAnimatedRef(newRef) {
  animatedRef.value = newRef;
  performAnimation();
}

function performAnimation() {
  if (!animatedRef.value) return;
  animateValue(animatedRef.value, 0, props.modelValue, props.duration);
}

watch(modelValueProp, performAnimation);
</script>
