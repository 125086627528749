import { setEmployeeProvisioningTaskAnswerFromRequest } from "@/models/EmployeeProvisioningTask";

export function NewEmployee(model = {}) {
  return {
    anniversary: model?.anniversary,
    contractType: model?.contractType,
    department: model?.department,
    updatable: model?.updatable,
    terminatedDate: model?.terminatedDate,
    title: model?.title,
    manager: model?.manager ? EmployeeManager(model?.manager) : null,
    maxCases: model?.maxCases,
    birthdate: model?.birthdate,
    phoneMobile: model?.phoneMobile,

    ownable: model?.ownable,
    resumé: model?.resumé,
    employmentAgreement: model?.employmentAgreement
  };
}

export function setRequestBodyFromNewEmployee(newEmployee = NewEmployee()) {
  return {
    title: newEmployee.title,
    department: newEmployee.department,
    anniversary: newEmployee.anniversary,
    birthdate: newEmployee.birthdate,
    manager_id: newEmployee.manager.id,
    ownable_id: newEmployee.ownable.id,
    phone_mobile: newEmployee.phoneMobile,
    contract_type: newEmployee.contractType,
    max_cases: [null, undefined].includes(newEmployee.maxCases)
      ? undefined
      : +newEmployee.maxCases
  };
}

export function Employee(model = {}) {
  return {
    anniversary: model?.anniversary,
    contractType: model?.contractType,
    department: model?.department,
    updatable: model?.updatable,
    terminatedDate: model?.terminatedDate,
    title: model?.title,
    manager: model?.manager ? EmployeeManager(model?.manager) : null,
    maxCases: model?.maxCases,
    birthdate: model?.birthdate,
    phoneMobile: model?.phoneMobile,

    firstName: model?.firstName,
    id: model?.id,
    lastName: model?.lastName,
    status: model?.status,
    reviews: model?.reviews || [],
    provisioningTasks: model?.provisioningTasks || []
  };
}

export function setEmployeeFromRequest(raw = {}) {
  const employee = Employee();

  employee.anniversary = raw?.anniversary;
  employee.contractType = raw?.contract_type;
  employee.department = raw?.department;
  employee.updatable = raw?.updatable;
  employee.firstName = raw?.first_name;
  employee.id = raw?.id;
  employee.lastName = raw?.last_name;
  employee.status = raw?.status;
  employee.terminatedDate = raw?.terminated_date;
  employee.title = raw?.title;
  employee.manager = setEmployeeManagerFromRequest(raw?.manager);
  employee.maxCases = raw?.max_cases;
  if (Array.isArray(raw?.employee_reviews)) {
    employee.reviews = raw.employee_reviews.map(setEmployeeReviewFromRequest);
  }
  if (Array.isArray(raw?.employee_provisioning_task_answers)) {
    employee.provisioningTasks = raw.employee_provisioning_task_answers.map(
      setEmployeeProvisioningTaskAnswerFromRequest
    );
  }

  return employee;
}

function EmployeeManager(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

export function setEmployeeManagerFromRequest(raw = {}) {
  const model = EmployeeManager();
  model.id = raw?.id;
  model.name = raw?.name;
  model.type = raw?.type?.model || raw?.type;
  return model;
}

export function EmployeeReview(model = {}) {
  return {
    review: model?.review || null,
    reviewDate: model?.reviewDate || null,
    reviewerId: model?.reviewerId || null,
    reviewerType: model?.reviewerType || null,
    reviewer: model?.reviewer || null
  };
}

export function setEmployeeReviewFromRequest(raw = {}) {
  const review = EmployeeReview();
  review.review = raw.review;
  review.reviewDate = raw.review_date;
  review.reviewerId = raw.reviewer.id;
  review.reviewerType = raw.reviewer.type.model;
  review.reviewer = raw.reviewer.name;
  return review;
}

export function setRequestFromReview(review = EmployeeReview()) {
  return {
    review_date: review.reviewDate,
    review: review.review
  };
}

export function EmployeeTableItem(model = {}) {
  return {
    id: model?.id || null,
    accessible: model?.accessible || null,
    name: model?.name || null,
    email: model?.email || null,
    phone: model?.phone || null,
    extension: model?.extension || null,
    mobile: model?.mobile || null,
    birthdate: model?.birthdate || null,
    anniversary: model?.anniversary || null,
    schedulingLink: model?.schedulingLink || null,
    jobDescription: model?.jobDescription || null,
    department: model?.department || null,
    lastReviewDate: model?.lastReviewDate || null,
    managerName: model?.managerName || null,
    backnineCALicenseNumber: model?.backnineCALicense || null,
    caLicenseNumber: model?.caLicenseNumber || null,
    title: model?.title || null
  };
}

export function setEmployeeTableItemFromRequest(raw = {}) {
  const employee = EmployeeTableItem();
  employee.id = raw.id;
  employee.accessible = raw.accessible;
  employee.name = `${raw.first_name} ${raw.last_name}`;
  employee.email = raw.email;
  employee.phone = raw.phone_work;
  employee.extension = raw.extension;
  employee.mobile = raw.phone_mobile;
  employee.birthdate = raw.birthdate;
  employee.anniversary = raw.anniversary;
  employee.jobDescription = raw.job_description;
  employee.department = raw.department;
  employee.title = raw.title;
  if (raw.last_review_date) {
    employee.lastReviewDate = raw.last_review_date;
  }
  if (raw.scheduling_link?.length) {
    employee.schedulingLink = raw.scheduling_link[0].url;
  }
  if (raw.manager) {
    employee.managerName = raw.manager.name;
  }
  if (raw.backnine_ca_license) {
    employee.backnineCALicenseNumber = raw.backnine_ca_license.number;
  }
  if (raw.ca_license) {
    employee.caLicenseNumber = raw.ca_license.number;
  }

  return employee;
}

export const DEPARTMENTS = [
  "Executive Department",
  "Sales",
  "Commissions",
  "Office Management",
  "Case Management",
  "Accounting",
  "Engineering",
  "Contracting",
  "Administrative Assistant",
  "Form Management"
];

export const TITLES = [
  "Account Manager, Enterprise",
  "Accounting",
  "Annuity Sales President",
  "Case Concierge",
  "Case Manager",
  "Commissions",
  "Contracting Coordinator",
  "Contracting Manager",
  "Director of Advanced Sales",
  "Director of White Glove",
  "Disability Specialist",
  "Forms Manager",
  "Institutional Accounts, Enterprise",
  "Internal Wholesaler",
  "Marketing Manager",
  "Office Manager",
  "President",
  "President, Enterprise",
  "Software Engineer",
  "Vice President",
  "White Glove Agent",
  "White Glove Marketing"
];

export const CONTRACT_TYPE = {
  1099: "1099",
  W2: "W2",
  OUTSOURCED_EMPLOYEE: "Outsourced Employee"
};

export const CONTRACT_TYPES = Object.values(CONTRACT_TYPE);

export function getTitlesForDepartment(department) {
  if (department == "Case Management")
    return ["Case Manager", "Case Concierge"];
  return TITLES;
}
