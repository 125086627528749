<template>
  <v-card class="ma-1">
    <v-card-title>Marketing Emails</v-card-title>
    <v-card-text>
      Choose a template and send an email or generate HTML to send emails from
      your own email program. The box
      {{ mdAndDown ? " below " : " to the right of " }} the form will be updated
      with a preview of the final result.
    </v-card-text>
    <v-row class="ma-0">
      <v-col cols="12" md="6">
        <v-card-text>
          <v-form @submit.prevent="createMarketing">
            <autocomplete-field
              v-model="selectedTemplate"
              label="Template"
              return-object
              density="default"
              data-testid="template-select"
              :items="templates"
              :prepend-inner-icon="mdiFile"
              :success="selectedTemplateValidation.success"
              :error-messages="selectedTemplateValidation.errorMessages"
              @update:model-value="getTemplate"
            />
            <text-field
              v-model="subject"
              data-lpignore="true"
              label="Subject"
              density="default"
              data-testid="subject"
              :prepend-inner-icon="mdiText"
              :placeholder="subjectText"
              :success="subjectTextValidation.success"
              :error-messages="subjectTextValidation.errorMessages"
            />
            <combobox-field
              v-model="chips"
              v-model:search="search"
              density="default"
              clearable
              label="Emails (up to 100)"
              multiple
              hint="Press Enter to Add"
              persistent-hint
              variant="outlined"
              data-testid="email-chips"
              :prepend-inner-icon="mdiEmailMultiple"
              :success="chipsValidation.success"
              :error-messages="chipsValidation.errorMessages"
            >
              <template #selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  data-testid="email-chip"
                  :input-value="data.selected"
                  closable
                  @click:close="remove(data.item.title)"
                >
                  <strong> {{ data.item.title }} </strong>
                </v-chip>
              </template>
            </combobox-field>

            <app-button
              type="submit"
              color="primary"
              class="text-none"
              data-testid="submit"
              :loading="creatingMarketing"
            >
              Submit
            </app-button>
          </v-form>
        </v-card-text>
      </v-col>
      <v-col v-if="previewHtmlContent" cols="12" md="6" class="pa-6">
        <v-card variant="outlined" style="line-height: 0">
          <v-row class="ma-0" align="center">
            <div>
              <v-card-title>
                {{ subjectText }}
              </v-card-title>
              <v-card-subtitle>
                A preview of your selected template will appear below.
              </v-card-subtitle>
            </div>
            <v-spacer />
            <div class="pa-3">
              <app-button
                class="text-none"
                variant="text"
                color="primary"
                data-testid="copy-html"
                :loading="fetchingHtml"
                :prepend-icon="mdiFileCode"
                text="View HTML"
                @click="copyHtml"
              />
            </div>
          </v-row>
          <iframe
            v-if="previewHtmlContent"
            style="height: 40rem; border: 0; width: 100%"
            class="pa-0"
            data-testid="preview-iframe"
            :srcdoc="previewHtmlContent"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import CopyDialog from "@/dialogs/CopyDialog.vue";

import { templates } from "@/data/marketing-data";

import {
  sendMarketingTemplate,
  getMarketingTemplateHtml
} from "@/api/marketing.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiFile, mdiText, mdiEmailMultiple, mdiFileCode } from "@mdi/js";
import { computed, markRaw, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useDisplay } from "vuetify";

const { mdAndDown } = useDisplay();
useHead({ title: "Marketing" });
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const selectedTemplate = ref(null);
const activeTemplate = ref(null);
const chips = ref([]);
const subject = ref(null);
const creatingMarketing = ref(false);
const search = ref("");
const fetchingHtml = ref(false);
const previewHtmlContent = ref(null);

let searchTimer = null;

watch(search, v => debounceUserInput(v));
watch(selectedTemplate, (newVal, oldVal) => {
  if (newVal?.id == oldVal?.id) return;
  activeTemplate.value = null;
});

const subjectText = computed(() => selectedTemplate.value?.title);

const v$ = useVuelidate(
  {
    chips: { required: Boolean },
    subjectText: { required: Boolean },
    selectedTemplate: { required: Boolean }
  },
  { chips, subjectText, selectedTemplate },
  { $scope: null, $autoDirty: true }
);

const chipsValidation = computedValidation(v$.value.chips, {
  required: "Required, Press Enter to add an email"
});

const subjectTextValidation = computedValidation(v$.value.subjectText, {
  required: "Required"
});

const selectedTemplateValidation = computedValidation(
  v$.value.selectedTemplate,
  {
    required: "Required"
  }
);

function remove(item) {
  chips.value.splice(chips.value.indexOf(item), 1);
}

function debounceUserInput(v) {
  if (searchTimer) clearTimeout(searchTimer);
  searchTimer = setTimeout(() => {
    if (!v) return;
    const delimiters = [",", ";", "|"];
    delimiters.forEach(delimiter => {
      const split = v.split(delimiter);
      if (split.length <= 1) return;
      const nonEmptyEmails = split.filter(Boolean);
      chips.value.push(...nonEmptyEmails);
      search.value = "";
    });
  }, 200);
}

async function getTemplate() {
  if (!selectedTemplate.value) return;
  subject.value = subjectText.value;
  const title = selectedTemplate.value.title;
  const type = selectedTemplate.value.value;
  try {
    fetchingHtml.value = true;
    previewHtmlContent.value = await getMarketingTemplateHtml(title, type);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    fetchingHtml.value = false;
  }
}

async function createMarketing() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  creatingMarketing.value = true;
  const emails = chips.value.join();
  const title = subject.value || subjectText.value;
  const type = selectedTemplate.value.value;

  try {
    await sendMarketingTemplate(emails, type, title);
    snackbar.showSuccessSnackbar({
      message: "Successfuly Sent Marketing Emails",
      timeout: 5000
    });
    selectedTemplate.value = null;
    subject.value = null;
    chips.value = [];
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({ messsage: parseErrorMessage(e) });
  } finally {
    creatingMarketing.value = false;
  }
}
async function copyHtml() {
  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: previewHtmlContent.value,
    title: selectedTemplate.value.title,
    scrollable: true
  });
}
</script>
