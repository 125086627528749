import { Quote } from "@/models/Quote";
import { getQuote } from "@/api/quotes.service";
import { defineStore } from "pinia";
import { parse, isValid } from "@/util/date-util";
export const useQuoteViewStore = defineStore("quote-view", {
  state: () => ({ ...Quote(), todoCount: 0 }),
  getters: {
    insuredName() {
      return this.insured.ownable.name;
    },
    birthdate() {
      try {
        const birthdate = this.insured.ownable.birthdate;
        const date = parse(birthdate, "yyyy-MM-dd");
        if (!isValid(date)) return null;
        return date;
      } catch (e) {
        return null;
      }
    }
  },
  actions: {
    updateAgent(agent) {
      this.$patch({ agent });
    },
    async initializeQuote(id) {
      this.$reset();
      const quote = await getQuote(id);
      this.$patch(quote);

      const insured = this.quoteRoles.find(insured =>
        ["Insured", "Annuitant"].includes(insured.role)
      );

      let agent = null;
      if (this.opportunities.length) {
        const agentOpportunity = this.opportunities.find(a => a.agent === true);
        if (agentOpportunity) {
          agent = {
            name: agentOpportunity.name,
            id: agentOpportunity.id,
            routerLink: agentOpportunity.routerLink
          };
        }
      }

      this.$patch({ insured, agent, smoker: insured?.ownable?.tobacco });
    }
  }
});
