<template>
  <autocomplete-field
    v-model="user"
    v-bind="$attrs"
    v-model:search="searchText"
    autocomplete="false"
    :prepend-inner-icon="mdiAccount"
    placeholder="Search Users by Email"
    label="User"
    item-title="email"
    return-object
    no-filter
    persistent-hint
    hint=" "
    :data-testid="dataTestid"
    :hide-no-data="!includeNew || !searchText || loading || typing"
    :disabled="disabled"
    :items="users"
    :loading="loading"
    @blur="$emit('blur')"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
    <template #no-data>
      <v-list-item
        data-testid="existing-search-create-new-user"
        @click="$emit('create-new', searchText)"
      >
        <v-list-item-title> {{ searchText }} </v-list-item-title>
        <v-list-item-subtitle>
          User not found. Create new user?
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message-inner" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script setup>
import { mdiAccount } from "@mdi/js";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, toRef, watch } from "vue";
import { getUsers } from "@/api/users.service";
import { getHttpClient } from "@/http-client";

const props = defineProps({
  dataTestid: { type: String, requried: false, default: null },
  modelValue: { type: Object, required: false, default: null },
  disabled: Boolean,
  includeNew: Boolean
});

const modelValue = toRef(props, "modelValue");

const emit = defineEmits(["update:model-value", "blur", "create-new"]);

const snackbar = useSnackbarStore();
const users = ref(props.modelValue ? [props.modelValue] : []);
const user = ref(props.modelValue || null);
const searchText = ref("");
const loading = ref(false);
const typing = ref(false);

watch(modelValue, v => {
  if (!v || !Object.keys(v).length) {
    user.value = null;
    users.value = [];
    return;
  }

  user.value = v;
  users.value = [v];
});

watch(user, v => emit("update:model-value", v));

watch(searchText, v => {
  if (!v || !Object.keys(v).length) {
    typing.value = false;
    return;
  }
  if (users.value.some(val => val?.email === v)) {
    typing.value = false;
    return;
  }
  typing.value = true;
  debounceAndSearch();
});

let timer;
function debounceAndSearch() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(search, 200);
}

let cancelToken = getHttpClient().CancelToken.source();

async function search() {
  loading.value = true;
  try {
    const res = await getUsers({ email: searchText.value }, cancelToken);
    if (res?.items) users.value = res.items;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
    typing.value = false;
  }
}
</script>
