<template>
  <v-card>
    <v-row class="ma-0 py-3" align="center">
      <v-card-title> Annualized Premium </v-card-title>
      <v-spacer />
      <v-tabs class="mb-0 mx-3" style="width: unset" color="primary" right @update:model-value="updateData">
        <v-tab class="text-none">Inforce </v-tab>
        <v-tab class="text-none">Pending </v-tab>
      </v-tabs>
    </v-row>
    <v-divider />
    <v-card-text>
      <v-col cols="12" align="center" class="pa-0">
        <div v-if="hasData" class="highcharts-areaspline">
          <highcharts-graph
            data-testid="premium-graph"
            :options="chartOptions"
            style="border-radius: 5px"
          />
        </div>
        <v-alert v-else-if="noData" color="info" class="mt-6 text-white">
          No Premium Data to Report
        </v-alert>
        <div v-else-if="loaded">
          <v-alert color="error">Unable to load Premium Reports</v-alert>
        </div>
        <v-progress-circular v-else indeterminate />
      </v-col>
    </v-card-text>
  </v-card>
  <!-- Add Tabs Inforce, Pending -->
</template>

<script>
import { getPremiumReport } from "@/api/reports.service";
import { useTableStore } from "@/stores/table";
import { format } from "@/util/date-util";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    HighchartsGraph: defineAsyncComponent(() =>
      import("@/components/shared/HighchartsGraph.vue")
    )
  },
  props: {
    all: Boolean
  },
  data() {
    return {
      premiumData: {},
      loaded: false,
      activeTab: 0
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          styledMode: true,
          type: "areaspline",
          style: {
            fontFamily: "Roboto"
          }
        },
        title: {
          text:
            this.activeTab === 0
              ? "Inforce Annualized Premium by Line"
              : "Pending Annualized Premium by Line",
          style: {
            textTransform: "none"
          }
        },
        tooltip: {
          xDateFormat: "%B"
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y"
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Production"
          }
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false
            },
            events: {
              click: e => {
                const table = useTableStore();
                const startDate = new Date(e.point.x);
                const finishDate = new Date(startDate);
                finishDate.setMonth(startDate.getMonth() + 1);
                finishDate.setDate(0);
                table.casesTable.filter = {
                  status: "active",
                  inforce: {
                    type: "solo",
                    value: {
                      start: format(startDate, "yyyy-MM-dd"),
                      finish: format(finishDate, "yyyy-MM-dd")
                    }
                  }
                };
                if (this.$router) this.$router.push({ name: "CasesTable" });
              }
            }
          }
        },
        series: this.premiumData[this.activeTab === 0 ? "active" : "pending"],
        defs: {
          gradient0: {
            tagName: "linearGradient",
            id: "gradient-0",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient1: {
            tagName: "linearGradient",
            id: "gradient-1",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          }
        }
      };
    },
    hasData() {
      return Boolean(this.chartOptions.series?.length);
    },
    noData() {
      return !this.chartOptions.series?.length && this.loaded;
    }
  },
  created() {
    this.getReportData();
  },
  methods: {
    updateData(index) {
      this.activeTab = index;
    },
    async getReportData() {
      const params = new URLSearchParams();
      if (this.all) params.append("view", "all");
      try {
        this.premiumData = await getPremiumReport(params);
      } catch (e) {
        // Do nothing.
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
