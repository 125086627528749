<template>
  <div class="bg-section pa-3">
    <div class="d-flex align-center justify-space-between mb-3">
      <h2 class="text-h5 inline-block">Performance Reviews</h2>
      <app-button
        v-if="updatable"
        class="text-none"
        color="primary"
        data-testid="add-review"
        text="Add Review"
        :prepend-icon="mdiPlus"
        @click="addReview"
      />
    </div>

    <v-timeline v-if="reviews.length" align-top dense>
      <v-timeline-item
        v-for="({ reviewDate, review, reviewer }, index) in reviews"
        :key="index"
        :data-testid="`review-${index}`"
        dot-color="primary"
      >
        <v-card variant="outlined">
          <v-card-title v-if="reviewDate" data-testid="review-title">
            {{ timestampFormatter(reviewDate, "sole-day") }}
          </v-card-title>
          <v-card-subtitle data-testid="review-reviewed-by">
            {{ reviewer }}
          </v-card-subtitle>
          <v-card-text data-testid="review-content">
            {{ review }}
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <p v-else data-testid="no-reviews">No Reviews</p>
  </div>
</template>

<script setup>
import EmployeeReviewDialog from "@/components/employees/EmployeeReviewDialog.vue";
import { timestampFormatter } from "@/util/helpers";
import { useEmployeeView } from "@/stores/employee-view";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { mdiPlus } from "@mdi/js";
import { markRaw } from "vue";

const employeeView = useEmployeeView();

const { updatable, reviews } = storeToRefs(employeeView);

const dialog = useDialogStore();

function addReview() {
  dialog.showDialog({
    component: markRaw(EmployeeReviewDialog),
    persistent: true
  });
}
</script>
