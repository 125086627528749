<template>
  <v-card elevation="8" class="pa-3 ma-1">
    <v-row>
      <v-col cols="12">
        <v-row
          v-for="(section, index) in series.sections"
          :key="index"
          class="ma-0"
          style="padding-top: 5rem; padding-bottom: 5rem"
        >
          <v-col cols="12" :lg="section.url ? 6 : 12">
            <v-row class="ma-0 h-100" justify="center" align="center">
              <v-col align="center">
                <div>
                  <h2
                    class="series-title"
                    :class="{ 'text-primary': section.primaryTitle }"
                    :style="{
                      'font-size': smAndDown ? '28px' : '44px'
                    }"
                  >
                    {{ section.title }}
                  </h2>
                </div>
                <v-row justify="center">
                  <v-col>
                    <div
                      class="series-subtitle"
                      :style="{
                        'font-size': smAndDown ? '24px' : '28px'
                      }"
                    >
                      {{ section.subtitle }}
                    </div>
                    <div
                      v-if="section.c2a"
                      class="series-c2a"
                      style="margin-top: 20px"
                    >
                      <app-button
                        v-bind="section.c2a.value.buttonProps"
                        :color="section.c2a.value.color"
                        block
                        size="x-large"
                        class="text-none"
                        :style="{
                          'font-size': smAndDown ? '18px' : '22px'
                        }"
                        v-on="section.c2a.value.buttonListeners"
                      >
                        <v-icon
                          v-if="section.c2a.value.icon"
                          :icon="section.c2a.value.icon"
                        />
                        {{ section.c2a.value.title }}
                      </app-button>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="section.url" cols="12" lg="6">
            <vimeo-video :id="`series-video-${index}`" :url="section.url" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  AVAILABLE_SERIES,
  useWalkthroughComposable
} from "@/composables/series-composable";
import { useInstanceStore } from "@/stores/instance";
import VimeoVideo from "@/components/shared/VimeoVideo.vue";
import { useHead } from "@unhead/vue";
import { useDisplay } from "vuetify";

export default {
  components: {
    VimeoVideo
  },
  props: {
    id: { type: String, required: true }
  },
  setup() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      [AVAILABLE_SERIES.WALKTHROUGH]: useWalkthroughComposable()
    };
  },
  data: () => ({ loading: false }),
  computed: {
    series() {
      return { [AVAILABLE_SERIES.WALKTHROUGH]: this.walkthrough }[this.id];
    }
  },
  mounted() {
    const instance = useInstanceStore();
    instance.breadcrumb = this.series.title;
    useHead({ title: this.series.title });
  },
  beforeUnmount() {
    const instance = useInstanceStore();
    instance.breadcrumb = "";
  }
};
</script>

<style lang="scss">
.series-title {
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;
}
.series-subtitle {
  max-width: 750px;
  text-align: center;
  line-height: 1.2;
  font-weight: 300;
}

.series-c2a {
  max-width: 400px;
  width: 100%;
}
</style>
