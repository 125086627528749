import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";

const baseUrl = "api/boss/pdf-tools";
export async function mergePdfs(pdfs) {
  const body = await serializeObject({ files: pdfs });

  try {
    const { data } = await getHttpClient().post(`${baseUrl}/merge`, body, {
      responseType: "blob"
    });
    return data;
  } catch (e) {
    // Because the responseType is set to Blob, axios returns a failure blob too.
    if (e?.response?.data) {
      const responseData = e?.response?.data;
      if (responseData?.type === "application/json") {
        throw JSON.parse(await responseData.text());
      }
    }
    throw e;
  }
}
