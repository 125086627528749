<template>
  <v-expansion-panels v-model="openTable">
    <v-expansion-panel v-for="table in tables" :key="table.title">
      <v-expansion-panel-title> {{ table.title }} </v-expansion-panel-title>
      <v-expansion-panel-text class="no-expansion-panel-content-padding">
        <component :is="table.component" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import VendorTable from "@/components/vendors/VendorTable.vue";
import UsersTable from "@/components/users/UsersTable.vue";
import EmailExceptionListTable from "@/components/email-exception-list/EmailExceptionListTable.vue";
import TrainingsTable from "@/components/trainings/TrainingsTable.vue";
import CarrierStatusFilesTable from "@/components/carrier-status-files/CarrierStatusFilesTable.vue";

import { markRaw, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const { isGroupFour } = storeToRefs(useUserStore());

const tables = [];
const openTable = ref(null);

if (isGroupFour.value) {
  tables.push(
    { title: "Vendors", component: markRaw(VendorTable) },
    {
      title: "Carrier Status Files",
      component: markRaw(CarrierStatusFilesTable)
    },
    {
      title: "Email Exception List",
      component: markRaw(EmailExceptionListTable)
    },
    { title: "Trainings", component: markRaw(TrainingsTable) }
  );
}

tables.push({ title: "Users", component: markRaw(UsersTable) });

openTable.value = tables.length - 1;
</script>
