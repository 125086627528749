<template>
  <v-card>
    <v-card-title>Upload Carrier Status File</v-card-title>
    <v-card-text>
      <carrier-search
        v-model="model.carrier"
        label="Carrier"
        data-testid="carrier"
        v-bind="carrierValidation"
      />
      <date-input
        v-model="model.statusDate"
        label="Status Date"
        data-testid="status-date"
        v-bind="statusDateValidation"
      />
      <file-drag-and-drop
        v-model="model.file"
        :prepend-inner-icon="mdiFileDocument"
        label="File"
        data-testid="file"
        accept=".csv"
        v-bind="fileValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none mr-1"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create"
        :loading="loading"
        @click="create"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { mdiFileDocument } from "@mdi/js";

import { NewCarrierStatusFile } from "@/models/CarrierStatusFile";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { createCarrierStatusFile } from "@/api/carrier-status-files.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const model = ref(NewCarrierStatusFile());
const loading = ref(false);
const v$ = useVuelidate(
  {
    model: {
      carrier: {
        required: v => Boolean(v?.id)
      },
      statusDate: {
        required: v => Boolean(v)
      },
      file: {
        required: v => Boolean(v)
      }
    }
  },
  { model },
  { $scope: null, $autoDirty: true }
);

const carrierValidation = computedValidation(v$.value.model.carrier, {
  required: "Required"
});
const statusDateValidation = computedValidation(v$.value.model.statusDate, {
  required: "Required"
});
const fileValidation = computedValidation(v$.value.model.file, {
  required: "Required"
});

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    await createCarrierStatusFile(model.value);
    dialog.closeDialog({ created: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
