<template>
  <v-row dense>
    <v-col cols="12">
      <v-row class="flex-nowrap ma-0">
        <contract-party-search
          v-model="contractParty.party"
          persistent-hint
          hint=" "
          super-simple
          data-testid="contract-party"
          :success="partyValidation.success"
          :error-messages="partyValidation.errorMessages"
        >
          <template #message>
            <a
              data-testid="create-contract-party"
              class="text-none"
              @click="createContractParty"
            >
              Can't find your contract party?
            </a>
          </template>
        </contract-party-search>
        <app-button
          :icon="mdiDelete"
          color="error"
          variant="text"
          density="comfortable"
          @click="emit('delete-contract-party')"
        />
      </v-row>
    </v-col>

    <v-col cols="12" class="mt-0">
      <template v-if="!topRoles.length">
        Select a Carrier and Product to assign roles.
      </template>
      <template v-else>
        <v-chip-group
          v-model="contractParty.roles"
          column
          multiple
          selected-class="bg-primary"
        >
          <v-chip
            v-for="role in visibleRoles"
            :key="role.value"
            :data-testid="`role-${role}`"
            :value="role.value"
            :disabled="role.disabled"
            :text="role.title"
          />
          <a v-if="truncatedRoles" class="mt-3" @click="showMore = !showMore">
            {{ showMore ? "Show Less" : "Show More" }}
          </a>
        </v-chip-group>
      </template>
      <v-row class="ma-0">
        <autocomplete-field
          v-if="displayRelationship"
          v-model="contractParty.relationship"
          data-testid="relationship"
          :prepend-inner-icon="mdiBriefcase"
          label="Relationship"
          :items="relationships"
          :success="relationshipValidation.success"
          :error-messages="relationshipValidation.errorMessages"
          clearable
        />
      </v-row>
      <v-row class="ma-0">
        <decimal-input
          v-if="displayBeneficiaryQualifier"
          v-model="contractParty.beneficiaryAmount"
          data-testid="beneficiary-amount"
          data-lpignore="true"
          :prepend-inner-icon="mdiPercent"
          label="Beneficiary Percentage"
          type="text"
          inputmode="numeric"
          :min="0"
          :max="100"
          :decimal-length="2"
          :success="beneficiaryAmountValidation.success"
          :error-messages="beneficiaryAmountValidation.errorMessages"
        />
      </v-row>
      <v-row v-if="displayRatingApplied" dense>
        <v-col cols="6">
          <autocomplete-field
            v-model="contractParty.ratingApplied"
            data-testid="rating-applied"
            :prepend-inner-icon="mdiStethoscope"
            label="Rating Applied"
            clearable
            :items="RATINGS_BY_LINE[productLine]"
            :success="Boolean(contractParty.ratingApplied)"
          />
        </v-col>
        <v-col cols="6">
          <autocomplete-field
            v-model="contractParty.ratingApproved"
            data-testid="rating-approved"
            :prepend-inner-icon="mdiStethoscope"
            label="Rating Approved"
            clearable
            :items="RATINGS_BY_LINE[productLine]"
            :success="Boolean(contractParty.ratingApproved)"
          />
        </v-col>
        <v-col v-if="displayOccupationClass" cols="12">
          <autocomplete-field
            v-model="contractParty.occupationClass"
            label="Occupation Class"
            data-testid="occupation-class"
            :prepend-inner-icon="mdiBriefcase"
            :items="OCCUPATION_CLASSES"
            :success="occupationClassValidation.success"
            :error-messages="occupationClassValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <underwriting-rationale
            v-model="contractParty.underwritingRationale"
            data-testid="underwriting-rationale"
            :prepend-inner-icon="mdiText"
            class="pb-3"
            :success="Boolean(contractParty.underwritingRationale)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import UnderwritingRationale from "@/components/shared/UnderwritingRationale.vue";
import ContractPartySearch from "@/components/shared/ContractPartySearch.vue";
import DecimalInput from "@/components/shared/DecimalInput.vue";

import {
  ENTITY_RELATIONSHIPS,
  INDIVIDUAL_RELATIONSHIPS,
  MUTUALLY_EXCLUSIVE_ROLES,
  ROLES_BY_LINE
} from "@/models/Role";
import { RATINGS_BY_LINE, OCCUPATION_CLASSES } from "@/data/case-data";

import { ContractPartySearchItem } from "@/models/ContractParty";
import { useDialogStore } from "@/stores/dialog";
import { ref, watch, toRefs, computed, markRaw } from "vue";
import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import NewPartyDialog from "@/components/cases/case-dialogs/NewPartyDialog.vue";
import {
  mdiDelete,
  mdiBriefcase,
  mdiPercent,
  mdiStethoscope,
  mdiText
} from "@mdi/js";
import { useDisplay } from "vuetify";

const props = defineProps({
  productLine: { type: String, default: "" },
  insured: { type: Object, default: () => {} },
  modelValue: { type: Object, default: () => {} }
});
const emit = defineEmits(["update:model-value", "delete-contract-party"]);
const { productLine } = toRefs(props);
const contractParty = ref(props.modelValue);
const showMore = ref(false);

const dialog = useDialogStore();

const relationships = computed(() => {
  if (!contractParty.value?.party?.type) return [];
  return contractParty.value.party.type === "Entity"
    ? ENTITY_RELATIONSHIPS
    : INDIVIDUAL_RELATIONSHIPS;
});

const displayOccupationClass = computed(
  () =>
    contractParty.value.roles.includes("Insured") &&
    ["disability"].includes(productLine.value)
);

const displayBeneficiaryQualifier = computed(() =>
  contractParty.value.roles.some(val => val.includes("Beneficiary"))
);
const displayRatingApplied = computed(() =>
  contractParty.value.roles.some(val => val.includes("Insured"))
);
const displayRelationship = computed(() =>
  contractParty.value.roles.every(
    r => !r.includes("Insured") && !r.includes("Annuitant")
  )
);

const v$ = useVuelidate(
  {
    contractParty: {
      ratingApplied: {},
      ratingApproved: {},
      underwritingRationale: {},
      party: { required: Boolean },
      relationship: {
        required: v => !displayRelationship.value || Boolean(v)
      },
      beneficiaryAmount: {
        required: v => !displayBeneficiaryQualifier.value || (v > 0 && v <= 100)
      },
      occupationClass: {
        required: v => !displayOccupationClass.value || Boolean(v)
      },
      roles: { required: Boolean }
    }
  },
  { contractParty },
  { $scope: "new-case", $autoDirty: true }
);

const relationshipValidation = computedValidation(
  v$.value.contractParty.relationship,
  [{ key: "required", message: "Required" }]
);
const partyValidation = computedValidation(v$.value.contractParty.party, [
  { key: "required", message: "Required" }
]);
const beneficiaryAmountValidation = computedValidation(
  v$.value.contractParty.beneficiaryAmount,
  [{ key: "required", message: "Required" }]
);
const occupationClassValidation = computedValidation(
  v$.value.contractParty.occupationClass,
  [{ key: "required", message: "Required" }]
);

async function createContractParty() {
  const result = await dialog.showDialog({
    component: markRaw(NewPartyDialog),
    scrollable: true,
    insuredAddress: props.insured?.party?.address,
    hideRole: true,
    hideNetWorth: true
  });
  if (!result?.party) return;
  const party = result.party;
  //convert to match search result
  const basicContractParty = ContractPartySearchItem();
  if (party.type === "Individual") {
    basicContractParty.name =
      party.name || `${party.firstName} ${party.lastName}`;
    basicContractParty.address = party.homeAddress;
    basicContractParty.type = "Individual";
  } else {
    basicContractParty.name = party.name;
    basicContractParty.address = party.businessAddress;
    basicContractParty.type = "Entity";
  }
  basicContractParty.id = party.id;
  contractParty.value.party = basicContractParty;
}

const contractPartyRoles = computed(() =>
  contractParty.value.roles.reduce((acc, r) => ({ ...acc, [r]: true }), {})
);

const { smAndDown } = useDisplay();
const truncatedRoles = computed(() => smAndDown.value);

const visibleRoles = computed(() => {
  let roles = [...topRoles.value];

  if (showMore.value || !truncatedRoles.value) {
    roles.push(...remainingRoles.value);
  }

  return roles.map(r => ({
    title: r,
    value: r,
    disabled: Boolean(contractPartyRoles.value[MUTUALLY_EXCLUSIVE_ROLES[r]])
  }));
});

const topRoles = computed(() => {
  if (!ROLES_BY_LINE[productLine.value]) return [];
  return ROLES_BY_LINE[productLine.value].slice(0, 5);
});
const remainingRoles = computed(() => {
  if (!ROLES_BY_LINE[productLine.value]) return [];
  return ROLES_BY_LINE[productLine.value].filter(
    v => !topRoles.value.includes(v)
  );
});

watch(contractParty, () => emit("update:model-value", contractParty.value), {
  deep: true
});
watch(productLine, () => {
  if (!productLine.value) return;
  contractParty.value.roles = contractParty.value.roles
    .map(r => {
      if (productLine.value === "annuity") {
        return r.replace("Insured", "Annuitant");
      }
      return r.replace("Annuitant", "Insured");
    })
    .filter(r => ROLES_BY_LINE[productLine.value].includes(r));
});

watch(displayRelationship, () => (contractParty.value.relationship = null));
</script>
