<template>
  <v-fade-transition>
    <v-card
      v-if="showTour"
      id="tour-guide"
      elevation="8"
      role="tooltip"
      width="300"
      :dark="!theme.current.value.dark"
    >
      <v-card-title class="justify-center">
        {{ tourTitle }}
      </v-card-title>
      <v-card-text class="text-center">
        {{ tourText }}
      </v-card-text>
      <v-card-actions>
        <app-button
          class="text-none"
          variant="text"
          size="small"
          :disabled="currentStep === 0"
          @click="previous"
        >
          Previous
        </app-button>
        <app-button class="text-none" small variant="text" @click="tourSkipped">
          Skip
        </app-button>
        <v-spacer />
        <app-button
          v-if="currentStep < steps.length - 2"
          color="primary"
          class="text-none"
          size="small"
          @click="next"
        >
          Next
        </app-button>
        <app-button
          v-else
          color="primary"
          class="text-none"
          size="small"
          @click="tourFinished"
        >
          Complete
        </app-button>
      </v-card-actions>
      <div id="arrow" data-popper-arrow></div>
    </v-card>
  </v-fade-transition>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { createPopper } from "@popperjs/core/lib/popper-lite";
import offset from "@popperjs/core/lib/modifiers/offset";
import arrow from "@popperjs/core/lib/modifiers/arrow";
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";
import { useTheme } from "vuetify";
import { markRaw } from "vue";
import WelcomeTourDialog from "@/dialogs/WelcomeTourDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
export default {
  setup() {
    const theme = useTheme();
    return { theme };
  },
  data() {
    return {
      showTour: false,
      currentStep: 0,
      tourTitle: null,
      tourText: null
    };
  },

  computed: {
    ...mapState(useUserStore, {
      userId: "id",
      loginable: "loginable",
      isGroupOne: "isGroupOne"
    }),
    currentStepObj() {
      return this.steps[this.currentStep];
    },
    steps() {
      const steps = [];
      if (document.getElementById("launch-quote-and-apply")) {
        steps.push({
          target: "#launch-quote-and-apply",
          title: "Launch Quote & Apply",
          text: "You can launch Quote & Apply from any page with this button.",
          placement: "bottom"
        });
      }
      steps.push({
        target: "#user-menu",
        title: "User Menu",
        text: "View helpful options like account settings, viewing your profile, changing light/dark mode, and logging out.",
        placement: "bottom",
        before: () => this.hideUserMenu()
      });
      steps.push({
        target: "#v-step-2",
        title: "User Settings",
        text: "Settings allows you to edit your account and contracting information.",
        placement: "left",
        before: () => {
          window.scrollTo({ top: 0, left: 0 });
          this.showUserMenu();
        }
      });
      steps.push({
        target: "#v-step-3",
        title: "Home Page",
        text: "Check out your top cases and recent activity from the home tab",
        placement: "right",
        before: () => this.hideUserMenu()
      });
      steps.push({
        target: "#quote-and-apply-nav",
        title: "Quote & Apply Dashboard",
        text: "View Quote & Apply Sites and eApps",
        placement: "right"
      });
      steps.push({
        target: "#cases-nav",
        title: "Case Dashboard",
        text: "View your cases",
        placement: "right"
      });
      if (!this.loginable.hide_commission && this.loginable.show_backnine) {
        steps.push({
          target: "#commissions-nav",
          title: "Commission Dashboard",
          text: "Track your commissions and view product commissions",
          placement: "right"
        });
      }
      if (this.isGroupOne) {
        steps.push({
          target: "#schedule-a-meeting",
          title: "Schedule a Meeting",
          text: "If you have any more questions, click here to schedule a meeting!",
          placement: "end"
        });
      }

      return steps;
    }
  },
  created() {
    this.startTour();
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    ...mapActions(useUserStore, ["completeTutorial", "saveTutorials"]),
    showUserMenu() {
      const userMenu = document.getElementById("user-menu");
      if (!userMenu) return;
      if (userMenu.getAttribute("aria-expanded") === "true") return;
      userMenu.click();
    },
    hideUserMenu() {
      const userMenu = document.getElementById("user-menu");
      if (!userMenu) return;
      if (userMenu.getAttribute("aria-expanded") === "false") return;
      userMenu.click();
    },
    async startTour() {
      const res = await this.showDialog({
        component: markRaw(WelcomeTourDialog),
        persistent: true
      });
      if (res?.stop) {
        this.tourSkipped();
        return;
      }

      this.showTour = true;
      this.$nextTick(() => {
        this.renderPopover();
      });
    },
    tourSkipped() {
      this.completeTutorial("home");
      this.saveTutorials();
      this.hideUserMenu();
    },
    tourFinished() {
      this.tourSkipped();
      this.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Welcome Tour Completed",
        subtitle: "You've completed the introductory tour of BOSS.",
        hideCancel: true,
        confirmText: "Continue to BOSS"
      });
    },
    previous() {
      this.currentStep--;
      this.$nextTick(() => {
        this.renderPopover();
      });
    },
    next() {
      this.currentStep++;
      this.$nextTick(() => {
        this.renderPopover();
      });
    },
    async renderPopover() {
      if (this.currentStepObj.before) {
        this.currentStepObj.before();
        await new Promise(r => setTimeout(r, 50));
      }

      this.tourTitle = this.currentStepObj.title;
      this.tourText = this.currentStepObj.text;

      const target = document.querySelector(this.currentStepObj.target);
      target.setAttribute("aria-describedby", "tooltip");
      const tourGuide = document.querySelector("#tour-guide");

      createPopper(target, tourGuide, {
        placement: this.currentStepObj.placement,
        modifiers: [
          arrow,
          offset,
          preventOverflow,
          {
            name: "offset",
            options: {
              offset: [0, 8]
            }
          }
        ]
      });
    }
  }
};
</script>

<style scoped lang="scss">
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tour-guide[data-popper-placement^="top"] > #arrow {
  bottom: -px;
}

#tour-guide[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tour-guide[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tour-guide[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
</style>
