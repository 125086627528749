<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-row dense align="center">
        <v-col cols="12" sm="6" order="2" order-sm="1" data-testid="insured">
          <v-row justify="center" dense justify-sm="start" class="ma-0 pa-3">
            <div>
              <strong style="font-size: 1.2rem"> {{ insured.name }} </strong>
              <br />
              Insured
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" order="1" order-sm="2">
          <v-row justify="center" justify-sm="end" class="ma-0 pa-3">
            <carrier-image
              :id="carrier.id"
              data-testid="carrier"
              :image="carrier.avatarUrl"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <template v-for="group in detailGroups" :key="group.key">
        <card-items :items="group.items" />
        <v-divider />
      </template>

      <v-divider />
      <v-alert
        v-if="underwritingRationaleItems.length"
        type="info"
        class="ma-3"
        border="left"
        variant="outlined"
        data-testid="underwriting-rationale"
      >
        <div>
          <div>
            <strong>Reasons for Underwriting Decision:</strong>
          </div>
          <div
            v-for="(detail, index) in underwritingRationaleItems"
            :key="index"
          >
            {{ detail.title }}: {{ detail.text }}
          </div>
        </div>
      </v-alert>
      <v-row
        v-if="primaryContact || marketingManager.schedulingLink"
        class="ma-0 py-3 px-4"
        data-testid="case-card-contact"
        align="center"
      >
        <div>
          <strong> Need Help? </strong>
          <template v-if="primaryContact">
            Call {{ primaryContact.name }}
            <a
              :href="`tel:${primaryContact.phone}`"
              data-outbound-type="Agent"
              :data-outbound-id="primaryContact.id"
              :data-outbound-number="primaryContact.phone"
            >
              {{ primaryContact.phone }}</a
            >
          </template>
          <template v-if="marketingManager.schedulingLink">
            <template v-if="primaryContact"> or you can </template>
            <a :href="marketingManager.schedulingLink" target="_blank">
              Schedule a meeting with
              {{ marketingManager.name }}
            </a>
          </template>
        </div>
        <slot name="actions" />
      </v-row>
      <slot v-else name="actions" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import CarrierImage from "@/components/shared/CarrierImage.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import { computed } from "vue";
import { useCaseViewStore } from "@/stores/case-view";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import {
  currencyFormat,
  lineFormatter,
  modeFormat,
  timestampFormatter
} from "@/util/helpers";
import { fullStateDict } from "@/data/states";
import {
  routerLinkItem,
  routerLinkItemList,
  textItem
} from "@/components/shared/card-items/card-items";

const user = useUserStore();
const caseView = useCaseViewStore();
const {
  agents,
  appliedMode,
  appliedPremium,
  appliedPremiumTotal,
  carrier,
  carrierCaseManager,
  carrierEDelivery,
  caseConcierge,
  caseIsApproved,
  caseManager,
  cashReceived,
  chronicIllnessRider,
  commission,
  conversion,
  exchangeAmount,
  faceAmount,
  gaCode,
  insured,
  insuredRole,
  line,
  LINE,
  ltcRider,
  marketingManager,
  mode,
  policyDate,
  policyNumber,
  premium,
  product,
  primaryContact,
  saveAge,
  state,
  status,
  statusDate,
  targetPremium,
  tia,
  totalPremium,
  underwriter,
  usesDeathBenefit
} = storeToRefs(caseView);

const topCaseDetails = computed(() => {
  const details = [];

  // Agents
  details.push(
    routerLinkItemList({
      title: agents.value.length > 1 ? "Agents" : "Agent",
      links: agents.value.map(a => ({
        text: a.name,
        to: a.routerLink,
        key: a.id + a.type
      }))
    })
  );

  // case manager is being set wrongly

  if (
    caseConcierge.value?.id === caseManager.value?.id &&
    caseConcierge.value?.type === caseManager.value?.type
  ) {
    details.push(
      routerLinkItem({
        title: "Case Concierge and Case Manager",
        text: caseConcierge.value.name,
        to: { name: "Contact" }
      })
    );
  } else {
    if (caseConcierge.value?.id) {
      details.push(
        routerLinkItem({
          title: "Case Concierge",
          text: caseConcierge.value.name,
          to: { name: "Contact" }
        })
      );
    }
    if (caseManager.value?.id) {
      details.push(
        routerLinkItem({
          title: "Case Manager",
          text: caseManager.value.name,
          to: { name: "Contact" }
        })
      );
    }
  }

  if (underwriter.value.id) {
    details.push(
      routerLinkItem({
        title: "Underwriter",
        text: underwriter.value.name,
        to: { name: "PersonnelView", params: { id: underwriter.value.id } }
      })
    );
  } else {
    details.push(
      textItem({
        title: "Underwriter",
        text: underwriter.value.name
      })
    );
  }

  if (carrierCaseManager.value) {
    details.push(
      routerLinkItem({
        title: "Carrier Case Manager",
        text: carrierCaseManager.value.name,
        to: {
          name: "PersonnelView",
          params: { id: carrierCaseManager.value.id }
        }
      })
    );
  }

  if (gaCode.value) {
    if (user.isGroupTwoPlus) {
      details.push(
        routerLinkItem({
          title: "GA Code",
          text: gaCode.value.name,
          to: { name: "AppointmentView", params: { id: gaCode.value.id } }
        })
      );
    } else {
      details.push(
        textItem({
          title: "GA Code",
          text: gaCode.value.name
        })
      );
    }
  }

  if (policyDate.value) {
    details.push(
      textItem({
        title: "Policy Date",
        text: timestampFormatter(policyDate.value, "sole-day")
      })
    );
  }

  return details;
});

const caseDetails = computed(() => {
  const details = [
    textItem({
      title: "Policy Number",
      text: policyNumber.value
    })
  ];

  let statusText = status.value;
  if (statusDate.value) statusText += ` (${statusDate.value})`;

  details.push(
    textItem({
      title: "Status",
      text: statusText
    })
  );

  details.push(
    textItem({
      title: "State",
      text: fullStateDict[state.value]
    })
  );

  if (cashReceived.value) {
    details.push(
      textItem({
        title: "Cash Received",
        text: currencyFormat(cashReceived.value, 0)
      })
    );
  }

  if (exchangeAmount.value) {
    details.push(
      textItem({
        title: "Exchange Amount",
        text: currencyFormat(exchangeAmount.value, 0)
      })
    );
  }

  if (LINE.value.INFORMAL) {
    details.push(
      textItem({
        title: "Distribution",
        text: carrierEDelivery.value ? "eDelivery" : "Paper Delivery"
      })
    );
  }

  if (appliedPremium.value || appliedPremiumTotal.value) {
    let text = "",
      totalText = "";
    if (appliedPremiumTotal.value) {
      totalText = `${currencyFormat(appliedPremiumTotal.value)} Total`;
    }

    if (appliedPremium.value) {
      text = `${currencyFormat(appliedPremium.value)} / ${modeFormat(
        appliedMode.value,
        "abbreviated"
      )}`;
      if (totalText) text += ` (${totalText})`;
    } else if (totalText) {
      text = totalText;
    }

    details.push(
      textItem({
        title: "Applied Premium",
        text
      })
    );
  }

  if (caseIsApproved.value && totalPremium.value > 0) {
    details.push(
      textItem({
        title: "Approved Premium",
        text: `${currencyFormat(premium.value)} / ${modeFormat(
          mode.value,
          "abbreviated"
        )} (${currencyFormat(totalPremium.value)} Total)`
      })
    );
  }

  if (commission.value) {
    details.push(
      textItem({
        title: "Commission Percentage",
        text: `${commission.value}%`
      })
    );
  }

  if (targetPremium.value) {
    details.push(
      textItem({
        title: "Target Premium",
        text: currencyFormat(targetPremium.value, 0)
      })
    );
  }

  return details;
});

const productDetails = computed(() => {
  const details = [];

  if (product.value?.name && !LINE.value.INFORMAL) {
    details.push(
      routerLinkItem({
        title: `${lineFormatter(line.value)} Product`,
        text: product.value.name,
        to: {
          name: "ProductView",
          params: { id: product.value.id }
        }
      })
    );
  }

  if (usesDeathBenefit.value) {
    details.push(
      textItem({
        title: "Death Benefit",
        text: currencyFormat(faceAmount.value, 0)
      })
    );
  }

  if (ltcRider.value) {
    details.push(
      textItem({
        title: "LTC Rider",
        text: "Has LTC Rider"
      })
    );
  }

  if (chronicIllnessRider.value) {
    details.push(
      textItem({
        title: "Chronic Illness Rider",
        text: "Has Chronic Illness Rider"
      })
    );
  }

  if (tia.value) {
    details.push(
      textItem({
        title: "Temporary Insurance",
        text: "Has Temporary Insurance"
      })
    );
  }

  if (!LINE.value.ANNUITY) {
    details.push(
      textItem({
        title: "Save Age",
        text: saveAge.value ? "Yes" : "No"
      })
    );
  }

  if (conversion.value) {
    details.push(
      textItem({
        title: "Conversion",
        text: "Yes"
      })
    );
  }

  return details;
});

const insuredDetails = computed(() => {
  const details = [];
  if (insuredRole.value?.ratingApplied) {
    details.push(
      textItem({
        title: "Rating Applied",
        text: insuredRole.value.ratingApplied
      })
    );
  }

  if (insuredRole.value?.ratingApproved) {
    details.push(
      textItem({
        title: "Rating Approved",
        text: insuredRole.value.ratingApproved
      })
    );
  }

  if (insured.value?.gender) {
    details.push(
      textItem({
        title: "Gender",
        text: insured.value.gender
      })
    );
  }

  return details;
});

const detailGroups = computed(() => {
  const groups = [];
  if (topCaseDetails.value.length) {
    groups.push({ key: "topCaseDetails", items: topCaseDetails.value });
  }
  if (caseDetails.value.length) {
    groups.push({ key: "caseDetails", items: caseDetails.value });
  }
  if (productDetails.value.length) {
    groups.push({ key: "productDetails", items: productDetails.value });
  }
  if (insuredDetails.value.length) {
    groups.push({ key: "insuredDetails", items: insuredDetails.value });
  }

  return groups;
});

const underwritingRationaleItems = computed(() => {
  return (
    Object.keys(insuredRole.value.underwritingRationale).map(val => ({
      title: val,
      text: insuredRole.value.underwritingRationale[val]
    })) || []
  );
});
</script>
