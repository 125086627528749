export const PERMISSION = {
  FORM_MAPPING: "form_mapping",
  ACCOUNTING: "accounting",
  SCORECARD: "scorecard",
  MARKETING_MANAGER: "marketing_manager"
};

export const PERMISSION_TEXT = {
  [PERMISSION.FORM_MAPPING]: "Form Mapping",
  [PERMISSION.ACCOUNTING]: "Accounting",
  [PERMISSION.SCORECARD]: "Scorecard",
  [PERMISSION.MARKETING_MANAGER]: "Marketing Manager",
};

export const PERMISSION_FILTER_ITEMS = Object.values(PERMISSION).map(rp => ({
  title: PERMISSION_TEXT[rp],
  value: rp
}));
