<template>
  <v-card :id="id" v-bind="cardProps">
    <v-card-title data-testid="chat-header">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-row class="ma-0" align="center">
            <slot name="title" />
            {{ title }}
            <v-spacer />
            <v-tooltip>
              <template #activator="{ props: templateProps }">
                <app-button
                  v-if="documents.length"
                  variant="text"
                  density="comfortable"
                  color="primary"
                  v-bind="templateProps"
                  :icon="displayingDocuments ? mdiFileCancel : mdiFileDocument"
                  @click="displayingDocuments = !displayingDocuments"
                />
              </template>
              <span>
                {{ displayingDocuments ? "Hide" : "Show" }} All Documents
              </span>
            </v-tooltip>
            <v-menu v-if="room.length" offset-y>
              <template #activator="{ props: templateProps }">
                <app-button
                  v-bind="templateProps"
                  variant="text"
                  class="default-btn"
                  density="comfortable"
                  :icon="mdiAccountMultiple"
                />
              </template>
              <v-list>
                <v-list-item
                  v-for="(person, index) in room"
                  :key="index"
                  link
                  @click="displayContactDetails(person)"
                >
                  {{ person.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
        <v-col v-if="displayingDocuments" cols="12" class="pa-0">
          <v-row align="center" class="ma-0" justify="end">
            <a
              v-for="document in documents"
              :key="document.id"
              style="font-size: 12px"
              v-bind="downloadDocument(document.uid)"
            >
              {{ document.name }}
              <span v-if="document.fileSize" class="text-grey">
                ({{ document.fileSize }})
              </span>
              <v-icon color="primary" size="small" :icon="mdiDownload" />
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-row class="ma-0">
      <v-col class="pa-0">
        <chat-view-body
          v-if="hasFocused"
          ref="chatBody"
          :messages="messages"
          @remove-message="removeMessage"
        />
        <chat-view-footer
          class="chat-footer"
          :no-documents="noDocuments"
          :checkboxes="checkboxes"
          :additional-buttons="additionalButtons"
          :create-func="createFunc"
          @select-template="emit('select-template')"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import ChatViewBody from "@/components/shared/chat/ChatViewBody.vue";
import ChatViewFooter from "@/components/shared/chat/ChatViewFooter.vue";
import ContactDetailsDialog from "@/dialogs/ContactDetailsDialog.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { initSocketForChat } from "@/util/socket-helper-service";
import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiDownload,
  mdiFileCancel,
  mdiFileDocument,
  mdiAccountMultiple
} from "@mdi/js";

import {
  computed,
  markRaw,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs
} from "vue";
import { useDisplay } from "vuetify";

const emit = defineEmits(["remove-message", "select-template", "new-note"]);

const props = defineProps({
  noDocuments: Boolean,
  title: { type: String, required: false, default: null },
  pusherId: { type: String, required: true },
  messages: { type: Array, required: true },
  room: { type: Array, required: false, default: () => [] },
  checkboxes: { type: Array, required: false, default: () => [] },
  additionalButtons: { type: Array, required: false, default: () => [] },
  createFunc: { type: Function, required: true },
  cardProps: {
    type: Object,
    default: () => ({ color: "section", tile: true, flat: true })
  },
  id: {
    default: "chat-container",
    required: false,
    type: [String, Number]
  }
});
const { mdAndDown } = useDisplay();
const dialog = useDialogStore();

const { messages } = toRefs(props);
const displayingDocuments = ref(false);
const hasFocused = ref(false);

onMounted(() => {
  subscribe();
  const el = document.getElementById(props.id);
  const handleResize = () => {
    if (el.clientHeight && !hasFocused.value) hasFocused.value = true;
  };
  const observer = new ResizeObserver(handleResize);
  observer.observe(el);
});

onBeforeUnmount(() => unsubscribe());

const documents = computed(() =>
  messages.value.reduce(
    (acc, message) =>
      message?.documents ? [...acc, ...message.documents] : acc,
    []
  )
);

function removeMessage(message) {
  emit("remove-message", message);
}

let pusher;
function unsubscribe() {
  if (pusher?.disconnect) pusher.disconnect();
}
function subscribe() {
  pusher = initSocketForChat(props.pusherId, {
    newNoteHandler: n => emit("new-note", n)
  });
}
function displayContactDetails(contact) {
  dialog.showDialog({
    component: markRaw(ContactDetailsDialog),
    contact
  });
}

function downloadDocument(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}
</script>

<style lang="scss">
.chat-header {
  height: min(calc(10vh - 8.8px), 4rem);
}
</style>
