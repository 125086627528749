import { setAddressFromRaw, Address } from "@/models/Address";
import { setCompBuilderFromRequest } from "@/models/CompBuilder";
import { setDocumentFromRequest } from "@/models/Document";
import { lineFormatter, listToSentence } from "@/util/helpers";

export const ADDRESS = {
  NEW_BUSINESS: "new_business"
};

export function CarrierCompBuilderKey(id) {
  return `c_${id}`;
}

export const RAW_PHONE_NUMBER = {
  LICENSING: "licensing",
  LICENSING_FAX: "licensing_fax",
  NEW_BUSINESS: "new_business",
  NEW_BUSINESS_FAX: "new_business_fax",
  CUSTOMER_SERVICE: "customer_service",
  SALES_DESK: "sales_desk"
};

export const RAW_PERSONNEL_PREFIX = {
  LICENSING: "licensing",
  NEW_BUSINESS: "new_business",
  NEW_APP: "new_app",
  CUSTOMER_SERVICE: "customer_service"
};

export const LINE = {
  DISABILITY: "disability",
  LIFE: "life",
  ANNUITY: "annuity",
  LTC: "ltc"
};

export const LINE_TEXT = {
  [LINE.LIFE]: "Life",
  [LINE.DISABILITY]: "Disability",
  [LINE.ANNUITY]: "Annuity",
  [LINE.LTC]: "LTC"
};

const LINES = Object.values(LINE);

export function Carrier(model = {}) {
  return {
    addressee: model?.addressee || "",
    autoSubmitContracting: model?.autoSubmitContracting || false,
    avatar: model?.avatar || "",
    checkCommissionSchedule: model?.checkCommissionSchedule || "",
    compBuilders: {
      by_id: model?.compBuilders?.by_id || {},
      ids: model?.compBuilders?.ids || []
    },
    details: model?.details || "",
    documents: model?.documents || [],
    eftCommissionSchedule: model?.eftCommissionSchedule || false,
    emailUnderwriter: model?.emailUnderwriter || false,
    emailEo: model?.emailEo || false,
    examOneCode: model?.examOneCode || null,
    firelightCarrierCode: model?.firelightCarrierCode || null,
    holdForAppointment: model?.holdForAppointment || false,
    id: model?.id || null,
    naic: model?.naic || null,
    amBestRating: model?.amBestRating || null,
    name: model?.name || "",
    paramedsAccountNumber: model?.paramedsAccountNumber || null,
    paramedsCode: model?.paramedsCode || null,
    quotingStates: model?.quotingStates || [],
    requiresWetSignature: model?.requires_wet_signature,
    strifeApproved: Boolean(model?.strifeApproved),
    strifeDisclosure: model?.strifeDisclosure || "",
    supportsInstantAps: model?.supportsInstantAps || false,
    defaultAppointmentManager: AppointmentManager(
      model?.defaultAppointmentManager
    ),

    // By Line data
    newBusinessPhoneByLine: PhoneNumberByLine(model?.newBusinessPhoneByLine),
    licensingPhoneByLine: PhoneNumberByLine(model?.licensingPhoneByLine),
    salesDeskPhoneByLine: PhoneNumberByLine(model?.salesDeskPhoneByLine),
    newBusinessFaxByLine: PhoneNumberByLine(model?.newBusinessFaxByLine),
    licensingFaxByLine: PhoneNumberByLine(model?.licensingFaxByLine),
    customerServicePhoneByLine: PhoneNumberByLine(
      model?.customerServicePhoneByLine
    ),
    emailsByLine: EmailsByLine(model?.emailsByLine),
    licensingPersonnelByLine: PersonnelByLine(model?.licensingPersonnelByLine),
    newBusinessPersonnelByLine: PersonnelByLine(
      model?.newBusinessPersonnelByLine
    ),
    newAppPersonnelByLine: PersonnelByLine(model?.newAppPersonnelByLine),
    customerServicePersonnelByLine: PersonnelByLine(
      model?.customerServicePersonnelByLine
    ),
    websitesByLine: WebsitesByLine(model?.websitesByLine),
    newBusinessAddressByLine: AddressByLine(
      model?.newBusinessAddressByLine,
      ADDRESS.NEW_BUSINESS
    ),
    trainingByLine: DataByLine(model?.trainingByLine),
    detailsByLine: DataByLine(model?.detailsByLine),
    activesByLine: DataByLine(model?.activesByLine),
    annualizationByLine: DataByLine(model?.annualizationByLine),
    annualizationDetailsByLine: DataByLine(model?.annualizationDetailsByLine),
    autoSubmitByLine: DataByLine(model?.autoSubmitByLine),
    dtccByLine: DataByLine(model?.dtccByLine),
    eDeliveryByLine: DataByLine(model?.eDeliveryByLine),
    eSignatureByLine: DataByLine(model?.eSignatureByLine),
    gaCanOrderApsByLine: DataByLine(model?.gaCanOrderApsByLine),
    multipleAppointmentsByLine: DataByLine(model?.multipleAppointmentsByLine),
    paymentScheduleByLine: DataByLine(model?.paymentScheduleByLine),
    paysFullOverrideByLine: DataByLine(model?.paysFullOverrideByLine),
    preappointmentStatesByLine: DataByLine(
      model?.preappointmentStatesByLine,
      []
    ),
    reg187TrainingByLine: DataByLine(model?.reg187TrainingByLine),
    requiresOriginalApplicationByLine: DataByLine(
      model?.requiresOriginalApplicationByLine
    ),
    sendContractingByLine: DataByLine(model?.sendContractingByLine)
  };
}

export function setCarrierFromRequest(raw = {}) {
  const model = Carrier();
  model.addressee = raw?.addressee;
  model.amBestRating = raw?.am_best_rating;
  model.autoSubmitContracting = raw?.auto_submit_contracting;
  model.avatar = raw?.avatar_url;
  model.checkCommissionSchedule = raw?.check_commission_schedule;
  model.details = raw?.details;
  model.documents = raw?.documents?.map(setDocumentFromRequest);
  model.eftCommissionSchedule = raw?.eft_commission_schedule;
  model.emailEo = raw?.email_eo;
  model.emailUnderwriter = raw?.email_underwriter;
  model.examOneCode = raw?.exam_one_code;
  model.firelightCarrierCode = raw?.firelight_carrier_code;
  model.holdForAppointment = raw?.hold_for_appointment;
  model.id = raw?.id;
  model.naic = raw?.naic;
  model.name = raw?.name;
  model.paramedsAccountNumber = raw?.parameds_acct_num;
  model.paramedsCode = raw?.parameds_code;
  model.requiresWetSignature = raw?.requires_wet_signature;
  model.strifeApproved = raw?.strife_approved;
  model.strifeDisclosure = raw?.strife_disclosure;
  model.supportsInstantAps = raw?.instant_aps;

  model.quotingStates = raw?.states?.map(val => val.name);
  model.quotingStates.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  model.defaultAppointmentManager = setAppointmentManagerFromRequest(
    raw?.default_appointment_manager
  );

  model.detailsByLine = setDataByLineFromRequest({
    [LINE.LIFE]: raw?.life?.details,
    [LINE.ANNUITY]: raw?.annuity?.details,
    [LINE.LTC]: raw?.ltc?.details,
    [LINE.DISABILITY]: raw?.disability?.details
  });

  model.training = setDataByLineFromRequest({
    [LINE.ANNUITY]: raw?.training
  });
  model.eSignatureByLine = setDataByLineFromRequest(raw?.e_signature);
  model.gaCanOrderApsByLine = setDataByLineFromRequest(raw?.ga_can_order_aps);
  model.multipleAppointmentsByLine = setDataByLineFromRequest(
    raw?.multiple_appointments
  );
  model.paymentScheduleByLine = setDataByLineFromRequest(raw?.payment_schedule);
  model.paysFullOverrideByLine = setDataByLineFromRequest(
    raw?.pays_full_override
  );
  model.preappointmentStatesByLine = setDataByLineFromRequest(
    raw?.preappointment_states,
    []
  );
  model.reg187ByLine = setDataByLineFromRequest(raw?.reg_187);
  model.requiresOriginalApplicationByLine = setDataByLineFromRequest(
    raw?.requires_original_application
  );
  model.sendContractingByLine = setDataByLineFromRequest(raw?.send_contracting);
  model.activesByLine = setDataByLineFromRequest(raw?.actives);
  model.annualizationByLine = setDataByLineFromRequest(raw?.annualization);
  model.annualizationDetailsByLine = setDataByLineFromRequest(
    raw?.annualization_details
  );
  model.autoSubmitByLine = setDataByLineFromRequestBool(raw?.auto_submit);
  model.dtccByLine = setDataByLineFromRequest(raw?.dtcc);
  model.eDeliveryByLine = setDataByLineFromRequest(raw?.e_delivery);
  model.websitesByLine = setWebsitesByLineFromRequest(raw?.websites);

  const phones = LINES.reduce((acc, l) => {
    let value = raw?.[`${l}_phone`];
    if (typeof value === "string") value = JSON.parse(value);
    return { ...acc, [l]: value };
  }, {});

  model.licensingPhoneByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.LICENSING
  );

  model.licensingFaxByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.LICENSING_FAX
  );

  model.newBusinessPhoneByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.NEW_BUSINESS
  );

  model.newBusinessFaxByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.NEW_BUSINESS_FAX
  );

  model.customerServicePhoneByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.CUSTOMER_SERVICE
  );

  model.salesDeskPhoneByLine = setPhoneNumbersByLineFromRequest(
    phones,
    RAW_PHONE_NUMBER.SALES_DESK
  );

  model.emailsByLine = setEmailsByLineFromRequest(raw?.emails);

  model.licensingPersonnelByLine = setPersonnelByLineFromRequest(
    raw,
    RAW_PERSONNEL_PREFIX.LICENSING
  );
  model.newBusinessPersonnelByLine = setPersonnelByLineFromRequest(
    raw,
    RAW_PERSONNEL_PREFIX.NEW_BUSINESS
  );
  model.newAppPersonnelByLine = setPersonnelByLineFromRequest(
    raw,
    RAW_PERSONNEL_PREFIX.NEW_APP
  );
  model.customerServicePersonnelByLine = setPersonnelByLineFromRequest(
    raw,
    RAW_PERSONNEL_PREFIX.CUSTOMER_SERVICE
  );

  model.newBusinessAddressByLine = setAddressByLineFromRequest(
    raw,
    ADDRESS.NEW_BUSINESS
  );

  if (Array.isArray(raw?.comp_builders)) {
    raw.comp_builders.forEach(raw => {
      const compBuilder = setCompBuilderFromRequest(raw);
      const key = CarrierCompBuilderKey(compBuilder.id);
      model.compBuilders.by_id[key] = compBuilder;
      model.compBuilders.ids.push(key);
    });
  }

  return model;
}

function AddressByLine(model = {}, addressType) {
  return {
    [LINE.ANNUITY]: Address({
      ...model?.annuity,
      addressType: `annuity,${addressType}`
    }),
    [LINE.DISABILITY]: Address({
      ...model?.annuity,
      addressType: `disability,${addressType}`
    }),
    [LINE.LIFE]: Address({
      ...model?.annuity,
      addressType: `life,${addressType}`
    }),
    [LINE.LTC]: Address({
      ...model?.annuity,
      addressType: `ltc,${addressType}`
    })
  };
}

function setAddressByLineFromRequest(raw = {}, addressType) {
  const model = AddressByLine({}, addressType);

  LINES.forEach(l => {
    const address = raw?.addresses?.find(
      a => a.address_type === `${l},${addressType}`
    );
    model[l] = setAddressFromRaw(address);
  });

  return model;
}

function DataByLine(model = {}, defaultValue = "") {
  return {
    [LINE.ANNUITY]: model?.[LINE.ANNUITY] || defaultValue,
    [LINE.DISABILITY]: model?.[LINE.DISABILITY] || defaultValue,
    [LINE.LIFE]: model?.[LINE.LIFE] || defaultValue,
    [LINE.LTC]: model?.[LINE.LTC] || defaultValue
  };
}

function setDataByLineFromRequest(raw = {}, defaultValue = null) {
  const model = DataByLine();
  let data = raw;
  if (typeof data === "string") data = JSON.parse(data);

  const valOrDefault = value => (value === undefined ? defaultValue : value);

  model.annuity = valOrDefault(data?.annuity);
  model.disability = valOrDefault(data?.disability);
  model.life = valOrDefault(data?.life);
  model.ltc = valOrDefault(data?.ltc);

  return model;
}

function setDataByLineFromRequestBool(raw = {}) {
  const model = DataByLine();
  let data = raw;
  if (typeof data === "string") data = JSON.parse(data);

  model.annuity = data?.annuity === "true";
  model.disability = data?.disability === "true";
  model.life = data?.life === "true";
  model.ltc = data?.ltc === "true";

  return model;
}

function WebsitesByLine(model = {}) {
  return {
    main: model?.main || null,
    [LINE.ANNUITY]: model?.[LINE.ANNUITY] || null,
    [LINE.DISABILITY]: model?.[LINE.DISABILITY] || null,
    [LINE.LIFE]: model?.[LINE.LIFE] || null,
    [LINE.LTC]: model?.[LINE.LTC] || null
  };
}

function setWebsitesByLineFromRequest(raw = {}) {
  const model = WebsitesByLine();
  let data = raw;
  if (typeof data === "string") data = JSON.parse(raw);

  model.main = data?.main;
  model[LINE.ANNUITY] = data?.[LINE.ANNUITY];
  model[LINE.DISABILITY] = data?.[LINE.DISABILITY];
  model[LINE.LIFE] = data?.[LINE.LIFE];
  model[LINE.LTC] = data?.[LINE.LTC];

  return model;
}

function PhoneNumberByLine(model = {}) {
  return {
    [LINE.ANNUITY]: model?.[LINE.ANNUITY] || null,
    [LINE.DISABILITY]: model?.[LINE.DISABILITY] || null,
    [LINE.LIFE]: model?.[LINE.LIFE] || null,
    [LINE.LTC]: model?.[LINE.LTC] || null
  };
}

function setPhoneNumbersByLineFromRequest(raw = {}, type) {
  const model = PhoneNumberByLine();

  LINES.forEach(line => (model[line] = raw[line][type]));

  return model;
}

function PersonnelByLine(model = {}) {
  return {
    [LINE.ANNUITY]: Personnel(model?.[LINE.ANNUITY]),
    [LINE.DISABILITY]: Personnel(model?.[LINE.DISABILITY]),
    [LINE.LIFE]: Personnel(model?.[LINE.LIFE]),
    [LINE.LTC]: Personnel(model?.[LINE.LTC])
  };
}

function setPersonnelByLineFromRequest(raw = {}, type) {
  const model = PersonnelByLine();

  LINES.forEach(line => {
    model[line] = setPersonnelFromRequest(raw?.[`${type}_${line}`]);
  });

  return model;
}

function Personnel(model = {}) {
  return {
    id: model?.id,
    type: model?.type,
    name: model?.name,
    email: model?.email
  };
}

function setPersonnelFromRequest(raw = {}) {
  const model = Personnel();
  model.id = raw?.id;
  model.type = raw?.type;
  model.name = raw?.name;
  model.email = raw?.email;
  return model;
}

export function EmailsByLine(model = {}) {
  return {
    [LINE.ANNUITY]: CarrierEmails(model?.[LINE.ANNUITY]),
    [LINE.DISABILITY]: CarrierEmails(model?.[LINE.DISABILITY]),
    [LINE.LIFE]: CarrierEmails(model?.[LINE.LIFE]),
    [LINE.LTC]: CarrierEmails(model?.[LINE.LTC])
  };
}

function setEmailsByLineFromRequest(raw = {}) {
  const model = EmailsByLine();
  let data = raw;
  if (typeof data === "string") data = JSON.parse(data);
  model[LINE.DISABILITY] = setCarrierEmailsFromRequest(data?.[LINE.DISABILITY]);
  model[LINE.LIFE] = setCarrierEmailsFromRequest(data?.[LINE.LIFE]);
  model[LINE.ANNUITY] = setCarrierEmailsFromRequest(data?.[LINE.ANNUITY]);
  model[LINE.LTC] = setCarrierEmailsFromRequest(data?.[LINE.LTC]);
  return model;
}

function CarrierEmails(model = {}) {
  return {
    quickQuote: model?.quickQuote || null,
    licensing: model?.licensing || null,
    newBusiness: model?.newBusiness || null,
    newApplication: model?.newApplication || null
  };
}

function setCarrierEmailsFromRequest(raw = {}) {
  const model = CarrierEmails();
  let data = raw;
  if (typeof data === "string") data = JSON.parse(data);
  model.licensing = data?.licensing;
  model.newBusiness = data?.new_business;
  model.newApplication = data?.new_application;
  model.quickQuote = data?.quick_quote;
  return model;
}

function AppointmentManager(model = {}) {
  return {
    name: model?.name,
    id: model?.id
  };
}

export function setAppointmentManagerFromRequest(raw = {}) {
  const model = AppointmentManager();
  model.name = raw?.name;
  model.id = raw?.id;
  return model;
}

export function CarrierTableItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    lines: model?.lines,
    website: model?.website,
    multipleUplines: model?.multipleUplines,
    annualization: model?.annualization,
    availableInQuoteAndApply: model?.availableInQuoteAndApply,
    websites: model?.websites
  };
}

export function setCarrierTableItemFromRaw(rawModel = {}) {
  const model = CarrierTableItem();
  model.id = rawModel?.id;
  model.name = rawModel?.name;
  model.lines = rawModel?.lines;
  model.multipleUplines = rawModel?.multiple_uplines;
  model.annualization = rawModel?.annualization;
  model.availableInQuoteAndApply = rawModel?.strife_approved;

  if (rawModel?.actives) {
    const lines = [];
    Object.keys(rawModel.actives).forEach(line => {
      if (!rawModel.actives[line]) return;
      lines.push(lineFormatter(line));
    });

    model.lines = listToSentence(lines);
  }

  if (rawModel?.websites) {
    const sites = [];
    let mainSite;

    Object.keys(rawModel.websites).forEach(v => {
      let website = rawModel.websites[v];
      if (website === "http://") return;
      website = website.replace("https://", "").replace("http://", "").trim();
      if (!website) return;
      if (v === "main") mainSite = website;
      else sites.push(website);
    });

    model.website = mainSite || sites?.[0];
  }
  return model;
}
