import { Employee } from "@/models/Employee";
import {
  getEmployee,
  createEmployeeReview,
  updateEmployee,
  deprovisionUser,
  deleteProvisioningTaskAnswer,
  updateProvisioningTaskAnswer
} from "@/api/employees.service";

import { defineStore } from "pinia";
import { parse } from "@/util/date-util";

export const useEmployeeView = defineStore("employee-view", {
  state: () => Employee(),
  getters: {
    name: s => `${s.firstName} ${s.lastName}`
  },
  actions: {
    async initialize(id) {
      this.$reset();
      const res = await getEmployee(id);
      this.$patch(res);
      this.sortReviews();
    },
    sortReviews() {
      this.reviews.sort(
        (a, b) =>
          parse(b.reviewDate, "yyyy-MM-dd") - parse(a.reviewDate, "yyyy-MM-dd")
      );
    },
    async createEmployeeReview(reviewText) {
      const result = await createEmployeeReview(this.id, reviewText);
      this.reviews.push(result);
      this.sortReviews();
    },
    updateMaxCases() {
      let max_cases = this.maxCases;
      if (![null, undefined].includes(max_cases)) max_cases = +this.maxCases;
      return updateEmployee(this.id, { max_cases });
    },
    updateTitle() {
      return updateEmployee(this.id, { title: this.title });
    },
    updateManager() {
      return updateEmployee(this.id, { manager_id: this.manager.id });
    },
    async deprovisionUser() {
      await deprovisionUser(this.id);
      await this.initialize(this.id);
    },
    updateTask(taskId, body) {
      return updateProvisioningTaskAnswer(this.id, taskId, body);
    },
    async deleteTask(taskId) {
      await deleteProvisioningTaskAnswer(this.id, taskId);
      const taskIndex = this.provisioningTasks.findIndex(i => i.id === taskId);
      if (taskIndex > -1) this.provisioningTasks.splice(taskIndex, 1);
    },
    addTask(task) {
      this.provisioningTasks.push(task);
    }
  }
});
