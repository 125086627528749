import { getHttpClient } from "@/http-client";
import {
  setCarrierStatusFileFromRequest,
  NewCarrierStatusFile,
  NewCarrierStatusFileToCreateRequest
} from "@/models/CarrierStatusFile";
import { serializeObject } from "@/util/helpers";

const baseUrl = "api/boss/carrier-status-files";
let cancelToken = null;

export async function getCarrierStatusFiles(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const result = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!result?.data) return;

  return {
    items: result.data.carrier_status_files.map(
      setCarrierStatusFileFromRequest
    ),
    meta: result.data.meta
  };
}

export async function createCarrierStatusFile(
  newCarrierStatusFile = NewCarrierStatusFile()
) {
  const body = await serializeObject({
    carrier_status_file:
      NewCarrierStatusFileToCreateRequest(newCarrierStatusFile)
  });

  return getHttpClient().post(baseUrl, body);
}
