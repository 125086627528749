import { getHttpClient } from "@/http-client";
import { setCaseSearchItemFromRequest } from "@/models/Case";
const baseUrl = "api/boss/search";

export async function advisorSearch(advisorName, { employee = false } = {}) {
  const body = {
    search: advisorName
  };
  if (employee) body.employee = true;
  const { data } = await getHttpClient().post(`${baseUrl}/advisors`, body);
  return data.advisors;
}

export async function simpleAdvisorSearch(advisorName) {
  const { data } = await getHttpClient().post(`${baseUrl}/advisors_simple`, {
    search: advisorName
  });

  return data.advisors;
}

export async function simpleAdvisorPaymentSearch(advisorName) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/advisors_payments_simple`,
    {
      search: advisorName
    }
  );

  return data.advisors;
}

export async function agentSearch(search, assignable = false) {
  const body = {
    search
  };

  if (assignable) body.assignable = true;
  const { data } = await getHttpClient().post(`${baseUrl}/agents`, body);

  return data.agents;
}

function CompBuilder(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    street: model?.street
  };
}

export async function compBuilderSearch(id) {
  const { data } = await getHttpClient().post(`${baseUrl}/comp_builders`, {
    carrier_id: id
  });

  return data.comp_builders.map(CompBuilder);
}

export function marketingManagerSearch(name) {
  return getHttpClient().post(`${baseUrl}/mms`, {
    search: name
  });
}

export function searchCaseManagers(name) {
  const params = new URLSearchParams();
  params.append("name", name);
  return getHttpClient().get(`${baseUrl}/search_case_managers_simple`, {
    params
  });
}

export async function policyNumberSearch(value) {
  const { data } = await getHttpClient().post(`${baseUrl}/cases`, {
    policy_number: value,
    with_carrier_payor: true
  });

  return data.cases
    .map(setCaseSearchItemFromRequest)
    .toSorted((a, b) => new Date(a.policyDate) - new Date(b.policyDate));
}

export async function simplePolicyNumberSearch(value) {
  const { data } = await getHttpClient().post(`${baseUrl}/cases_simple`, {
    policy_number: value,
    with_carrier_payor: true
  });
  return data.cases.map(i => ({ id: i.id, policyNumber: i.policy_number }));
}

export function payorSearch(search) {
  return getHttpClient().post(`${baseUrl}/payors`, {
    search: search
  });
}

export async function audienceSearch(carrier_id, name) {
  const params = new URLSearchParams();
  params.append("carrier_id", carrier_id);
  params.append("name", name);
  const { data } = await getHttpClient().get(`${baseUrl}/audience`, { params });
  return data.audience.map(setAudienceItemFromResponse);
}

export async function advisorAppointmentSearch(name, carrierId) {
  const params = new URLSearchParams();
  params.append("carrier_id", carrierId);
  params.append("search", name);
  const { data } = await getHttpClient().get(
    `${baseUrl}/advisors_custom_assignable`,
    {
      params
    }
  );

  return data.advisors;
}

export async function personnelSearch({ name, carrierId }) {
  const body = { name: undefined, carrier: undefined };
  if (name) body.name = name;
  if (carrierId) body.carrier = carrierId;
  const { data } = await getHttpClient().post(`${baseUrl}/personnel`, body);
  return data.personnel;
}

function AudienceItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type,
    email: model?.email
  };
}

function setAudienceItemFromResponse(raw = {}) {
  const audienceItem = AudienceItem();
  audienceItem.id = raw.id;
  audienceItem.name = raw.name;
  audienceItem.type = raw.type;
  audienceItem.email = raw.email;
  return audienceItem;
}
