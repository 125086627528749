<template>
  <v-card>
    <v-fade-transition mode="out-in">
      <v-progress-circular v-if="loading" key="loader" indeterminate />
      <div v-else-if="user.id" key="content">
        <v-card-title> {{ user.email }} </v-card-title>
        <v-divider />
        <v-alert
          v-if="user.isLocked"
          key="locked"
          data-testid="is-locked-banner"
          type="error"
          class="ma-3"
        >
          This user has been locked.
        </v-alert>
        <template v-for="(detailGroup, index) in detailGroups" :key="index">
          <card-items :items="detailGroup" />
          <v-divider />
        </template>

        <v-row class="ma-0 pa-3" style="gap: 2px">
          <v-spacer />
          <app-button
            v-if="loggedInUser.isGroupFour"
            data-testid="edit"
            color="accent"
            variant="outlined"
            class="text-none rounded-pill"
            text="Edit"
            :prepend-icon="mdiPencil"
            @click="editUser"
          />
          <app-button
            data-testid="send-password-reset-email"
            variant="outlined"
            class="text-none rounded-pill"
            color="warning"
            text="Send Password Reset Email"
            :prepend-icon="mdiLockReset"
            @click="sendResetPassword"
          />
          <app-button
            v-if="user.otpEnrolled"
            data-testid="reset-mfa"
            variant="outlined"
            class="text-none rounded-pill"
            color="error"
            text="Reset MFA"
            :prepend-icon="mdiShieldOffOutline"
            @click="resetOtp"
          />
          <app-button
            v-if="user.otpEnrolled"
            data-testid="delete-mfa"
            variant="outlined"
            class="text-none rounded-pill"
            color="error"
            text="Delete MFA"
            :prepend-icon="mdiShieldOffOutline"
            @click="deleteOtp"
          />
          <app-button
            v-if="user.isLocked"
            variant="outlined"
            data-testid="unlock-user"
            class="text-none rounded-pill"
            color="error"
            text="Unlock User"
            :prepend-icon="mdiAccountLockOpen"
            @click="userUnLock"
          />
          <app-button
            v-else
            data-testid="lock-user"
            variant="outlined"
            class="text-none rounded-pill"
            color="error"
            text="Lock User"
            :prepend-icon="mdiAccountLock"
            @click="userLock"
          />
        </v-row>

        <template v-if="tabs.length">
          <v-divider />

          <v-tabs v-model="activeTab" color="primary">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              eager
              class="section"
            >
              <component
                :is="tab.component"
                v-bind="tab.props"
                class="section"
              />
            </v-tabs-window-item>
          </v-tabs-window>
        </template>
      </div>
      <v-alert v-else key="cannot-load" color="error">
        Unable to load data for user {{ props.id }}
      </v-alert>
    </v-fade-transition>
  </v-card>
</template>

<script setup>
import ApplicationVersionsTable from "@/components/application-versions/ApplicationVersionsTable.vue";
import UserSettingsDialog from "@/components/users/UserSettingsDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import {
  PERMISSION,
  PERMISSION_FILTER_ITEMS,
  PERMISSION_TEXT
} from "@/constants/permissions.constants";
import { User } from "@/models/User";
import {
  getRouterLink,
  listToSentence,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { computed, ref, markRaw } from "vue";
import {
  routerLinkItem,
  textItem
} from "@/components/shared/card-items/card-items";
import {
  getUserDetails,
  lockUser,
  resetUserOTP,
  deleteUserOTP,
  sendPasswordReset,
  unlockUser
} from "@/api/users.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import {
  mdiLockReset,
  mdiShieldOffOutline,
  mdiPencil,
  mdiAccountLock,
  mdiAccountLockOpen
} from "@mdi/js";
const emit = defineEmits(["refresh"]);
const props = defineProps({
  id: {
    type: Number,
    required: true
  }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const loggedInUser = useUserStore();

const user = ref(User());
const loading = ref(false);
const activeTab = ref(0);

const tabs = [];

if (loggedInUser.isGroupFour) {
  tabs.push(
    {
      title: "Changes To User",
      component: markRaw(ApplicationVersionsTable),
      props: {
        id: props.id,
        title: "Changes To User",
        changesToId: props.id,
        changesToType: "User"
      }
    },
    {
      title: "Changes By User",
      component: markRaw(ApplicationVersionsTable),
      props: {
        id: props.id,
        title: "Changes By User",
        changesById: props.id,
        changesByType: "User"
      }
    }
  );
}

const general = computed(() => {
  const permissionsData = [];
  Object.values(PERMISSION).forEach(p => {
    if (user.value.permissions[p]) {
      permissionsData.push(PERMISSION_TEXT[p]);
    }
  });
  let permissionText = "None";
  if (permissionsData.length === PERMISSION_FILTER_ITEMS.length) {
    permissionText = "All";
  } else if (permissionsData.length) {
    permissionText = listToSentence(permissionsData);
  }

  return [
    textItem({
      title: "Email",
      text: user.value.email
    }),
    textItem({
      title: "Last Sign in At",
      text: user.value.lastSignInAt
        ? timestampFormatter(user.value.lastSignInAt, null, "date-time")
        : ""
    }),
    textItem({
      title: "Created At",
      text: timestampFormatter(user.value.createdAt, null, "date-time")
    }),
    textItem({
      title: "Permissions",
      text: permissionText
    }),
    textItem({
      title: "Group",
      text: user.value.group?.toString()
    }),
    textItem({
      title: "Highest Group",
      text: user.value.highestGroup?.toString()
    })
  ];
});

const signIn = computed(() => {
  return [
    textItem({
      title: "MFA Enrolled",
      text: boolToText(user.value.otpEnrolled)
    }),
    textItem({
      title: "MFA Required",
      text: boolToText(user.value.otpRequiredForLogin)
    }),
    textItem({
      title: "MFA Required By",
      text: user.value.otpRequiredOn
        ? timestampFormatter(user.value.otpRequiredOn, null, "date-time")
        : ""
    }),
    textItem({
      title: "Sign in Method",
      text: ssoFormatter(user.value.sso)
    }),
    ...user.value.identities.map(i =>
      textItem({
        title: "OAuth Identity",
        text: i
      })
    )
  ];
});

const logins = computed(() => {
  return user.value.logins.map(l => {
    let text = l.name;
    if (
      l.id === user.value.loggedInAs?.id &&
      l.type === user.value.loggedInAs?.type
    ) {
      text += " (Current)";
    }

    return routerLinkItem({
      title: "Login",
      text,
      to: getRouterLink(l.type, l.id)
    });
  });
});

const detailGroups = computed(() => {
  return [general.value, signIn.value, logins.value].filter(i => i.length);
});

function ssoFormatter(sso) {
  if (sso?.oauthProvider && sso?.authMethod) {
    return `${sso.authMethod}, via ${sso.oauthProvider}`;
  } else if (sso?.authMethod && sso?.emailDomain) {
    return `${sso.authMethod}, via ${sso.emailDomain}`;
  } else {
    return "Email & Password";
  }
}

function boolToText(bool) {
  return bool ? "Yes" : "No";
}

async function fetchUserData() {
  try {
    loading.value = true;
    user.value = await getUserDetails(props.id);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  } finally {
    loading.value = false;
  }
}

function resetOtp() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Reset ${user.value.email}'s MFA?`,
    subtitle: "This will require them to re-enroll",
    func: async () => {
      await resetUserOTP(user.value.id);
      refresh();
    }
  });
}

function deleteOtp() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Delete ${user.value.email}'s MFA?`,
    subtitle:
      "This will decrease the security of their account. Please confirm your intent. It's recommended that you reset their MFA instead.",
    func: async () => {
      await deleteUserOTP(user.value.id);
      refresh();
    }
  });
}

function userLock() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Lock ${user.value.email}'s Account?`,
    subtitle: "This will sign them out and prevent signing in",
    func: async () => {
      await lockUser(user.value.id);
      refresh();
    }
  });
}

function userUnLock() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    showErrorMessage: true,
    title: `Unock ${user.value.email}'s Account?`,
    subtitle: "This will permit signing in",
    func: async () => {
      await unlockUser(user.value.id);
      refresh();
    }
  });
}

function sendResetPassword() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Reset User Password",
    subtitle: "This will send a reset password email to the user.",
    func: () => sendPasswordReset(user.value.id)
  });
}

async function editUser() {
  const result = await dialog.showDialog({
    component: markRaw(UserSettingsDialog),
    modelValue: user.value
  });

  if (result?.refresh) refresh();
}

function refresh() {
  emit("refresh");
  fetchUserData();
}

fetchUserData();
</script>
