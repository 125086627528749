<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="employee-provisioning-tasks-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Employee Provisioning Tasks</h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="employee-provisioning-tasks-table-refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiPlus"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="employee-provisioning-tasks-table-create"
              @click="createProvisioningTask"
            />
          </template>
          <span>Create Provisioning Task</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #[`item.stage`]="{ item }">
      {{ STAGE_TEXT[item.additional.stage] }}
    </template>

    <template #[`item.departments`]="{ item }">
      <template v-if="item.additional.includedDepartments.length">
        Only {{ item.additional.includedDepartments.join(", ") }}
      </template>
      <template v-else-if="item.additional.excludedDepartments.length">
        All but {{ item.additional.excludedDepartments.join(", ") }}
      </template>
      <template v-else> All </template>
    </template>

    <template #[`item.titles`]="{ item }">
      <template v-if="item.additional.includedTitles.length">
        Only {{ item.additional.includedTitles.join(", ") }}
      </template>
      <template v-else-if="item.additional.excludedTitles.length">
        All but {{ item.additional.excludedTitles.join(", ") }}
      </template>
      <template v-else> All </template>
    </template>

    <template #[`item.createdAt`]="{ item }">
      {{ timestampFormatter(item.createdAt, "none", "date-time") }}
    </template>

    <template #[`item.actions`]="{ item }">
      <app-button
        variant="text"
        color="accent"
        density="comfortable"
        data-testid="edit"
        :icon="mdiPencil"
        @click="editProvisioningTask(item.additional)"
      />
      <app-button
        variant="text"
        color="error"
        density="comfortable"
        data-testid="delete"
        :icon="mdiDelete"
        @click="destroyProvisioningTask(item.additional)"
      />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>
  </v-data-table-server>
</template>

<script setup>
import EmployeeProvisioningTaskCreate from "@/components/employees/EmployeeProvisioningTaskCreate.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { useTable } from "@/composables/table.composable";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiRefresh, mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";
import { markRaw } from "vue";
import { DEPARTMENTS, TITLES } from "@/models/Employee";
import { getProvisioningTasks } from "@/api/employee-provisioning-tasks.service";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { deleteProvisioningTask } from "@/api/employee-provisioning-tasks.service";
import { STAGES, STAGE_TEXT } from "@/models/EmployeeProvisioningTask";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Text",
      value: "text",
      map: "text",
      ...TableHeader.IS_STRING_FILTER,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Stage",
      value: "stage",
      map: "stage",
      sortFilterMap: "stage",
      selectableOptions: STAGES,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Departments",
      value: "departments",
      map: "departments"
    }),

    new TableHeader({
      text: "Titles",
      value: "titles",
      map: "titles"
    }),

    new TableHeader({
      text: "Created At",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "created_at",
      ...TableHeader.IS_SORTABLE
    }),

    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    }),

    new TableHeader({
      text: "Simulate For Department",
      value: "forDepartment",
      map: "forDepartment",
      sortFilterMap: "for_department",
      showIf: filter =>
        !filter.excludedDepartment &&
        !filter.includedDepartment &&
        !filter.excludedTitle &&
        !filter.includedTitle,
      selectableOptions: DEPARTMENTS,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Simulate For Title",
      value: "forTitle",
      map: "forTitle",
      sortFilterMap: "for_title",
      showIf: filter =>
        !filter.excludedDepartment &&
        !filter.includedDepartment &&
        !filter.excludedTitle &&
        !filter.includedTitle,
      selectableOptions: TITLES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Has Included Department",
      value: "includedDepartment",
      map: "includedDepartment",
      sortFilterMap: "included_department",
      showIf: filter =>
        !filter.excludedDepartment && !filter.forTitle && !filter.forDepartment,
      selectableOptions: DEPARTMENTS,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Has Excluded Department",
      value: "excludedDepartment",
      map: "excludedDepartment",
      sortFilterMap: "excluded_department",
      selectableOptions: DEPARTMENTS,
      showIf: filter =>
        !filter.includedDepartment && !filter.forTitle && !filter.forDepartment,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Has Included Title",
      value: "includedTitle",
      map: "includedTitle",
      sortFilterMap: "included_title",
      showIf: filter =>
        !filter.excludedTitle && !filter.forTitle && !filter.forDepartment,
      selectableOptions: TITLES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Has Excluded Title",
      value: "excludedTitle",
      map: "excludedTitle",
      sortFilterMap: "excluded_title",
      showIf: filter =>
        !filter.includedTitle && !filter.forTitle && !filter.forDepartment,
      selectableOptions: TITLES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  options: TableOptions({
    sortBy: [{ key: "createdAt", order: "desc" }]
  }),
  shouldIncludeCancelToken: true,
  getData: getProvisioningTasks
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function createProvisioningTask() {
  const result = await dialog.showDialog({
    component: markRaw(EmployeeProvisioningTaskCreate),
    scrollable: true
  });

  if (result?.refresh) getData();
}

async function editProvisioningTask(item) {
  const result = await dialog.showDialog({
    component: markRaw(EmployeeProvisioningTaskCreate),
    scrollable: true,
    modelValue: item
  });

  if (result?.refresh) getData();
}

async function destroyProvisioningTask(item) {
  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    func: () => deleteProvisioningTask(item.id),
    title: "Are you sure that you want to delete this provisioning task?",
    subtitle: "This cannot be undone."
  });
  if (res?.confirm) getData();
}
</script>
