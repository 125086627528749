import {
  mdiEggEaster,
  mdiFirework,
  mdiHalloween,
  mdiHeartBox,
  mdiStarShooting,
  mdiStringLights,
  mdiTurkey
} from "@mdi/js";

const HOLIDAYS = year => {
  const easter = Easter(year);
  const thanksgiving = Thanksgiving(year);
  return [
    {
      title: "Valentines",
      month: 1,
      day: 14,
      quoteAndApplyIcon: mdiHeartBox,
      primaryColor: "#e6a6be",
      accentColor: "#78D5D7",
      darkPrimaryColor: "#efdbda",
      darkAccentColor: "#78D5D7"
    },
    {
      title: "Halloween",
      month: 9,
      day: 31,
      quoteAndApplyIcon: mdiHalloween,
      primaryColor: "#FF6700",
      accentColor: "#531CB3",
      darkPrimaryColor: "#FF6700",
      darkAccentColor: "#531CB3"
    },
    {
      title: "Christmas",
      month: 11,
      day: 25,
      quoteAndApplyIcon: mdiStringLights,
      primaryColor: "#0a8e5a",
      accentColor: "#F05D5E",
      darkPrimaryColor: "#0a8e5a",
      darkAccentColor: "#F05D5E"
    },
    {
      title: "New Years",
      month: 0,
      day: 1,
      quoteAndApplyIcon: mdiStarShooting,
      primaryColor: "#FFD15C",
      accentColor: "#b5838d",
      darkPrimaryColor: "#FFD15C",
      darkAccentColor: "#b5838d"
    },
    {
      title: "Independence Day",
      month: 6,
      day: 4,
      quoteAndApplyIcon: mdiFirework,
      primaryColor: "#a53531",
      accentColor: "#4381C1",
      darkPrimaryColor: "#a53531",
      darkAccentColor: "#4381C1"
    },
    {
      title: "Easter",
      month: easter.month,
      day: easter.day,
      quoteAndApplyIcon: mdiEggEaster,
      primaryColor: "#FFD275",
      accentColor: "#DCD6F7",
      darkPrimaryColor: "#FFD275",
      darkAccentColor: "#DCD6F7"
    },
    {
      title: "Thanksgiving",
      month: thanksgiving.month,
      day: thanksgiving.day,
      quoteAndApplyIcon: mdiTurkey,
      primaryColor: "#FE621D",
      accentColor: "#60463B",
      darkPrimaryColor: "#FE621D",
      darkAccentColor: "#60463B"
    }
  ];
};

export default function isHoliday() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  const holidayDict = HOLIDAYS(today.getFullYear());
  return holidayDict.find(data => {
    return data.month === currentMonth && data.day === currentDay;
  });
}

function Easter(Y) {
  const C = Math.floor(Y / 100);
  const N = Y - 19 * Math.floor(Y / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  I =
    I -
    Math.floor(I / 28) *
      (1 -
        Math.floor(I / 28) *
          Math.floor(29 / (I + 1)) *
          Math.floor((21 - N) / 11));
  let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);

  // minus one for JS date
  return { month: M - 1, day: D };
}

function Thanksgiving(year) {
  const first = new Date(year, 10, 1);
  const day_of_week = first.getDay();
  const day = 22 + ((11 - day_of_week) % 7);

  return { month: 10, day };
}
