<template>
  <v-card>
    <v-card-title>Create {{ title }} </v-card-title>
    <v-card-text id="training-container" class="py-3">
      <text-field
        v-model="training.providerName"
        data-testid="training-provider-name"
        data-lpignore="true"
        :prepend-inner-icon="mdiDomain"
        label="Provider Name"
        :success="providerNameValidation.success"
        :error-messages="providerNameValidation.errorMessages"
      />
      <text-field
        v-model="training.courseName"
        data-testid="training-course-name"
        data-lpignore="true"
        :prepend-inner-icon="mdiClipboardOutline"
        label="Full Course Name"
        :success="courseNameValidation.success"
        :error-messages="courseNameValidation.errorMessages"
      />
      <date-input
        v-model="training.completedAt"
        data-testid="training-completed-at"
        :prepend-inner-icon="mdiCalendar"
        label="Completed At"
        :success="completedAtValidation.success"
        :error-messages="completedAtValidation.errorMessages"
      />
      <file-drag-and-drop
        v-model="training.file"
        data-testid="training-file"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none mr-1"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="training-create"
        :loading="creatingTraining"
        @click="create"
      >
        Create {{ title }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { Training, TRAININGS } from "@/models/AgentSettings";
import { createEducation } from "@/api/educations.service";
import {
  differenceInMonths,
  isBefore,
  isSameDay,
  isValid,
  parse
} from "@/util/date-util";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { nextTick, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { mdiDomain, mdiClipboardOutline, mdiCalendar } from "@mdi/js";

const props = defineProps({
  advisorId: { type: [Number, String], default: null },
  expiresInMonths: { type: [Number], default: null },
  title: { type: String, required: true },
  category: {
    type: String,
    required: true,
    validator: v => [TRAININGS.AML, TRAININGS.REG_187].includes(v)
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const training = ref(
  Training({
    ownerId: props.advisorId,
    ownerType: "Agent",
    category: props.category
  })
);
const creatingTraining = ref(false);

const v$ = useVuelidate(
  {
    training: {
      providerName: { required: Boolean },
      courseName: { required: Boolean },
      completedAt: {
        required: Boolean,
        withinLastTwoYears: value => {
          if (!props.expiresInMonths) return true;
          return (
            differenceInMonths(
              new Date(),
              parse(value, "yyyy-MM-dd")
            ) < props.expiresInMonths
          );
        },
        beforeToday: val => {
          const date = parse(val, "yyyy-MM-dd");
          if (!isValid(date)) return false;
          if (isSameDay(new Date(), date)) return true;
          return isBefore(date, new Date());
        }
      },
      file: {
        required: Boolean,
        validSize: val => {
          return val?.size > 0;
        }
      }
    }
  },
  { training },
  { $scope: null, $autoDirty: true }
);

const providerNameValidation = computedValidation(
  v$.value.training.providerName,
  { required: "Required" }
);
const courseNameValidation = computedValidation(v$.value.training.courseName, {
  required: "Required"
});
const completedAtValidation = computedValidation(
  v$.value.training.completedAt,
  {
    required: "Required",
    withinLastTwoYears: "Must be within the last two years",
    beforeToday: "Must be today or before"
  }
);
const fileValidation = computedValidation(v$.value.training.file, {
  required: "Required",
  validSize: "Please confirm this file has data or try re-uploading the file"
});

function goToInvalid() {
  const els = document.getElementsByClassName("v-input error-field");
  if (!els?.length) return;

  els[0].scrollIntoView();
}

async function create() {
  if (creatingTraining.value) return;
  const isValid = await v$.value.$validate();
  if (!isValid) {
    nextTick(() => goToInvalid());
    return;
  }

  creatingTraining.value = true;
  try {
    const createdTraining = await createEducation(training.value);
    dialog.closeDialog({ training: createdTraining });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingTraining.value = false;
  }
}
</script>
