<template>
  <v-row dense class="ma-0">
    <v-slide-y-transition mode="out-in">
      <v-col v-if="!fullscreen || !lgAndUp" cols="12">
        <quote-and-apply-hero />
      </v-col>
    </v-slide-y-transition>
    <v-col cols="12">
      <v-tabs
        v-model="activeTab"
        background-color="transparent"
        class="transparent-tab-item"
        color="primary"
        @update:model-value="handleTabChange"
      >
        <v-tab v-for="table in tables" :key="table.view" class="text-none">
          <v-icon class="mr-1" :icon="table.icon" /> {{ table.title }}
        </v-tab>
        <v-spacer />
        <app-button
          v-if="lgAndUp"
          variant="text"
          density="comfortable"
          class="default-btn"
          :icon="fullscreen ? mdiFullscreenExit : mdiFullscreen"
          @click="fullscreen = !fullscreen"
        />
      </v-tabs>
      <v-tabs-window v-model="activeTab" touchless>
        <v-tabs-window-item v-for="table in tables" :key="table.view">
          <component :is="table.component" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-col>
  </v-row>
</template>
<script setup>
import QuoteAndApplyHero from "@/components/quote-and-apply/QuoteAndApplyHero.vue";

import ElectronicApplicationsTable from "@/components/electronic-applications/ElectronicApplicationsTable.vue";
import ElectronicApplicationPipeline from "@/components/electronic-applications/ElectronicApplicationPipeline.vue";
import QuoteAndApplyWebsitesView from "@/components/quote-and-apply/QuoteAndApplyWebsitesView.vue";

import { useHead } from "@unhead/vue";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { markRaw, onBeforeUnmount, ref, watch, toRef } from "vue";
import { useRouter } from "vue-router";
import {
  mdiFullscreenExit,
  mdiFullscreen,
  mdiTable,
  mdiViewColumnOutline,
  mdiWeb
} from "@mdi/js";
import { useDisplay } from "vuetify";

const props = defineProps({
  view: {
    type: String,
    required: false,
    default: null
  }
});
useHead({ title: "Quote & Apply" });

const router = useRouter();
const { lgAndUp } = useDisplay();
const tables = [
  {
    component: markRaw(ElectronicApplicationsTable),
    view: "list",
    title: "List",
    icon: mdiTable
  },
  {
    component: markRaw(ElectronicApplicationPipeline),
    view: "pipeline",
    title: "Pipeline",
    icon: mdiViewColumnOutline
  },
  {
    component: markRaw(QuoteAndApplyWebsitesView),
    view: "websites",
    title: "Websites",
    icon: mdiWeb
  }
];

const currentQueryView = toRef(props, "view");

const activeTab = ref(0);
const { fullscreen } = storeToRefs(useInstanceStore());

onBeforeUnmount(() => (fullscreen.value = false));

function initTab() {
  if (!currentQueryView.value) return;
  const index = tables.findIndex(v => v.view === currentQueryView.value);
  if (index === -1) return;
  activeTab.value = index;
}

function handleTabChange(index) {
  if (currentQueryView.value === tables[index].view) return;
  router.replace({ query: { view: tables[index].view } });
}

watch(
  currentQueryView,
  () => {
    if (!currentQueryView.value) return;
    const index = tables.findIndex(v => v.view === currentQueryView.value);
    if (index === -1 || activeTab.value === index) return;
    activeTab.value = index;
  },
  { immediate: true }
);

initTab();
</script>
