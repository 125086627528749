<template>
  <v-card flat color="section">
    <v-card-title class="text-break pb-0" :data-testid="`${name}-eo-title`">
      {{ name }}'s E&O
    </v-card-title>
    <v-card-text class="pb-0" :data-testid="`${name}-eo-status`">
      {{ eoStatusText }}
    </v-card-text>
    <v-card-text>
      <span v-if="canDownloadDocument">
        <app-button
          class="text-none ma-1"
          color="primary"
          target="_blank"
          variant="outlined"
          :data-testid="`${name}-download-eo`"
          v-bind="downloadDocumentProps"
        >
          <v-icon class="mr-1" :icon="mdiDownload" /> Download E&O
        </app-button>

        <app-button
          v-if="user.isGroupTwoPlus"
          class="text-none ma-1"
          color="error"
          variant="outlined"
          :data-testid="`${name}-delete-eo`"
          @click="deleteEo"
        >
          <v-icon class="mr-1" :icon="mdiDelete" /> Delete E&O
        </app-button>
      </span>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { downloadFileAsLink, timestampFormatter } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw, toRef } from "vue";
import { mdiDownload, mdiDelete } from "@mdi/js";
import { useDisplay } from "vuetify";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const props = defineProps({
  readonly: Boolean,
  name: { type: String, default: null },
  eo: { type: Object, default: () => {} },
  destroy: { type: Function, default: () => {} }
});

const eo = toRef(props, "eo");
const name = toRef(props, "name");

const user = useUserStore();
const dialog = useDialogStore();
const { mdAndDown } = useDisplay();
const canDownloadDocument = computed(() => {
  return Boolean(eo.value?.eoDocument?.id) && !props.readonly;
});

const eoStatusText = computed(() => {
  let text = `${name.value} does not have an E&O on file with us. Please upload ${name.value}'s E&O.`;
  const eoIsCurrent = Boolean(eo.value?.eoDocument?.id && !eo.value.expired);
  const eoIsExpired = Boolean(eo.value?.eoDocument?.id && eo.value.expired);

  if (eoIsCurrent || eoIsExpired) {
    const expiresOn = timestampFormatter(
      eo.value.expirationDate,
      "sole-day",
      "basic"
    );

    if (eoIsCurrent) {
      text = `${name.value}'s E&O is current but expires on ${expiresOn}.`;
    } else if (eoIsExpired) {
      text = `${name.value}'s E&O expired on ${expiresOn}. Please upload ${name.value}'s current E&O.`;
    }
  }

  return text;
});

const downloadDocumentProps = computed(() => {
  return downloadFileAsLink(
    getDocumentUrl(eo.value.eoDocument.uid),
    mdAndDown.value
  );
});

function deleteEo() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Are you sure you want to delete ${name.value}'s E&O?`,
    subtitle: "This cannot be undone",
    func: props.destroy
  });
}
</script>
