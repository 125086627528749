<template>
  <v-app-bar
    v-if="lgAndUp"
    v-show="!fullscreen"
    key="lg-menu-items"
    class="d-print-none"
    flat
    absolute
    color="background"
  >
    <app-button
      v-if="hasQuoteAndApplyLink"
      :id="quoteAndApplyTourId"
      variant="flat"
      :class="{ 'header-btn-color-light': !theme.current.value.dark }"
      :href="quoteAndApplyLink"
      target="_blank"
      color="primary"
      class="text-none launch-button mr-1 ml-3 rounded-pill"
    >
      <div class="animated-launch-button"></div>
      <v-icon :icon="quoteAndApplyIcon" class="mr-1" />
      <template v-if="hasEApps"> Launch Quote & Apply </template>
      <template v-else> Start Your First Quote & Apply Application </template>
    </app-button>
    <app-button
      v-else-if="hasNpn"
      variant="flat"
      :class="{ 'header-btn-color-light': !theme.current.value.dark }"
      color="primary"
      class="text-none launch-button mr-1"
      @click="openCreateQuoteAndApplySiteDialog"
    >
      <div class="animated-launch-button"></div>
      <v-icon :icon="quoteAndApplyIcon" class="mr-1" />
      Create Quote & Apply Website
    </app-button>
    <template v-if="multiManagers || schedulingLink">
      <app-button
        v-if="!multiManagers && schedulingLink"
        id="schedule-a-meeting"
        color="accent"
        class="text-none rounded-pill"
        variant="flat"
        :href="schedulingLink.url"
        target="_blank"
      >
        <v-icon class="mr-1" :icon="mdiCalendar" />
        {{ schedulingLink.text }}
      </app-button>
      <template v-else>
        <v-menu offset-y>
          <template #activator="{ props }">
            <app-button
              v-bind="props"
              target="_blank"
              class="text-none ml-1 rounded-pill"
              color="accent"
              variant="flat"
            >
              <v-icon class="mr-1" :icon="mdiCalendar" /> Schedule a meeting
              <v-icon :icon="mdiChevronDown" />
            </app-button>
          </template>
          <v-list>
            <v-list-item
              v-for="link in schedulingLinks"
              :key="link.text"
              link
              :href="link.url"
              target="_blank"
              :title="link.text"
            />
          </v-list>
        </v-menu>
      </template>
    </template>
    <v-spacer />
    <v-toolbar-items>
      <app-header-sitewide-search autofocus />
      <model-create-menu />
      <notifications-indicator />
      <user-menu />
    </v-toolbar-items>
  </v-app-bar>
  <v-app-bar
    v-else-if="md"
    v-show="!fullscreen"
    key="md-menu-items"
    class="d-print-none"
    app
    flat
    color="sidebar"
    elevation="2"
  >
    <v-toolbar-items>
      <v-app-bar-nav-icon @click="mobileSidebarVisible = true" />
    </v-toolbar-items>
    <app-logo />
    <v-spacer />
    <v-toolbar-items>
      <app-header-sitewide-search />
      <v-tooltip v-if="hasQuoteAndApplyLink">
        <template #activator="{ props: tooltipProps }">
          <app-button
            v-bind="tooltipProps"
            :id="quoteAndApplyTourId"
            variant="text"
            class="text-none px-1"
            :href="quoteAndApplyLink"
            target="_blank"
            color="primary"
            :icon="quoteAndApplyIcon"
          />
        </template>
        <span>Launch Quote & Apply</span>
      </v-tooltip>
      <app-button
        v-else-if="hasNpn"
        variant="text"
        color="primary"
        class="text-none px-1"
        :icon="quoteAndApplyIcon"
        @click="openCreateQuoteAndApplySiteDialog"
      />
      <model-create-menu />
    </v-toolbar-items>
  </v-app-bar>
  <v-app-bar
    v-else
    v-show="!fullscreen"
    key="small-menu-items"
    class="d-print-none"
    app
    flat
    location="bottom"
    color="sidebar"
    elevation="2"
  >
    <app-button
      variant="text"
      class="rounded"
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      :to="{ name: 'Home' }"
      exact
      :icon="mdiHome"
    />
    <app-button
      v-if="hasQuoteAndApplyLink"
      :id="quoteAndApplyTourId"
      variant="text"
      class="rounded"
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      exact
      target="_blank"
      color="primary"
      :icon="quoteAndApplyIcon"
      :href="quoteAndApplyLink"
    />
    <app-button
      variant="text"
      class="rounded"
      data-testid="sitewide-search-button"
      :icon="mdiMagnify"
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      @click="showFullPageSearch"
    />
    <model-create-menu
      :width="hasQuoteAndApplyLink ? '20%' : '25%'"
      :color="theme.current.value.dark ? 'white' : 'black'"
      text
    />
    <app-button
      variant="text"
      class="rounded"
      :icon="mdiMenu"
      :style="{ width: hasQuoteAndApplyLink ? '20%' : '25%' }"
      @click="mobileSidebarVisible = true"
    />
    <v-dialog v-model="showSearch" fullscreen persistent>
      <v-card class="h-100">
        <div>
          <app-header-sitewide-search
            in-dialog
            autofocus
            label=""
            @close="showSearch = false"
          >
            <template #prepend-inner>
              <app-button
                variant="text"
                density="compact"
                color="primary"
                :icon="mdiArrowLeft"
                @click="showSearch = false"
              />
            </template>
          </app-header-sitewide-search>
        </div>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script setup>
import UserMenu from "@/components/UserMenu.vue";
import ModelCreateMenu from "@/components/ModelCreateMenu.vue";

import AppLogo from "@/components/AppLogo.vue";

import AppHeaderSitewideSearch from "@/components/AppHeaderSitewideSearch.vue";
import NotificationsIndicator from "@/components/notifications/NotificationsIndicator.vue";

import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useInstanceStore } from "@/stores/instance";
import {
  mdiCalendar,
  mdiChevronDown,
  mdiHome,
  mdiMagnify,
  mdiMenu,
  mdiArrowLeft
} from "@mdi/js";
import { computed, markRaw, ref } from "vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import { useDisplay, useTheme } from "vuetify";
import { useQuoteAndApplyIcon } from "@/composables/quote-and-apply-icon.composable";

const instance = useInstanceStore();
const { md, lgAndUp } = useDisplay();
const theme = useTheme();
const quoteAndApplyIcon = useQuoteAndApplyIcon();

const { mobileSidebarVisible, fullscreen } = storeToRefs(instance);
const { loginable } = storeToRefs(useUserStore());
const dialog = useDialogStore();

const showSearch = ref(false);

const quoteAndApplyTourId = "launch-quote-and-apply";

const hasQuoteAndApplyLink = computed(() =>
  Boolean(loginable.value.approved_domain_url)
);

const quoteAndApplyLink = computed(() => loginable.value.approved_domain_url);
const hasEApps = computed(() => loginable.value.has_eapps);
const hasNpn = computed(() => loginable.value.npn);
const schedulingLinks = computed(
  () => loginable.value?.marketing_manager?.scheduling_links || []
);

const schedulingLink = computed(() => {
  if (!schedulingLinks.value.length) return null;
  return schedulingLinks.value[0];
});

const multiManagers = computed(() => {
  if (!schedulingLinks.value) return false;
  return schedulingLinks.value?.length > 1;
});

async function showFullPageSearch() {
  showSearch.value = true;
  const element = await waitForElement("#menu-search");
  if (element) element.focus();
}

function waitForElement(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

function openCreateQuoteAndApplySiteDialog() {
  dialog.showDialog({
    component: markRaw(CreateQuoteAndApplySite)
  });
}
</script>

<style lang="scss">
.launch-button {
  svg {
    transform: rotate(0deg);
    transition: all 0.5s;
  }

  &:hover {
    svg {
      transform: rotate(-45deg);
    }

    .animated-launch-button {
      position: absolute;
      border-radius: 50px;

      height: 150%;
      width: 110%;
      border: solid #1487e2 5px;
      animation: 3s explode infinite;
    }
  }

  &:active {
    svg {
      transform: rotate(-45deg) translate(32px, -32px);
    }
  }

  &:visited {
    transform: rotate(0deg);
  }
}

@keyframes explode {
  0% {
    height: 150%;
    width: 110%;
    border: solid #1487e2 5px;
  }

  20% {
    height: 210%;
    width: 125%;
    border: solid transparent 1px;
  }

  100% {
    height: 210%;
    width: 125%;
    border: solid transparent 1px;
  }
}
</style>
