import { format } from "@/util/date-util";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user.js";
import {
  CastTodoToNotification,
  CastSupportTicketTableItemToNotification
} from "@/models/Notification.js";
import { getTodos } from "@/api/todos.service";
import { getHttpClient } from "@/http-client";
import { getSupportTickets } from "@/api/support-tickets.service";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    loadingTodos: false,
    todos: [],
    todoCount: 0,
    todoCancelToken: null,
    loadingSupportTickets: false,
    supportTickets: [],
    supportTicketCount: 0,
    supportTicketCancelToken: null
  }),
  getters: {
    loading() {
      return this.loadingTodos || this.loadingSupportTickets;
    },
    total() {
      return this.todoCount + this.supportTicketCount;
    }
  },
  actions: {
    async getDueTodos() {
      try {
        this.loadingTodos = true;

        const user = useUserStore();

        const requestParams = new URLSearchParams();
        const todaysDate = new Date();
        const finish = format(todaysDate, "yyyy-MM-dd");

        requestParams.append("sorting[follow_up_at]", "desc");
        requestParams.append("date[follow_up_at][finish]", finish);
        requestParams.append("count", 5);
        requestParams.append("page", 1);
        requestParams.append("complete", "false");
        requestParams.append("assignee_id", user.loginable.id);
        requestParams.append("assignee_type", user.loginable.type);
        requestParams.append("include_subject_title", "true");

        if (this.todoCancelToken !== null) {
          this.todoCancelToken.cancel("Operation canceled due to new request.");
        }
        this.todoCancelToken = getHttpClient().CancelToken.source();

        const { meta, items: todos } = await getTodos(
          requestParams,
          this.todoCancelToken
        );
        this.todoCount = meta.total;
        this.todos.splice(0, this.todos.length);
        todos.forEach(i => this.todos.push(CastTodoToNotification(i)));
      } catch (e) {
        //fail silently
      } finally {
        this.loadingTodos = false;
      }
    },
    async getIncompleteSupportTickets() {
      try {
        this.loadingSupportTickets = true;

        const user = useUserStore();

        const requestParams = new URLSearchParams();

        requestParams.append("sorting[created_at]", "asc");
        requestParams.append("count", 5);
        requestParams.append("page", 1);
        requestParams.append("incomplete", "true");

        if (user.loginable.is_employee) {
          requestParams.append("assignee_id", user.loginable.id);
          requestParams.append("assignee_type", user.loginable.type);
        } else {
          requestParams.append("ownable_id", user.loginable.id);
          requestParams.append("ownable_type", user.loginable.type);
        }

        if (this.supportTicketCancelToken !== null) {
          this.supportTicketCancelToken.cancel(
            "Operation canceled due to new request."
          );
        }
        this.supportTicketCancelToken = getHttpClient().CancelToken.source();

        const { meta, items: supportTickets } = await getSupportTickets(
          requestParams,
          this.supportTicketCancelToken
        );
        this.supportTicketCount = meta.total;
        this.supportTickets.splice(0, this.supportTickets.length);
        supportTickets.forEach(i =>
          this.supportTickets.push(CastSupportTicketTableItemToNotification(i))
        );
      } catch (e) {
        //fail silently
      } finally {
        this.loadingSupportTickets = false;
      }
    }
  }
});
