<template>
  <side-by-side-tab-controller
    v-model="activeTab"
    expand-full
    :show-side-by-side="mdAndUp"
    :expanded="expanded"
  >
    <template #left>
      <task-list
        v-model="activeOfferKey"
        type="Offers"
        hide-dates
        simple
        :expanded="expanded"
        :tasks-grouped-by-category="groupedTasks"
        @toggle-expand="expanded = !expanded"
      >
        <!-- @create="createRequirement" -->
        <template #title>
          <span style="word-wrap: normal" data-testid="offer-title">
            <v-icon
              class="mr-1 ml-2 default-icon"
              :icon="mdiArchive"
              size="24"
            />
            {{ offersWithResponses.length }} of
            {{ pendingOffers.length + offersWithResponses.length }} Offers
            Received
          </span>
        </template>
      </task-list>
    </template>
    <template #right>
      <v-scroll-y-transition mode="out-in">
        <v-skeleton-loader v-if="loading" key="loading" type="card" />
        <impaired-risk-quote-view-chat
          v-else-if="activeOfferKey"
          :key="activeOfferKey"
        >
          <template v-if="smAndDown" #back-button>
            <app-button
              variant="outlined"
              color="accent"
              class="text-none mr-3"
              @click="activeTab = 0"
            >
              <v-icon :icon="mdiArrowLeft" /> View Offers
            </app-button>
          </template>
        </impaired-risk-quote-view-chat>
        <v-card v-else key="no-active-task" class="h-100" flat>
          <v-col align="center" justify="center" class="h-100">
            <v-row align="center" justify="center" class="h-100">
              <div>
                <v-card-title>
                  <v-spacer /> No Offer Selected <v-spacer />
                </v-card-title>
                <v-card-subtitle>
                  Use the menu on the left to select an offer.
                </v-card-subtitle>
              </div>
            </v-row>
          </v-col>
        </v-card>
      </v-scroll-y-transition>
    </template>
  </side-by-side-tab-controller>
</template>

<script setup>
import TaskList from "@/components/shared/tasks/TaskList.vue";
import SideBySideTabController from "@/components/shared/SideBySideTabController.vue";
import ImpairedRiskQuoteViewChat from "@/components/impaired-risk-quote/ImpairedRiskQuoteViewChat.vue";

import { mdiArchive, mdiClockTimeFour, mdiOffer, mdiArrowLeft } from "@mdi/js";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { useImpairedRiskQuoteOffer } from "@/stores/impaired-risk-quote-offer";
import { useRouter } from "vue-router";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { useDisplay } from "vuetify";

const { mdAndUp, smAndDown, mdAndDown } = useDisplay();
const router = useRouter();
const snackbar = useSnackbarStore();

const impairedRiskQuote = useImpairedRiskQuoteView();
const activeOfferStore = useImpairedRiskQuoteOffer();

const {
  offersWithResponses,
  pendingOffers,
  offers,
  id: quoteId
} = storeToRefs(impairedRiskQuote);
const { activeOfferKey } = storeToRefs(activeOfferStore);

const loading = ref(false);
const expanded = ref(!activeOfferKey.value);

const activeTab = ref(activeOfferKey.value ? 1 : 0);

const groupedTasks = computed(() => {
  let defaultToOffers = offersWithResponses.value.length > 0;
  if (offersWithResponses.value.some(o => o.id === activeOfferKey.value)) {
    defaultToOffers = true;
  } else if (pendingOffers.value.some(o => o.id === activeOfferKey.value)) {
    defaultToOffers = false;
  }

  return [
    {
      icon: mdiOffer,
      text: "Offers",
      tasks: offersWithResponses.value,
      defaultOpen: defaultToOffers
    },
    {
      icon: mdiClockTimeFour,
      text: "Pending Offers",
      tasks: pendingOffers.value,
      defaultOpen: !defaultToOffers
    }
  ];
});

function syncQuery(key) {
  if (!router) return;
  if (router.currentRoute.value.query.offer !== key) {
    const query = { page: "offers" };
    if (key) query.offer = key;
    router.replace({ query });
  }
}

watch(activeOfferKey, (newV, oldV) => {
  if (oldV === newV) return;
  expanded.value = false;
  if (newV) view(newV);
  else syncQuery(null);
});

async function view(key) {
  syncQuery(key);
  if (mdAndDown.value) activeTab.value = 1;

  const req = offers.value.find(v => +v.id === +key);
  if (!req) return;
  try {
    loading.value = true;
    await activeOfferStore.initialize(quoteId.value, key);
  } catch (e) {
    activeOfferStore.activeOfferKey = null;
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

if (activeOfferKey.value) view(activeOfferKey.value);
</script>
