import { Document, setDocumentFromRequest } from "./Document";

export function CarrierStatusFile(model = {}) {
  return {
    id: model?.id,
    statusDate: model?.statusDate,
    createdAt: model?.createdAt,
    document: model?.document ? Document(model.document) : null,
    errorsDocument: model?.errorsDocument
      ? Document(model.errorsDocument)
      : null,
    carrier: model?.carrier ? CarrierStatusFileCarrier(model.carrier) : null
  };
}

export function setCarrierStatusFileFromRequest(raw = {}) {
  const model = CarrierStatusFile();
  model.id = raw.id;
  model.statusDate = raw.status_date;
  model.createdAt = raw.created_at;

  if (raw.document) {
    model.document = setDocumentFromRequest(raw.document);
  }
  if (raw.errors_document) {
    model.errorsDocument = setDocumentFromRequest(raw.errors_document);
  }

  model.carrier = setCarrierStatusFileCarrierFromRequest(raw.carrier);

  return model;
}

export function NewCarrierStatusFile(model = {}) {
  return {
    carrier: model?.carrier ? CarrierStatusFileCarrier(model.carrier) : null,
    statusDate: model?.statusDate,
    file: model?.file
  };
}

export function NewCarrierStatusFileToCreateRequest(
  model = NewCarrierStatusFile()
) {
  return {
    status_date: model.statusDate,
    carrier_id: model.carrier.id,
    documents_attributes: [model.file]
  };
}

function CarrierStatusFileCarrier(model = {}) {
  return {
    id: model?.id,
    name: model?.name
  };
}

function setCarrierStatusFileCarrierFromRequest(raw = {}) {
  const model = CarrierStatusFileCarrier();
  model.id = raw.id;
  model.name = raw.name;
  return model;
}
