<template>
  <v-card v-cloak>
    <v-card-title class="px-6">
      {{ props.title || "Upload Document" }}
    </v-card-title>
    <v-card-subtitle v-if="props.subtitle">
      {{ props.subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <file-drag-and-drop
        v-model="file"
        :accept="props.accept"
        :multiple="!props.single"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions class="mb-3">
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none mr-4"
        color="primary"
        data-testid="upload-file-confirm"
        :loading="uploadingFile"
        @click="uploadFile"
      >
        Upload
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
// This component is deprecated in favor of "DocumentDialog" which provides category options
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  title: { type: String, default: null },
  subtitle: { type: String, default: null },
  accept: { type: String, default: null },
  single: Boolean,
  uploadFunction: { type: Function, required: false, default: null }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const file = ref(null);
const uploadingFile = ref(false);

const v$ = useVuelidate(
  {
    file: {
      required: Boolean,
      validSize: val => {
        if (Array.isArray(val)) return !val.some(file => file?.size === 0);
        return val?.size > 0;
      }
    }
  },
  { file },
  { $scope: null, $autoDirty: true }
);

const fileValidation = computedValidation(v$.value.file, {
  required: "Required",
  validSize: "Please confirm this file has data or try re-uploading the file"
});

async function uploadFile() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    uploadingFile.value = true;
    const response = await props.uploadFunction({
      file: file.value
    });
    dialog.closeDialog(response);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    uploadingFile.value = false;
  }
}
</script>
