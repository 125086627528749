<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-title>Employees</v-expansion-panel-title>
      <v-expansion-panel-text>
        <employees-table />
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-title>Org Chart</v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card>
          <v-img :src="orgChart" />
        </v-card>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel v-if="user.isGroupFour">
      <v-expansion-panel-title>
        Employee Provisioning Tasks
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <employee-provisioning-tasks-table />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import EmployeesTable from "@/components/employees/EmployeesTable.vue";
import EmployeeProvisioningTasksTable from "@/components/employees/EmployeeProvisioningTasksTable.vue";
import orgChart from "@/assets/img/org-chart.png";
import { useUserStore } from "@/stores/user";

const user = useUserStore();
</script>
