<template>
  <v-card>
    <v-card-title>Add Provisioning Task</v-card-title>
    <v-card-text>
      <employee-provisioning-tasks-search
        v-model="model"
        data-testid="tasks-search"
        :stage="stage"
        :success="Boolean(model?.id)"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="add-new-task"
        @click="addTask"
      >
        Add
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import EmployeeProvisioningTasksSearch from "@/components/employees/EmployeeProvisioningTasksSearch.vue";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { createProvisioningTaskAnswer } from "@/api/employees.service";
import { ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";
const props = defineProps({
  employeeId: {
    type: Number,
    required: true
  },
  stage: {
    type: String,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const model = ref(null);
const loading = ref(false);
async function addTask() {
  if (!model.value?.id) return;
  loading.value = true;
  try {
    const task = await createProvisioningTaskAnswer(props.employeeId, {
      employee_provisioning_task_id: model.value.id
    });
    dialog.closeDialog({ task });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
