<template>
  <v-fade-transition mode="out-in">
    <v-card v-if="loaded">
      <v-row class="ma-0 py-3 mr-3">
        <v-card-title>
          <div style="word-break: break-word">
            Commission Total:
            <animated-number
              :model-value="total"
              :format-value="wholeCurrencyFormatter"
              :duration="1000"
            />
          </div>
        </v-card-title>
        <v-spacer />
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiOpenInNew"
              variant="text"
              density="comfortable"
              class="default-icon"
              v-bind="templateProps"
              @click="goToPaymentsTable"
            />
          </template>
          <span> View All Payments </span>
        </v-tooltip>
      </v-row>
      <v-divider v-if="divided" class="mb-3" />
      <div class="highcharts-areaspline">
        <highcharts-graph
          :options="chartOptions"
          style="border-radius: 5px; height: 22.25rem"
        />
      </div>
    </v-card>
    <v-skeleton-loader v-else type="card" />
  </v-fade-transition>
</template>

<script>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { format } from "@/util/date-util";
import { getMyCommissionsReport } from "@/api/reports.service";
import { currencyFormat } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { mapWritableState, mapState } from "pinia";
import { useTableStore } from "@/stores/table";
import { mdiOpenInNew } from "@mdi/js";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    AnimatedNumber,
    HighchartsGraph: defineAsyncComponent(
      () => import("@/components/shared/HighchartsGraph.vue")
    )
  },
  props: {
    divided: Boolean,
    condensed: Boolean
  },
  data() {
    return {
      mdiOpenInNew,
      wholeCurrencyFormatter: v => currencyFormat(v, 0),
      loaded: false,
      total: null,
      chartOptions: {
        tooltip: {
          xDateFormat: "%B"
        },
        chart: {
          type: "areaspline",
          styledMode: true,
          style: {
            fontFamily: "Roboto"
          }
        },
        legend: {
          enabled: !this.condensed
        },
        title: {
          text: null
        },

        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y"
          }
        },
        yAxis: {
          min: 0,
          title: {
            enabled: !this.condensed
          },
          labels: {
            enabled: !this.condensed
          }
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false
            }
          }
        },
        series: [],
        defs: {
          gradient0: {
            tagName: "linearGradient",
            id: "gradient-0",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient1: {
            tagName: "linearGradient",
            id: "gradient-1",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient2: {
            tagName: "linearGradient",
            id: "gradient-2",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          }
        }
      }
    };
  },
  computed: {
    ...mapWritableState(useTableStore, ["paymentsTable"]),
    ...mapState(useUserStore, ["loginable"])
  },
  created() {
    this.getCommissionSummary();
  },
  methods: {
    getCommissionSummary() {
      getMyCommissionsReport(this.loginable.id, this.loginable.type)
        .then(({ agencies, all, signer }) => {
          const { data: agencyData } = agencies;
          const { data: allData } = all;
          const { data: signerData } = signer;
          this.total = 0;
          this.chartOptions.series = [];
          const reducer = (accumulator, currentValue) =>
            accumulator + currentValue[1];
          if (signerData.length) {
            this.chartOptions.series =
              this.chartOptions.series.concat(signerData);
            this.total = signerData[0].data.reduce(reducer, this.total);
          }

          if (allData.length) {
            this.chartOptions.series = this.chartOptions.series.concat(allData);
            this.total = allData[0].data.reduce(reducer, this.total);
          }
          if (agencyData.length) {
            this.chartOptions.series =
              this.chartOptions.series.concat(agencyData);
            this.total = agencyData[0].data.reduce(reducer, this.total);
          }
          this.total = Math.round(this.total);
        })
        .catch(() => {
          // Do nothing.
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    goToPaymentsTable() {
      this.mutatePaymentsTableFilter();
      this.$router.push({ name: "Commissions", query: { page: "payments" } });
    },
    mutatePaymentsTableFilter() {
      const today = new Date();
      const aYearAgo = new Date();
      aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
      this.paymentsTable.filter = {
        paidTo: {
          id: this.loginable.id,
          type: this.loginable.type,
          name: this.loginable.name
        },
        createdDate: {
          type: "range",
          value: {
            start: format(aYearAgo, "yyyy-MM-dd"),
            finish: format(today, "yyyy-MM-dd")
          }
        }
      };
    }
  }
};
</script>
