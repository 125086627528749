import { createVuetify } from "vuetify";
import { mdi, aliases } from "vuetify/iconsets/mdi-svg";

export default cspNonce =>
  createVuetify({
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi
      }
    },
    theme: {
      defaultTheme: "light",
      cspNonce,
      themes: {
        light: {
          dark: false,
          colors: {
            accent: "#28a746",
            background: "#ececec",
            basic: "#464646",
            error: "#ff5252",
            info: "#17a2b8",
            primary: "#1487e2",
            secondary: "#dfdfdf",
            section: "#f8f8f8",
            success: "#4caf50",
            surface: "#ffffff"
          }
        },
        dark: {
          dark: true,
          colors: {
            accent: "#28a746",
            background: "#121212",
            basic: "#767676",
            error: "#ff5252",
            info: "#17a2b8",
            primary: "#4188d9",
            secondary: "#424242",
            section: "#2d2d2d",
            success: "#4caf50",
            surface: "#1e1e1e"
          }
        }
      }
    }
  });
