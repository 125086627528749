<template>
  <v-sheet v-bind="sheetProps">
    <v-row dense class="ma-0">
      <v-col
        v-for="header in basicHeaders"
        :key="header.text"
        :md="mdCols[header.filterType] || 3"
        :cols="cols[header.filterType] || 6"
      >
        <table-filter-item
          :ref="v => (filterRefs[header.text] = v)"
          v-model="filters[header.value]"
          :filter-type="header.filterType"
          :items="header.selectableOptions"
          :text="header.text"
          :is-currency="header.isCurrency"
          :is-mandatory="header.isMandatory"
          :icon="header.icon"
          :future-date-values="header.futureDateValues"
          :color="color"
          :checkbox-values="header.checkboxValues"
          @update-filter="updateFilter"
        />
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row v-show="showAllFilters" dense class="ma-0">
        <v-col
          v-for="header in advancedHeaders"
          :key="header.text"
          :md="mdCols[header.filterType] || 3"
          :cols="cols[header.filterType] || 6"
        >
          <table-filter-item
            :ref="v => (filterRefs[header.text] = v)"
            v-model="filters[header.value]"
            :filter-type="header.filterType"
            :items="header.selectableOptions"
            :text="header.text"
            :is-currency="header.isCurrency"
            :is-mandatory="header.isMandatory"
            :icon="header.icon"
            :future-date-values="header.futureDateValues"
            :color="color"
            :checkbox-values="header.checkboxValues"
            @update-filter="updateFilter"
          />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row dense class="ma-0">
      <v-col :md="hasAdvancedFilters ? 9 : 12" cols="12" order="1" order-md="0">
        <table-active-filters
          :active-filter="filters"
          :headers="headers"
          @clicked="handleClickedFilter"
          @clear-filter="clearFilter"
        />
      </v-col>
      <v-col
        v-if="hasAdvancedFilters && !alwaysShowAllFilters"
        md="3"
        cols="12"
        class="order-md-1 order-0"
      >
        <v-row class="justify-md-end justify-start ma-0">
          <a
            class="text-body-2"
            data-testid="advanced-filters-toggle"
            @click="showAllFilters = !showAllFilters"
          >
            <v-icon
              class="mr-1 mb-1"
              style="color: inherit"
              size="16"
              :icon="
                showAllFilters ? mdiFilterVariantMinus : mdiFilterVariantPlus
              "
            />
            {{ showAllFilters ? "Hide" : "Show" }} Advanced Filters
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup>
import TableFilterItem from "@/components/shared/data-table/TableFilterItem.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import TableActiveFilters from "@/components/shared/data-table/TableActiveFilters.vue";
import { mdiFilterVariantMinus, mdiFilterVariantPlus } from "@mdi/js";
import { useDisplay } from "vuetify";
import { computed, nextTick, ref, toRefs, watch } from "vue";
const SELECT_TYPES = [
  TableHeader.SELECT_TYPE,
  TableHeader.AUTOCOMPLETE_TYPE,
  TableHeader.MULTI_SELECT_TYPE
];

const RANGE_TYPES = [TableHeader.DATE_TYPE, TableHeader.NUMBER_RANGE];

const props = defineProps({
  headerProps: { type: Object, required: false, default: () => ({}) },
  alwaysShowAllFilters: Boolean,
  headers: { type: Array, required: true },
  modelValue: { type: Object, required: true },
  hover: Boolean,
  color: {
    type: String,
    default: "section"
  }
});

const filterRefs = ref({});

const { modelValue, color, hover, headerProps, alwaysShowAllFilters, headers } =
  toRefs(props);

const emit = defineEmits(["update"]);

const { mdAndUp } = useDisplay();

const mdCols = RANGE_TYPES.reduce((acc, t) => ({ ...acc, [t]: 12 }), {});
const cols = RANGE_TYPES.reduce((acc, t) => ({ ...acc, [t]: 12 }), {});

const filters = ref(JSON.parse(JSON.stringify(modelValue.value)));
const showAllFilters = ref(alwaysShowAllFilters.value);

const sheetProps = computed(() => {
  return {
    rounded: true,
    class: "pa-3 mt-1 mx-0",
    color: color.value,
    elevation: hover.value ? 2 : 0,
    ...headerProps.value
  };
});

const filterableHeaders = computed(() =>
  headers.value.filter(h => h.filterable)
);

const hydratedHeaders = computed(() => {
  return filterableHeaders.value.reduce((accumulator, header) => {
    if (SELECT_TYPES.includes(header.filterType)) {
      if (header.selectableOptionsFn) {
        header.selectableOptions = header.selectableOptionsFn(filters.value);
        header.sideEffect = handleDynamicFilterSideEffects;
      }

      if (header.selectableOptions?.length) accumulator.push(header);
    } else {
      accumulator.push(header);
    }

    return accumulator;
  }, []);
});

const availableHeaders = computed(() => {
  const rangeHeaders = [];
  const nonRangeHeaders = [];

  hydratedHeaders.value.forEach(h => {
    if (RANGE_TYPES.includes(h.filterType)) rangeHeaders.push(h);
    else nonRangeHeaders.push(h);
  });

  rangeHeaders.sort(
    (a, b) => (a.filterOrder || a.order) - (b.filterOrder || b.order)
  );
  nonRangeHeaders.sort(
    (a, b) => (a.filterOrder || a.order) - (b.filterOrder || b.order)
  );

  return nonRangeHeaders.concat(rangeHeaders);
});

const hasAdvancedFilters = computed(() => {
  const length = mdAndUp.value ? 4 : 2;
  return (
    availableHeaders.value.length > length ||
    availableHeaders.value.some(h => RANGE_TYPES.includes(h.filterType))
  );
});

const collapsedCount = computed(() => (mdAndUp.value ? 4 : 2));
const headersByPriority = computed(() => {
  return availableHeaders.value.reduce((accumulator, header, index) => {
    const isRangeType = RANGE_TYPES.includes(header.filterType);
    const isInRange = index < collapsedCount.value;
    return {
      ...accumulator,
      [header.value]: !isRangeType && isInRange
    };
  }, {});
});

const basicHeaders = computed(() => {
  return availableHeaders.value.filter(h => headersByPriority.value[h.value]);
});

const advancedHeaders = computed(() => {
  return availableHeaders.value.filter(h => !headersByPriority.value[h.value]);
});

watch(modelValue, v => (filters.value = JSON.parse(JSON.stringify(v))));

async function handleClickedFilter(filterValue) {
  if (advancedHeaders.value.some(h => h.value === filterValue)) {
    showAllFilters.value = true;
  }
  await nextTick();

  const headerRef = getFilterRef(filterValue);
  if (headerRef?.focus) headerRef.focus();
}

function getFilterRef(filterValue) {
  const header = availableHeaders.value.find(v => v.value === filterValue);
  return filterRefs.value[header.text];
}

function handleDynamicFilterSideEffects(header, value) {
  if (!header) return null;
  const values = [];
  const shouldConvert = !Array.isArray(value);
  if (shouldConvert) values.push(value);
  else values.push(...value);

  values.forEach((v, index) => {
    const hasOldValue = !header.selectableOptions.some(
      o => o === v || o?.value === v
    );
    if (hasOldValue) values[index] = undefined;
  });
  const filteredValues = values.filter(v => v !== undefined);

  return shouldConvert ? filteredValues?.[0] : filteredValues;
}

function updateFilter() {
  Object.keys(filters.value).forEach(f => {
    const header = hydratedHeaders.value.find(v => v.value === f);
    const unfilteredHeader = headers.value.find(v => v.value === f);
    if (!header && !unfilteredHeader) return;
    if (!header && unfilteredHeader) filters.value[f] = null;
    else if (header.sideEffect) {
      filters.value[f] = header.sideEffect(header, filters.value[f]);
    }
  });
  nextTick(() => emit("update", filters.value));
}

function clearFilter(filterValue) {
  const headerRef = getFilterRef(filterValue);
  if (headerRef?.clear) headerRef.clear();
}
</script>

<style lang="scss">
.expand-transition {
  transition: height 0.3s;
}

.expanded {
  height: fit-content;
}
</style>
