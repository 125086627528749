<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              readonly
              data-test="strife-approved"
              :model-value="strifeApproved"
              @click="updateStrifeApproved"
            >
              <template #label>
                Strife Approved (Available in Quote & Apply)
                <active-save-indicator
                  :controller="savingBuffer.strifeApproved.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              readonly
              data-testid="requires-wet-signature"
              :model-value="requiresWetSignature"
              @click="updateRequiresWetSignature"
            >
              <template #label>
                Requires Wet Signature
                <active-save-indicator
                  :controller="
                    savingBuffer.requiresWetSignature.controller.value
                  "
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="firelightCarrierCode"
            data-lpignore="true"
            label="Firelight Code"
            data-testid="firelight-carrier-code"
            :success="Boolean(firelightCarrierCode)"
            @update:model-value="updateFirelightCarrierCode"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.firelightCarrierCode.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="examOneCode"
            data-lpignore="true"
            label="Exam One Code"
            data-testid="exam-one-code"
            :success="Boolean(examOneCode)"
            @update:model-value="updateExamOneCode"
          >
            <template #append-inner>
              <active-save-indicator
                class="ml-3"
                :controller="savingBuffer.examOneCode.controller.value"
              />
            </template>
          </text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useActiveSave } from "@/composables/active-save.composable";
import { markRaw } from "vue";
import { useCarrierView } from "@/stores/carrier-view";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const carrierView = useCarrierView();
const dialog = useDialogStore();
const {
  strifeApproved,
  requiresWetSignature,
  name: carrierName,
  firelightCarrierCode,
  examOneCode
} = storeToRefs(carrierView);

const savingBuffer = {
  strifeApproved: useActiveSave(),
  requiresWetSignature: useActiveSave(),
  firelightCarrierCode: useActiveSave(),
  examOneCode: useActiveSave()
};

function updateFirelightCarrierCode() {
  savingBuffer.firelightCarrierCode.debounceUpdate(
    carrierView.updateFirelightCarrierCode
  );
}

function updateExamOneCode() {
  savingBuffer.examOneCode.debounceUpdate(
    carrierView.updateExamOneCode
  );
}

function updateStrifeApproved() {
  const value = !strifeApproved.value;
  const actionText = value ? "Enable" : "Disable";

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `${actionText} ${carrierName.value} in Quote & Apply`,
    subtitle: "Please confirm your intent",
    func: () => {
      strifeApproved.value = value;
      return carrierView.updateStrifeApproved();
    }
  });
}

function updateRequiresWetSignature() {
  const value = !requiresWetSignature.value;
  const actionText = value ? "Require" : "Remove";
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `${actionText} Wet Signature from ${carrierName.value} in Quote & Apply`,
    subtitle: "Please confirm your intent",
    func: () => {
      requiresWetSignature.value = value;
      return carrierView.updateRequiresWetSignature();
    }
  });
}
</script>
