import { getHttpClient } from "@/http-client";
import { setUserTableItemFromRaw, setUserFromRaw } from "@/models/User";

const baseUrl = "api/boss/users";

export async function getUserDetails(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  return setUserFromRaw(data.user);
}

export function resetUserOTP(id) {
  return getHttpClient().post(`${baseUrl}/${id}/reset-otp`);
}
export function deleteUserOTP(id) {
  return getHttpClient().delete(`${baseUrl}/${id}/otp`);
}

export function lockUser(id) {
  return getHttpClient().post(`${baseUrl}/${id}/lock`);
}

export function unlockUser(id) {
  return getHttpClient().post(`${baseUrl}/${id}/unlock`);
}

export async function getUsers(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  const items = data.users.map(setUserTableItemFromRaw);

  return { items, meta: data.meta };
}

export function createUser(params) {
  return getHttpClient().post(`${baseUrl}`, params);
}

export function updateUser(id, user) {
  return getHttpClient().put(`${baseUrl}/${id}`, { user });
}
export function sendPasswordReset(id) {
  return getHttpClient().post(`${baseUrl}/${id}/password-reset`);
}
export function resetUserTutorials(id) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    user: { tutorials: { home: false } }
  });
}

export async function checkUserEmail(email) {
  const { data } = await getHttpClient().post(`${baseUrl}/check-email`, {
    email
  });

  return { provider: data.provider, method: data.method };
}
