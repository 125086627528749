import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/email-exception-list";
export async function getEmailExceptionList() {
  const { data } = await getHttpClient().get(baseUrl);
  return data.items
    .map(i => ({ email: i }))
    .toSorted((a, b) => a.email.localeCompare(b.email));
}

export async function addToEmailExceptionList(email) {
  return await getHttpClient().post(baseUrl, { email });
}

export function removeFromEmailExceptionList(email) {
  return getHttpClient().delete(baseUrl, { params: { email } });
}
