import {
  setRequestFromReview,
  setEmployeeFromRequest,
  setEmployeeReviewFromRequest,
  setEmployeeTableItemFromRequest
} from "@/models/Employee";
import { getHttpClient } from "@/http-client";
import { setEmployeeProvisioningTaskAnswerFromRequest } from "@/models/EmployeeProvisioningTask";

const baseUrl = "api/boss/employees";

export async function getEmployees({ agent_id = null } = {}) {
  const params = new URLSearchParams();
  if (agent_id) params.append("agent_id", agent_id);
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.employees.map(setEmployeeTableItemFromRequest);
}

export async function getEmployee(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  return setEmployeeFromRequest(data.employee);
}

export function updateEmployee(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, { employee: body });
}

export async function createEmployeeReview(id, employeeReview) {
  const { data } = await getHttpClient().post(`${baseUrl}/${id}/reviews`, {
    employee_review: setRequestFromReview(employeeReview)
  });

  return setEmployeeReviewFromRequest(data.employee_review);
}

export async function createEmployee(employee) {
  const { data } = await getHttpClient().post(baseUrl, { employee });
  return setEmployeeFromRequest(data.employee);
}

export function deprovisionUser(employeeId) {
  return getHttpClient().post(`${baseUrl}/${employeeId}/deprovision`);
}

export function updateProvisioningTaskAnswer(employeeId, answerId, body) {
  return getHttpClient().put(
    `${baseUrl}/${employeeId}/provisioning-task-answers/${answerId}`,
    {
      employee_provisioning_task_answer: body
    }
  );
}

export function deleteProvisioningTaskAnswer(employeeId, answerId) {
  return getHttpClient().delete(
    `${baseUrl}/${employeeId}/provisioning-task-answers/${answerId}`
  );
}

export async function createProvisioningTaskAnswer(employeeId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${employeeId}/provisioning-task-answers`,
    { employee_provisioning_task_answer: body }
  );
  return setEmployeeProvisioningTaskAnswerFromRequest(data);
}
