<template>
  <v-card color="section" flat tile>
    <v-card-text class="pa-3">
      <v-row v-if="showSideBySide" class="ma-0">
        <v-col cols="12" v-bind="leftColProps" class="pr-1 pl-0 py-0">
          <slot name="left" />
        </v-col>
        <v-col cols="12" v-bind="rightMdColSize" class="pl-2 pr-0 py-0">
          <slot name="right" />
        </v-col>
      </v-row>
      <v-tabs-window v-else v-model="activeTab" touchless>
        <v-tabs-window-item>
          <slot name="left" />
        </v-tabs-window-item>
        <v-tabs-window-item>
          <slot name="right" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, watch, computed, toRefs } from "vue";
const props = defineProps({
  showSideBySide: Boolean,
  modelValue: { type: Number, required: true },
  expanded: Boolean,
  expandFull: Boolean
});

const { expandFull, expanded, modelValue } = toRefs(props);

const emit = defineEmits(["update:model-value"]);
const activeTab = ref(props.modelValue);

const leftColProps = computed(() => {
  let md = 4;
  if (expanded.value) md = expandFull.value ? 12 : 8;
  return { md };
});

const rightMdColSize = computed(() => {
  let md = 8;
  let classes = "";
  if (expanded.value) md = 4;
  if (expanded.value && expandFull.value) classes = "d-none";
  return { md, class: classes };
});

watch(modelValue, newValue => {
  activeTab.value = newValue;
});

watch(activeTab, v => emit("update:model-value", v));
</script>
