export const STAGE = {
  PROVISIONING: "provisioning",
  DEPROVISIONING: "deprovisioning"
};
export const STAGE_TEXT = {
  [STAGE.PROVISIONING]: "Provisioning",
  [STAGE.DEPROVISIONING]: "Deprovisioning"
};
export const STAGES = [
  { title: STAGE_TEXT[STAGE.PROVISIONING], value: STAGE.PROVISIONING },
  { title: STAGE_TEXT[STAGE.DEPROVISIONING], value: STAGE.DEPROVISIONING }
];
const valOrArray = v => (Array.isArray(v) ? v : []);

export function EmployeeProvisioningTask(model = {}) {
  return {
    id: model?.id,
    text: model?.text,
    includedDepartments: valOrArray(model?.includedDepartments),
    excludedDepartments: valOrArray(model?.excludedDepartments),
    includedTitles: valOrArray(model?.includedTitles),
    excludedTitles: valOrArray(model?.excludedTitles),
    createdAt: model?.createdAt,
    stage: model?.stage
  };
}

export function setEmployeeProvisioningTaskFromRequest(raw = {}) {
  const model = EmployeeProvisioningTask();

  model.id = raw?.id;
  model.text = raw?.text;
  model.includedDepartments = valOrArray(raw?.included_departments);
  model.excludedDepartments = valOrArray(raw?.excluded_departments);
  model.includedTitles = valOrArray(raw?.included_titles);
  model.excludedTitles = valOrArray(raw?.excluded_titles);
  model.createdAt = raw?.created_at;
  model.stage = raw?.stage;

  return model;
}

export function setRequestBodyFromEmployeeProvisioningTask(
  model = EmployeeProvisioningTask()
) {
  return {
    text: model.text,
    excluded_departments: model.excludedDepartments,
    included_departments: model.includedDepartments,
    excluded_titles: model.excludedTitles,
    included_titles: model.includedTitles,
    stage: model.stage
  };
}

export function EmployeeProvisioningTaskAnswer(model = {}) {
  return {
    id: model?.id,
    completed: Boolean(model?.completed),
    explanation: model?.explanation,
    provisioningTaskText: model?.provisioningTaskText,
    provisioningTaskId: model?.provisioningTaskId,
    provisioningTaskStage: model?.provisioningTaskStage
  };
}
export function setEmployeeProvisioningTaskAnswerFromRequest(raw = {}) {
  const model = EmployeeProvisioningTaskAnswer();

  model.id = raw?.id;
  model.completed = Boolean(raw?.completed);
  model.explanation = raw?.explanation;
  model.provisioningTaskText = raw?.task?.text;
  model.provisioningTaskId = raw?.task?.id;
  model.provisioningTaskStage = raw?.task?.stage;

  return model;
}
