<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-card-title> TIA Profiles </v-card-title>
      <v-spacer />
      <app-button
        class="text-none ma-3"
        color="primary"
        variant="text"
        density="comfortable"
        data-testid="add-product-temporary-insurance-profile"
        :icon="mdiPlus"
        @click="add"
      />
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="item in temporaryInsuranceProfileProducts"
        :key="item.id"
        data-testid="temporary-insurance-profile"
      >
        <v-list-item-title>
          <v-row class="ma-0" align="center">
            {{ item.name }}
            <v-spacer />
            <app-button
              class="text-none"
              :icon="mdiDelete"
              color="error"
              variant="text"
              density="comfortable"
              data-testid="remove"
              @click="remove(item)"
            />
          </v-row>
        </v-list-item-title>
        <card-items :items="description(item)" />
      </v-list-item>
      <v-list-item v-if="!temporaryInsuranceProfileProducts.length">
        <v-list-item-title>No TIA Profiles</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";
import AddProductTemporaryInsuranceProfileDialog from "@/components/products/dialogs/AddProductTemporaryInsuranceProfileDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { storeToRefs } from "pinia";
import {
  currencyFormat,
  numberFormat,
  parseErrorMessage
} from "@/util/helpers";

import { removeTemporaryInsuranceProfile } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useProductView } from "@/stores/product-view";
import { markRaw } from "vue";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { textItem } from "@/components/shared/card-items/card-items";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const productView = useProductView();
const { temporaryInsuranceProfileProducts, id, carrier } =
  storeToRefs(productView);

async function remove(item) {
  try {
    dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Remove Temporary Insurance Profile",
      subtitle: "This cannot be undone",
      func: async () => {
        await removeTemporaryInsuranceProfile(id.value, item.id);
        const index = temporaryInsuranceProfileProducts.value.findIndex(
          i => i.id === item.id
        );
        temporaryInsuranceProfileProducts.value.splice(index, 1);
        snackbar.showSuccessSnackbar({
          message: "Product Temporary Insurance Profile has been deleted",
          timeout: 6000
        });
      }
    });
  } catch (e) {
    this.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}
async function add() {
  const result = await dialog.showDialog({
    component: markRaw(AddProductTemporaryInsuranceProfileDialog),
    productId: id.value,
    carrierId: carrier.value.id
  });
  if (!result?.id) return;
  temporaryInsuranceProfileProducts.value.push(result);
  snackbar.showSuccessSnackbar({
    message: "Temporary Insurance Profile has been added",
    timeout: 6000
  });
}

function description(item) {
  const items = [];
  items.push(
    textItem({
      title: "Days of Coverage",
      text: item.daysOfCoverage?.toString()
    })
  );
  items.push(
    textItem({
      title: "Min Bind Age (Days)",
      text: numberFormat(item.minBindAgeInDays)
    })
  );
  items.push(
    textItem({
      title: "Max Bind Age (Years)",
      text: numberFormat(item.maxBindAgeInYears)
    })
  );
  items.push(
    textItem({
      title: "Max Death Benefit",
      text: currencyFormat(item.maxFaceAmount, 0)
    })
  );
  items.push(
    textItem({
      title: "Max Death Benefit w/ Existing Coverage",
      text: currencyFormat(
        item.maxFaceAmountIfExistingCoverageFromSameCarrier,
        0
      )
    })
  );
  items.push(
    textItem({
      title: "Max Death Benefit to Bind",
      text: currencyFormat(item.maxFaceAmountToBind, 0)
    })
  );
  return items;
}
</script>
