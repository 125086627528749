import { getHttpClient } from "@/http-client";
import { setEmployeeProvisioningTaskFromRequest } from "@/models/EmployeeProvisioningTask";

const baseUrl = "api/boss/employee-provisioning-tasks";

let cancelToken = null;
export async function getProvisioningTasks(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.employee_provisioning_tasks.map(
      setEmployeeProvisioningTaskFromRequest
    ),
    meta: response.data.meta
  };
}

export function createProvisioningTask(body) {
  return getHttpClient().post(baseUrl, { employee_provisioning_task: body });
}

export function updateProvisioningTask(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    employee_provisioning_task: body
  });
}

export function deleteProvisioningTask(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
