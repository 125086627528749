<template>
  <v-fade-transition mode="out-in">
    <v-skeleton-loader v-if="loading" type="card" />
    <div v-else key="loaded" class="ma-1 bg-surface">
      <employee-view-card />
      <view-tabs v-model="activeTab" :tabs="tabs" />
    </div>
  </v-fade-transition>
</template>

<script setup>
import ViewTabs from "@/components/shared/ViewTabs.vue";
import EmployeeViewCard from "@/components/employees/EmployeeViewCard.vue";
import EmployeeViewDetails from "@/components/employees/EmployeeViewDetails.vue";
import EmployeeViewReviews from "@/components/employees/EmployeeViewReviews.vue";
import EmployeeViewProvisioningTasks from "@/components/employees/EmployeeViewProvisioningTasks.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

import { useHead } from "@unhead/vue";
import { computed, markRaw, onBeforeUnmount, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useEmployeeView } from "@/stores/employee-view";
import { mdiAccountPlus, mdiAccountRemove, mdiTimeline } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { STAGE } from "@/models/EmployeeProvisioningTask";

const head = useHead({ title: "Employee" });

const props = defineProps({
  id: { type: [Number, String], required: true },
  page: { type: String, optional: true, default: "" }
});

const activeTab = ref(0);

const employeeView = useEmployeeView();
const { updatable, name } = storeToRefs(employeeView);

const { breadcrumb } = storeToRefs(useInstanceStore());

const snackbar = useSnackbarStore();
const router = useRouter();
const loading = ref(false);

const tabs = computed(() => {
  const tabs = [
    {
      title: "Performance Reviews",
      icon: mdiTimeline,
      component: markRaw(EmployeeViewReviews),
      page: "preformance-reviews"
    }
  ];

  if (updatable.value) {
    tabs.push({
      title: "Details",
      icon: mdiTimeline,
      component: markRaw(EmployeeViewDetails),
      page: "details"
    });

    tabs.push({
      title: "Provisioning Tasks",
      icon: mdiAccountPlus,
      component: markRaw(EmployeeViewProvisioningTasks),
      page: "provisioning",
      componentProps: {
        stage: STAGE.PROVISIONING
      }
    });

    tabs.push({
      title: "Deprovisioning Tasks",
      icon: mdiAccountRemove,
      component: markRaw(EmployeeViewProvisioningTasks),
      page: "provisioning",
      componentProps: {
        stage: STAGE.DEPROVISIONING
      }
    });
  }

  return tabs;
});

async function fetchEmployee() {
  loading.value = true;
  try {
    await employeeView.initialize(props.id);
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
    else activeTab.value = tabs.value.findIndex(tab => tab.defaultTab);

    breadcrumb.value = name.value;
    head.patch({
      title: name.value
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    await router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return;
  const tab = tabs.value[activeTab.value];
  if (!tab) return;
  let page = tab.page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

watch(tabs, v => {
  if (v.length <= activeTab.value) {
    activeTab.value = 0;
  }
});

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

onBeforeUnmount(() => (breadcrumb.value = ""));
fetchEmployee();
</script>
