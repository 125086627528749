<template>
  <div
    style="position: fixed; right: 15px; z-index: 10"
    :style="{ bottom: isMobile ? '60px' : '15px' }"
  >
    <app-button
      color="accent"
      class="text-none rounded-pill"
      :class="{ 'px-0': isMobile }"
      :style="{ 'min-width': isMobile ? '36px !important' : undefined }"
      @click="emit('click')"
    >
      <v-icon :icon="mdiHelpCircleOutline" />
      <div v-if="!isMobile" class="ml-1">Need Help?</div>
    </app-button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { mdiHelpCircleOutline } from "@mdi/js";
import { useDisplay } from "vuetify";
const emit = defineEmits(["click"]);

const { smAndDown } = useDisplay();
const isMobile = computed(() => smAndDown.value);
</script>
