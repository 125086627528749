<template>
  <v-expansion-panel>
    <v-expansion-panel-title data-testid="panel">
      <checkbox-field
        v-model="model.completed"
        hide-details
        dense
        class="mr-1"
        :success="model.completed"
        data-testid="completed"
        @click.stop.prevent="toggleCompleted"
      />
      <active-save-indicator :controller="savingBuffer.completed" />
      <span :class="{ 'text-success': model.completed }" data-testid="text">
        {{ model.provisioningTaskText }}
      </span>
      <v-spacer />
      <app-button
        color="error"
        :icon="mdiDelete"
        variant="text"
        density="comfortable"
        data-testid="delete"
        @click.stop.prevent="emit('delete-task')"
      />
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <textarea-field
        v-model="model.explanation"
        label="Explanation (Optional)"
        class="mt-3"
        data-testid="explanation"
        :success="Boolean(model.explanation)"
        @update:model-value="updateExplanation"
      >
        <template #append-inner>
          <active-save-indicator
            :controller="savingBuffer.explanation.controller.value"
          />
        </template>
      </textarea-field>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref, watch } from "vue";
import { mdiDelete } from "@mdi/js";

const props = defineProps({
  updateFn: {
    type: Function,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(["update:model-value", "delete-task"]);

const savingBuffer = {
  explanation: useActiveSave(),
  completed: useActiveSave()
};

const model = ref(props.modelValue);

function updateExplanation() {
  savingBuffer.explanation.debounceUpdate(() =>
    props.updateFn(model.value.id, {
      explanation: model.value.explanation
    })
  );
}

function toggleCompleted() {
  model.value.completed = !model.value.completed;
  savingBuffer.completed.update(() =>
    props.updateFn(model.value.id, {
      completed: model.value.completed
    })
  );
}

watch(model, v => emit("update:model-value", v), { deep: true });
watch(
  () => props.modelValue,
  v => {
    if (JSON.stringify(v) === JSON.stringify(model.value)) return;
    model.value = props.modelValue;
  }
);
</script>
