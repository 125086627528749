<template>
  <div class="pt-3">
    <v-alert
      v-if="completedFormatted"
      type="info"
      class="ma-3"
      variant="outlined"
      data-testid="completed-banner"
    >
      This ticket was marked as completed on {{ completedFormatted }}
    </v-alert>

    <div class="px-4 pb-3">
      <h1 class="text-h6">Support Ticket</h1>
      <p class="text-body-2" data-testid="card-description">
        {{ description }}
      </p>
    </div>

    <v-divider />
    <card-items :items="items" class="ma-1" />

    <template v-if="supportingDocuments.length">
      <v-divider />
      <card-items :items="supportingDocuments" class="ma-1" />
    </template>
  </div>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";
import {
  textItem,
  linkItem,
  routerLinkItem
} from "@/components/shared/card-items/card-items";

import {
  listToSentence,
  getRouterLink,
  timestampFormatter,
  downloadFileAsLink
} from "@/util/helpers";
import { useSupportTicketView } from "@/stores/support-ticket-view";

import { computed } from "vue";
import { storeToRefs } from "pinia";
import { getDocumentUrl } from "@/api/documents.service";
import { useDisplay } from "vuetify";

const { mdAndDown } = useDisplay();
const supportTicket = useSupportTicketView();
const {
  categories,
  location,
  ownable,
  assignable,
  createdAt,
  completedAt,
  documents,
  description
} = storeToRefs(supportTicket);

const items = computed(() => {
  return [
    textItem({
      title: "Category",
      text: listToSentence(categories.value)
    }),
    linkItem({
      title: "Location Reported",
      href: location.value,
      text: location.value
    }),
    routerLinkItem({
      title: "Ticket Opened By",
      text: ownable.value.name,
      to: getRouterLink(ownable.value.type, ownable.value.id)
    }),
    textItem({
      title: "Ticket Assigned To",
      text: assignable.value.name
    }),
    textItem({
      title: "Ticket Created",
      text: timestampFormatter(createdAt.value, null, "date-time")
    })
  ];
});

const supportingDocuments = computed(() => {
  return documents.value.map((d, i) =>
    linkItem({
      key: `Supporting Document ${i}`,
      title: "Supporting Document",
      text: `${d.name} (${d.fileSize})`,
      linkProps: downloadFileAsLink(getDocumentUrl(d.uid), mdAndDown.value)
    })
  );
});

const completedFormatted = computed(() =>
  completedAt.value
    ? timestampFormatter(completedAt.value, null, "date-time")
    : null
);
</script>
