<template>
  <v-card
    v-if="href"
    :data-testid="dataTestid"
    hover
    :variant="outlined ? 'outlined' : undefined"
    height="100%"
    style="padding-bottom: 2rem"
    :href="href"
    :target="target"
    :class="cardClass + '-' + themeClass"
  >
    <v-row
      v-if="img || icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img
        v-if="img"
        :src="img"
        style="width: auto; max-height: 5rem; height: auto; max-width: 14rem"
      />
      <v-icon v-else-if="icon" :color="iconColor" :icon="icon" size="42" />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ description }} </v-card-subtitle>

    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <v-icon
          v-bind="templateProps"
          style="position: absolute; bottom: 0; right: 0"
          :loading="loading"
          color="accent"
          class="text-none"
          :icon="mdiPlus"
        />
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else-if="to"
    hover
    :data-testid="dataTestid"
    :variant="outlined ? 'outlined' : undefined"
    height="100%"
    style="padding-bottom: 2rem"
    :to="to"
    :class="cardClass + '-' + themeClass"
  >
    <v-row
      v-if="img || icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="img" :src="img" style="width: auto; height: 3rem" />
      <v-icon v-else-if="icon" :color="iconColor" size="42" :icon="icon" />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ description }} </v-card-subtitle>

    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <v-icon
          v-bind="templateProps"
          style="position: absolute; bottom: 0; right: 0"
          :loading="loading"
          :color="buttonColor"
          class="text-none"
          :icon="mdiPlus"
        />
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else
    height="100%"
    hover
    :data-testid="dataTestid"
    :variant="outlined ? 'outlined' : undefined"
    style="padding-bottom: 2rem"
    :style="{ backgroundColor: outlined ? 'transparent' : null }"
    :class="cardClass + '-' + themeClass"
    @click="click"
  >
    <v-row
      v-if="img || icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="img" :src="img" style="width: auto; height: 3rem" />
      <v-icon v-else-if="icon" :color="iconColor" size="42" :icon="icon" />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ description }} </v-card-subtitle>

    <div style="position: absolute; bottom: 5px; right: 5px">
      <v-progress-circular v-if="loading" indeterminate color="accent" />
      <v-tooltip v-else location="bottom">
        <template #activator="{ props: templateProps }">
          <v-icon
            v-bind="templateProps"
            :color="buttonColor"
            class="text-none"
            :icon="mdiPlus"
          />
        </template>
        <span>Start Quoting</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script setup>
import { mdiRocketLaunch, mdiPlus } from "@mdi/js";
import { computed, defineOptions } from "vue";
import { useTheme } from "vuetify";
defineProps({
  outlined: Boolean,
  loading: Boolean,
  buttonColor: { type: String, default: "accent" },
  cardClass: { type: String, default: "accent" },
  href: { type: String, default: null },
  icon: { type: String, default: null },
  iconColor: { type: String, default: null },
  img: { type: String, default: null },
  target: { type: String, default: null },
  text: { type: String, default: null },
  to: { type: Object, default: null },
  click: { type: Function, default: null },
  type: { type: String, default: null },
  description: { type: String, default: null },
  dataTestid: { type: String, default: null }
});

defineOptions({
  inheritAttrs: false
});

const theme = useTheme();
const themeClass = computed(() =>
  theme.current.value.dark ? "dark" : "light"
);
</script>
