<template>
  <v-card>
    <v-card-title>Marketing Manager Profit </v-card-title>
    <v-divider />
    <v-data-table
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      :items="table.mappedItems.value"
      :headers="table.tableHeaders.value"
      :loading="table.loading.value"
      data-testid="marketing-manager-profit-report"
      loading-text="Fetching Marketing Manager Data"
      :mobile="null"
      mobile-breakpoint="sm"
    >
      <template #top>
        <table-filter
          always-show-all-filters
          color="transparent"
          :model-value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
      </template>
      <template #[`item.oneMonth`]="{ item }">
        <currency-formatter :model-value="item.oneMonth" :decimal-length="0" />
      </template>
      <template #[`item.fourMonths`]="{ item }">
        <currency-formatter
          :model-value="item.fourMonths"
          :decimal-length="0"
        />
      </template>
      <template #[`item.oneYear`]="{ item }">
        <currency-formatter :model-value="item.oneYear" :decimal-length="0" />
      </template>
      <template #[`item.allTime`]="{ item }">
        <currency-formatter :model-value="item.allTime" :decimal-length="0" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { getMarketingManagersReport } from "@/api/reports.service";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { DATE_FILTERS } from "@/constants/date-filter.constants";

const PLAN_TYPES = [
  { title: "Accidental Death", value: "Accidental Death" },
  { title: "Critical Illness", value: "Critical Illness" },
  { title: "DIA", value: "DIA" },
  { title: "Disability", value: "Disability" },
  { title: "FIA", value: "FIA" },
  { title: "Final Expense", value: "Final Expense" },
  { title: "Fixed", value: "Fixed" },
  { title: "Guaranteed Issue Whole Life", value: "GIWL" },
  { title: "IUL", value: "IUL" },
  { title: "LTC", value: "LTC" },
  { title: "MYGA", value: "MYGA" },
  { title: "SPIA", value: "SPIA" },
  { title: "Term", value: "TRM" },
  { title: "Term - Non Medical", value: "TRM - Non Medical" },
  { title: "Term - ROP", value: "TRM - ROP" },
  { title: "UL", value: "UL" },
  { title: "VUL", value: "VUL" },
  { title: "Whole Life", value: "Whole Life" },
  {
    title: "Whole Life - Non Participating",
    value: "Whole Life - Non Participating"
  },
  { title: "Other", value: "Other" }
];

const table = useTable({
  headers: [
    new TableHeader({
      text: "Marketing Manager",
      value: "name",
      map: "name",
      sortKey: "name",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "1 Month",
      value: "oneMonth",
      map: "oneMonth",
      sortKey: "oneMonth",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "4 Months",
      value: "fourMonths",
      map: "fourMonths",
      sortKey: "fourMonths",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "1 Year",
      value: "oneYear",
      map: "oneYear",
      sortKey: "oneYear",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "All Time",
      value: "allTime",
      map: "allTime",
      sortKey: "allTime",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Timeframe",
      value: "timeframe",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_DATE_FILTER_TYPE
    }),
    new TableHeader({
      text: "Product Types",
      value: "product_types",
      map: "product_types",
      selectableOptions: PLAN_TYPES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MULTI_SELECT_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  options: TableOptions({ sortBy: [{ key: "oneYear", order: "desc" }] }),
  filter: {
    timeframe: {
      type: DATE_FILTERS.LAST_12_MONTHS.value,
      value: DATE_FILTERS.LAST_12_MONTHS.fn()
    }
  },
  getData: getMarketingManagersReport,
  localSort: true
});

const snackbar = useSnackbarStore();
async function getData() {
  table.loading.value = true;
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateFilter(value) {
  table.filter.value = value;
  getData();
}

getData();
</script>
