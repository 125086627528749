<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-row v-if="loading" class="ma-0 pa-3" justify="center" align="center">
        <v-progress-circular indeterminate />
      </v-row>
      <v-row v-else key="loaded" class="ma-0 pa-3" dense>
        <v-col data-testid="todo-description" cols="12">
          <h3 class="text-body-2 text-grey lighten-2">Description</h3>
          <div class="text-body-1">
            {{ todo.description || "No Description" }}
          </div>
        </v-col>
        <v-col data-testid="todo-subjects" cols="12" md="6">
          <h3 class="text-body-2 text-grey lighten-2">Subjects</h3>
          <v-chip-group v-if="todo.subjects.length" column class="ma-n1">
            <v-chip
              v-for="subject in todo.subjects"
              :key="subject.key"
              :to="getRouterLink(subject.type, subject.id)"
              color="primary"
              target="_blank"
              class="ma-1"
              variant="outlined"
              size="small"
              label
              exact
            >
              <v-icon
                size="small"
                class="mr-1"
                :icon="getTypeIcon(subject.type)"
              />
              {{ subject.name }}
            </v-chip>
          </v-chip-group>
          <div v-else>No subjects</div>
        </v-col>
        <v-col data-testid="todo-assignees" cols="12" md="6">
          <h3 class="text-body-2 text-grey lighten-2">Assignees</h3>
          <v-chip-group v-if="todo.assignables.length" column class="ma-n1">
            <v-chip
              v-for="assignee in todo.assignables"
              :key="assignee.key"
              :to="getRouterLink(assignee.type, assignee.id)"
              color="primary"
              target="_blank"
              class="ma-1"
              variant="outlined"
              size="small"
              label
              exact
            >
              <v-icon
                size="small"
                class="mr-1"
                :icon="getTypeIcon(assignee.type)"
              />
              {{ assignee.name }}
            </v-chip>
          </v-chip-group>
          <div v-if="!todo.assignables.length">No assignables</div>
        </v-col>
        <v-col data-testid="todo-created-on" cols="12" md="6">
          <h3 class="text-body-2 text-grey lighten-2 pr-1">Created on</h3>
          <span class="text-body-1">
            {{ createdAt }}
          </span>
        </v-col>
        <v-col data-testid="todo-created-by" cols="12" md="6">
          <h3 class="text-body-2 text-grey lighten-2 pr-1">Created by</h3>
          <span class="text-body-1">
            {{ todo.ownableName }}
          </span>
        </v-col>
        <v-col data-testid="todo-documents" cols="12">
          <h3 class="text-body-2 text-grey lighten-2 pr-1">
            Related Documents
          </h3>
          <v-chip-group v-if="todo.documents.length" column class="ma-n1">
            <v-chip
              v-for="doc in todo.documents"
              :key="doc.id"
              v-bind="getDownload(doc)"
              color="primary"
              class="ma-1"
              variant="outlined"
              size="small"
              label
            >
              <v-icon size="small" class="mr-1" :icon="mdiDownload" />
              {{ doc.name }}
            </v-chip>
          </v-chip-group>
          <div v-else>No documents</div>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script setup>
import { getTodo } from "@/api/todos.service";
import {
  downloadFileAsLink,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, computed, watch } from "vue";
import { getTypeIcon, getRouterLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";
import { mdiDownload } from "@mdi/js";
import { useDisplay } from "vuetify";
const props = defineProps({
  id: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(["update:is-complete", "update:loading"]);

const snackbar = useSnackbarStore();
const loading = ref(false);
const todo = ref(null);

const createdAt = computed(() => {
  if (!todo.value) return "";
  return timestampFormatter(todo.value.createdAt, "none", "date-time");
});
async function fetchTodo() {
  try {
    loading.value = true;
    const res = await getTodo(props.id);
    todo.value = res;
    emit("update:is-complete", res.complete);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const { mdAndDown } = useDisplay();
function getDownload(document) {
  return downloadFileAsLink(getDocumentUrl(document.uid), mdAndDown.value);
}

watch(loading, v => emit("update:loading", v));

fetchTodo();

defineExpose({ fetchTodo });
</script>
