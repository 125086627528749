<template>
  <v-card class="ma-1">
    <v-tabs v-model="activeTab" color="primary" show-arrows>
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        class="text-none"
        :data-testid="`tab-${tab.title}`"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-divider />

    <v-card-text :class="`inforce-font-size-${fontSizeOffset}`">
      <v-row class="pa-3 py-3" align="center">
        <h2 data-testid="title">{{ tabs[activeTab].title }}</h2>
        <span v-if="isRefreshing" class="text-grey ml-3">
          Last Refreshed {{ lastTimeRefreshed }}
        </span>
        <v-spacer />
        <!-- Open in fullscreen dialog -->
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiFormatFontSizeIncrease"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              data-testid="increase-font-size"
              :disabled="fontSizeOffset >= 7"
              @click="incrementFontSizeOffset(true)"
            />
          </template>
          Increase Font Size
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiFormatFontSizeDecrease"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              data-testid="decrease-font-size"
              :disabled="fontSizeOffset <= 0"
              @click="incrementFontSizeOffset(false)"
            />
          </template>
          Decrease Font Size
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="isRefreshing ? mdiRepeatOff : mdiRepeat"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              data-testid="automatic-data-refresh"
              @click="toggleDataRefresh(false)"
            />
          </template>
          {{ isRefreshing ? "Disable" : "Enable" }} Data Refreshing
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="isCyclingThroughTables ? mdiStop : mdiPlay"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              data-testid="automatic-table-cycling"
              @click="toggleTableCycling(false)"
            />
          </template>
          {{ isCyclingThroughTables ? "Disable" : "Enable" }} Automatic Table
          Cycling
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="fullscreen ? mdiFullscreenExit : mdiFullscreen"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              data-testid="fullscreen"
              @click="fullscreen = !fullscreen"
            />
          </template>
          {{ fullscreen ? "Exit Fullscreen" : "Enter Fullscreen" }}
        </v-tooltip>
      </v-row>
      <v-data-table
        v-model:sort-by="tabs[activeTab].table.options.value.sortBy"
        no-data-text="Unable to load results"
        class="inforce-table"
        data-testid="inforce-table"
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="tabs[activeTab].table.tableHeaders.value"
        :items="tabs[activeTab].table.mappedItems.value"
        :loading="tabs[activeTab].table.loading.value"
        @update:options="tabs[activeTab].setOptions"
      >
        <template #[`item.production`]="{ item }">
          <currency-formatter
            :model-value="item.production"
            :decimal-length="0"
          />
        </template>
        <template #[`item.goal`]="{ item }">
          <percentage-formatter
            :model-value="item.goal"
            floor
            is-decimal
            :decimal-length="0"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { format } from "@/util/date-util";

import { getScorecardReport } from "@/api/reports.service";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";

import {
  mdiFormatFontSizeIncrease,
  mdiFormatFontSizeDecrease,
  mdiRepeatOff,
  mdiRepeat,
  mdiStop,
  mdiPlay,
  mdiFullscreenExit,
  mdiFullscreen
} from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { computed, onBeforeUnmount, ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const FONT_SIZE_SCALER = 1;

useHead({ title: "Scorecard" });

const snackbar = useSnackbarStore();
const instance = useInstanceStore();
const { fullscreen } = storeToRefs(instance);

const lastTimeRefreshed = ref(null);
const activeTab = ref(0);
const fontSizeOffset = ref(0);
const isCyclingThroughTables = ref(false);
const isRefreshing = ref(false);
const loading = ref(false);
const firstCreated = ref(true);

const headers = [
  new TableHeader({
    text: "Name",
    value: "name",
    map: "name",
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Cases",
    value: "inforce_cases",
    map: "inforce_cases",
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Production",
    value: "production",
    map: "production",
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Goal",
    value: "goal",
    map: "goal",
    ...TableHeader.IS_SORTABLE
  })
];

const todaysTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers
});

const weeksTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers
});

const monthsTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers
});

const yearsTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers
});

const lastYearsTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers
});

const pendingTable = useTable({
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Cases",
      value: "pending_cases",
      map: "pending_cases",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Production",
      value: "production",
      map: "production",
      ...TableHeader.IS_SORTABLE
    })
  ]
});

const tabs = computed(() => [
  {
    title: "Today",
    table: todaysTable,
    setItems: data => todaysTable.replaceItems(data.day),
    setOptions: data => (todaysTable.options.value = data)
  },
  {
    title: "Last 7 Days",
    table: weeksTable,
    setItems: data => weeksTable.replaceItems(data.week),
    setOptions: data => (weeksTable.options.value = data)
  },
  {
    title: "Last 30 Days",
    table: monthsTable,
    setItems: data => monthsTable.replaceItems(data.month),
    setOptions: data => (monthsTable.options.value = data)
  },
  {
    title: new Date().getFullYear(),
    table: yearsTable,
    setItems: data => yearsTable.replaceItems(data.year),
    setOptions: data => (yearsTable.options.value = data)
  },
  {
    title: new Date().getFullYear() - 1,
    table: lastYearsTable,
    setItems: data => lastYearsTable.replaceItems(data.lastYear),
    setOptions: data => (lastYearsTable.options.value = data)
  },
  {
    title: "Pending Production",
    table: pendingTable,
    setItems: data => pendingTable.replaceItems(data.pending),
    setOptions: data => (pendingTable.options.value = data)
  }
]);

function handleIntervalTransfer() {
  if (isCyclingThroughTables.value) enableTableCycling();
  if (isRefreshing.value) enableDataRefresh();
}

async function getInforce() {
  lastTimeRefreshed.value = format(new Date(), "h:mmaa");
  loading.value = true;
  try {
    const result = await getScorecardReport();
    tabs.value.forEach(tab => tab.setItems(result));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function incrementFontSizeOffset(positive = true) {
  fontSizeOffset.value += (positive ? 1 : -1) * FONT_SIZE_SCALER;
}

function toggleDataRefresh(force = false) {
  if (isRefreshing.value || force) disableDataRefresh();
  else enableDataRefresh();
}

let refreshInterval;
function disableDataRefresh() {
  clearInterval(refreshInterval);
  isRefreshing.value = false;
}

function enableDataRefresh() {
  isRefreshing.value = true;
  refreshInterval = setInterval(getInforce, 900000);
}

function enableTableCycling() {
  isCyclingThroughTables.value = true;
  refreshInterval = setInterval(cycleThroughTables, 10000);
}

function toggleTableCycling(force = false) {
  if (isCyclingThroughTables.value || force) disableTableCycling();
  else enableTableCycling();
}

let cyclingInterval;
function disableTableCycling() {
  clearInterval(cyclingInterval);
  isCyclingThroughTables.value = false;
}

function cycleThroughTables() {
  activeTab.value = (activeTab.value + 1) % tabs.value.length;
}

if (firstCreated.value) {
  firstCreated.value = true;
  getInforce();
}

onBeforeUnmount(() => {
  toggleDataRefresh(true);
  toggleTableCycling(true);
  fullscreen.value = false;
});

handleIntervalTransfer();
</script>

<style lang="scss">
@for $i from 0 through 7 {
  .inforce-font-size-#{$i} {
    h2 {
      font-size: #{1.5 + $i * 0.5}em;
    }

    .inforce-table {
      tr > th {
        font-size: #{0.75 + $i * 0.25}rem !important;
        padding-bottom: #{$i * 0.05}rem !important;
        padding-top: #{$i * 0.05}rem !important;
      }

      tr > td {
        font-size: #{0.875 + $i * 0.25}rem !important;
        padding-bottom: #{$i * 0.05}rem !important;
        padding-top: #{$i * 0.05}rem !important;
      }
    }
  }
}
</style>
