<template>
  <v-card>
    <v-card-title> Create Employee </v-card-title>
    <v-card-text>
      <agent-search
        v-model="model.ownable"
        label="New Employee"
        data-testid="ownable-search"
        :prepend-inner-icon="mdiAccountTie"
        v-bind="agentValidation"
      />
      <v-row dense>
        <v-col cols="12">
          <agent-search
            v-model="model.manager"
            label="Manager"
            data-testid="manager-search"
            :prepend-inner-icon="mdiAccountSupervisor"
            v-bind="managerValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="model.department"
            label="Department"
            data-testid="department-select"
            :prepend-inner-icon="mdiDomain"
            :items="DEPARTMENTS"
            v-bind="departmentValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="model.title"
            label="Title"
            data-testid="title-select"
            :items="titles"
            :disabled="!model.department"
            :prepend-inner-icon="mdiFormatTitle"
            v-bind="titleValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="model.contractType"
            label="Contract Type"
            data-testid="contract-type-select"
            :prepend-inner-icon="mdiCalculator"
            :items="CONTRACT_TYPES"
            v-bind="contractTypeValidation"
          />
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="model.maxCases"
            mask="####"
            label="Max Cases (Optional)"
            clearable
            hide-details="auto"
            data-testid="max-cases-input"
            :prepend-inner-icon="mdiBriefcase"
            v-bind="maxCasesValidation"
          />
        </v-col>
      </v-row>
      <date-input
        v-model="model.anniversary"
        label="BackNine Anniversary"
        data-testid="anniversary-input"
        v-bind="anniversaryValidation"
      />
      <date-input
        v-if="requirements.birthdate"
        v-model="model.birthdate"
        label="Birthdate"
        data-testid="birthdate-input"
        :loading="checkingRequirements"
        v-bind="birthdateValidation"
      />
      <phone-input
        v-if="requirements.phoneMobile"
        v-model="model.phoneMobile"
        label="Mobile"
        data-testid="phone-mobile-input"
        :loading="checkingRequirements"
        :prepend-inner-icon="mdiCellphone"
        v-bind="phoneMobileValidation"
      />
      <file-drag-and-drop
        v-if="requirements.resumé"
        v-model="model.resumé"
        label="Resumé"
        data-testid="resumé"
        v-bind="resuméValidation"
      />
      <file-drag-and-drop
        v-if="requirements.employmentAgreement"
        v-model="model.employmentAgreement"
        label="Employment Agreement"
        data-testid="employment-agreement"
        v-bind="employmentAgreementValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create-button"
        :loading="saving"
        @click="create"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import AgentSearch from "@/components/shared/AgentSearch.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import {
  mdiDomain,
  mdiCellphone,
  mdiFormatTitle,
  mdiCalculator,
  mdiBriefcase,
  mdiAccountTie,
  mdiAccountSupervisor
} from "@mdi/js";

import { computed, ref, watch } from "vue";
import {
  NewEmployee,
  DEPARTMENTS,
  CONTRACT_TYPES,
  CONTRACT_TYPE,
  getTitlesForDepartment,
  setRequestBodyFromNewEmployee
} from "@/models/Employee";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import { useDialogStore } from "@/stores/dialog";
import { createEmployee } from "@/api/employees.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { getAgentSettings } from "@/api/agents.service";
import { DOCUMENT_CATEGORY } from "@/models/Document";
import { uploadAgentDocument } from "@/api/agents.service";
import { useRouter } from "vue-router";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const router = useRouter();

const model = ref(NewEmployee());
const sourceAgent = ref(null);
const saving = ref(false);
const checkingRequirements = ref(false);

const requirements = computed(() => {
  const isNotRequired =
    !model.value.ownable?.id ||
    checkingRequirements.value ||
    !sourceAgent.value ||
    model.value.department === CONTRACT_TYPE.OUTSOURCED_EMPLOYEE;

  if (isNotRequired) {
    return {
      birthdate: false,
      phoneMobile: false,
      resumé: false,
      employmentAgreement: false
    };
  }

  const agentDocuments = Object.values(sourceAgent.value.relatedDocuments);

  let foundResumé = false,
    foundEmploymentAgreement = false;

  for (const doc of agentDocuments) {
    if (doc.category === DOCUMENT_CATEGORY.RESUME) {
      foundResumé = true;
    } else if (doc.category === DOCUMENT_CATEGORY.EMPLOYMENT_AGREEMENT) {
      foundEmploymentAgreement = true;
    }
  }

  return {
    birthdate: !sourceAgent.value.birthdate,
    phoneMobile: !sourceAgent.value.phoneMobile,
    resumé: !foundResumé,
    employmentAgreement: !foundEmploymentAgreement
  };
});

const v$ = useVuelidate(
  {
    model: {
      title: { required: Boolean },
      department: { required: Boolean },
      anniversary: { required: Boolean },
      manager: { required: v => Boolean(v?.id) },
      ownable: { required: v => Boolean(v?.id) },
      contractType: { required: Boolean },
      phoneMobile: {
        required: v => !requirements.value.phoneMobile || Boolean(v)
      },
      birthdate: { required: v => !requirements.value.birthdate || Boolean(v) },
      maxCases: {
        required: v => [null, undefined].includes(v) || (v >= 1 && v <= 2000)
      },
      resumé: {
        required: v => !requirements.value.resumé || Boolean(v)
      },
      employmentAgreement: {
        required: v => !requirements.value.employmentAgreement || Boolean(v)
      }
    }
  },
  { model },
  { $autoDirty: true, $scope: null }
);

const titleValidation = computedValidation(v$.value.model.title, {
  required: "Required"
});
const departmentValidation = computedValidation(v$.value.model.department, {
  required: "Required"
});
const anniversaryValidation = computedValidation(v$.value.model.anniversary, {
  required: "Required"
});
const managerValidation = computedValidation(v$.value.model.manager, {
  required: "Required"
});
const agentValidation = computedValidation(v$.value.model.ownable, {
  required: "Required"
});
const phoneMobileValidation = computedValidation(v$.value.model.phoneMobile, {
  required: "Required"
});
const contractTypeValidation = computedValidation(v$.value.model.contractType, {
  required: "Required"
});
const maxCasesValidation = computedValidation(v$.value.model.maxCases, {
  required: "Required"
});
const birthdateValidation = computedValidation(v$.value.model.birthdate, {
  required: "Required"
});
const resuméValidation = computedValidation(v$.value.model.resumé, {
  required: "Required"
});
const employmentAgreementValidation = computedValidation(
  v$.value.model.employmentAgreement,
  { required: "Required" }
);

const titles = computed(() => {
  if (!model.value.department) return [];
  return getTitlesForDepartment(model.value.department);
});

async function create() {
  const isValid = await v$.value.$validate();
  console.log(v$.value);
  if (!isValid) return;
  try {
    saving.value = true;
    const body = setRequestBodyFromNewEmployee(model.value);
    if (!requirements.value.birthdate) delete body.birthdate;
    if (!requirements.value.phoneMobile) delete body.phone_mobile;

    if (requirements.value.employmentAgreement) {
      await uploadAgentDocument(
        sourceAgent.value.id,
        model.value.employmentAgreement,
        DOCUMENT_CATEGORY.EMPLOYMENT_AGREEMENT
      );
    }

    if (requirements.value.resumé) {
      await uploadAgentDocument(
        sourceAgent.value.id,
        model.value.resumé,
        DOCUMENT_CATEGORY.RESUME
      );
    }

    const newEmployee = await createEmployee(body);
    await router.push({ name: "EmployeeView", params: { id: newEmployee.id } });
    dialog.closeDialog({ refresh: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function checkDataRequirements() {
  if (!model.value.ownable?.id) return;
  try {
    checkingRequirements.value = true;
    sourceAgent.value = await getAgentSettings(model.value.ownable.id);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    checkingRequirements.value = false;
  }
}

watch(
  () => model.value.department,
  () => {
    model.value.title = null;
  }
);

watch(() => model.value.ownable?.id, checkDataRequirements);
</script>
