<template>
  <autocomplete-field
    v-model="item"
    v-bind="$attrs"
    autocomplete="false"
    :prepend-inner-icon="mdiBookOpenPageVariant"
    placeholder="Search Vendors"
    label="Vendor"
    item-title="name"
    return-object
    hide-no-data
    :data-testid="dataTestid"
    :items="items"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { getVendors } from "@/api/vendors.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiBookOpenPageVariant } from "@mdi/js";
import { ref, toRef, watch } from "vue";

const props = defineProps({
  dataTestid: {
    type: String,
    default: null
  },
  modelValue: {
    type: Object,
    default: null
  },
  lines: {
    type: Array,
    default: null
  }
});

const emit = defineEmits(["update:model-value", "blur"]);
const snackbar = useSnackbarStore();

const modelValueProp = toRef(props, "modelValue");

const items = ref([]);
const item = ref(null);
const loading = ref(false);

if (props.modelValue?.id) {
  items.value.push(props.modelValue);
  // eslint-disable-next-line vue/no-setup-props-destructure
  item.value = props.modelValue;
}

watch(modelValueProp, v => {
  items.value.splice(0, items.value.lengthj);
  if (!v?.id) {
    item.value = null;
    return;
  }
  item.value = v;
  items.value.push(v);
});

watch(item, v => emit("update:model-value", v));

async function fetchVendors() {
  try {
    loading.value = true;
    const vendors = await getVendors();
    items.value.splice(0, items.value.length);
    items.value.push(
      ...vendors.toSorted((a, b) => a.name.localeCompare(b.name))
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

fetchVendors();
</script>
