<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-row align="center">
          <v-col>
            <h1 class="text-h5 text-black">Your Quote & Apply Websites</h1>
          </v-col>
          <v-spacer class="hidden-md-and-up" />
          <v-col cols="12" md="6">
            <v-row class="ma-0">
              <text-field
                v-model="searchText"
                data-lpignore="true"
                class="mr-3"
                label="Search for a Quote & Apply Website"
                hide-details
                clearable
                data-testid="search"
                :prepend-inner-icon="mdiMagnify"
              />
              <slot name="header" />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-row class="ma-0">
          <v-col
            v-for="item in currentSiteChunk"
            :key="item.key"
            cols="12"
            md="4"
            lg="3"
            sm="6"
          >
            <v-card
              v-if="item.id"
              :to="{
                name: 'ApprovedDomains',
                params: { id: item.id }
              }"
              min-height="256"
              style="overflow: hidden; position: relative"
              variant="outlined"
              data-testid="approved-domain-card"
            >
              <v-card-title data-testid="approved-domain-title">{{
                item.domain
              }}</v-card-title>
              <v-card-text data-testid="approved-domain-text">
                Advisor: {{ item.advisorName }}
                <br />
                Marketing As: {{ item.marketingAsName }}
                <br />
                Start Page: {{ formatStartPage(item.startPage) }}
              </v-card-text>
              <div v-if="item.avatarUrl" class="domain-text">
                <v-img
                  :src="item.avatarUrl"
                  style="opacity: 0.06"
                  height="100%"
                  width="100%"
                  contain
                />
              </div>
              <div v-else class="domain-text-no-avatar">
                {{ item.domain }}
              </div>
              <v-card-actions style="position: absolute; bottom: 0; right: 0">
                <v-tooltip location="bottom">
                  <template #activator="{ props: templateProps }">
                    <app-button
                      v-bind="templateProps"
                      :icon="mdiOpenInNew"
                      variant="text"
                      density="comfortable"
                      data-testid="go-to-website"
                      color="primary"
                      class="text-none"
                      :href="`//${item.domain}`"
                      target="_blank"
                    />
                  </template>
                  <span>Launch Quote & Apply</span>
                </v-tooltip>
                <v-tooltip v-if="item?.id" location="bottom">
                  <template #activator="{ props: templateProps }">
                    <app-button
                      v-bind="templateProps"
                      :icon="mdiCog"
                      variant="text"
                      density="comfortable"
                      color="accent"
                      class="text-none"
                      data-testid="website-settings"
                      :to="{
                        name: 'ApprovedDomains',
                        params: { id: item.id }
                      }"
                    />
                  </template>
                  <span>Website Settings</span>
                </v-tooltip>
                <v-tooltip location="bottom">
                  <template #activator="{ props: templateProps }">
                    <app-button
                      v-bind="templateProps"
                      :icon="mdiCodeTags"
                      color="orange"
                      density="comfortable"
                      variant="text"
                      class="text-none"
                      data-testid="quote-and-apply-snippet"
                      :disabled="!item.domainKey"
                      @click.prevent="codeDialog(item)"
                    />
                  </template>
                  <span>
                    {{ item.domainKey ? "View Embedded Code" : "Pending" }}
                  </span>
                </v-tooltip>
                <v-tooltip location="bottom">
                  <template #activator="{ props: templateProps }">
                    <app-button
                      v-bind="templateProps"
                      :icon="mdiDelete"
                      density="comfortable"
                      variant="text"
                      color="error"
                      class="text-none"
                      data-testid="delete-website"
                      @click.prevent="deleteWebsite(item)"
                    />
                  </template>
                  <span>Delete Website</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>

            <v-card v-else variant="outlined">
              <v-skeleton-loader type="card" />
            </v-card>
          </v-col>
          <v-col v-if="siteChunkCount > 1" cols="12" data-testid="pagination">
            <v-pagination
              v-model="activeSitePage"
              :length="siteChunkCount"
              :total-visible="7"
            />
          </v-col>

          <template
            v-else-if="!chunksLoading.length && !dataCardLoadedElements.length"
          >
            <p v-if="loaded" class="pa-3">No results</p>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import CopyDialog from "@/dialogs/CopyDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { startPageItems } from "@/data/filter-options";
import { parseErrorMessage } from "@/util/helpers";
import {
  archiveApprovedDomain,
  getApprovedDomains
} from "@/api/approved-domain.service";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  mdiMagnify,
  mdiOpenInNew,
  mdiCog,
  mdiCodeTags,
  mdiDelete
} from "@mdi/js";
import { computed, markRaw, ref, watch } from "vue";

import { useDialogStore } from "@/stores/dialog";
import { useDisplay } from "vuetify";
import { getHttpClient } from "@/http-client";

const CHUNK_SIZE = 20;

const { md, sm, xs } = useDisplay();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const dataCardLoadedElements = ref([null, null, null, null]);
const chunksLoading = ref([]);
const activeSitePage = ref(1);
const meta = ref({});
const loaded = ref(false);
const searchText = ref("");

const chunkSize = computed(() => {
  if (md.value) {
    return 3;
  } else if (sm.value) {
    return 2;
  } else if (xs.value) {
    return 1;
  } else {
    return 4;
  }
});

const siteChunkCount = computed(() =>
  Math.ceil(meta.value.total / chunkSize.value)
);
const currentSiteChunk = computed(() => {
  if (!dataCardLoadedElements.value) return [];
  const offset = (activeSitePage.value - 1) * chunkSize.value;
  return dataCardLoadedElements.value
    .slice(offset, offset + chunkSize.value)
    .map((v, index) => {
      if (!v) return { key: `loader-${index}` };
      return { ...v, key: v.id };
    });
});

let timer;
watch(searchText, () => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    dataCardLoadedElements.value = [null, null, null, null];
    chunksLoading.value = [];
    activeSitePage.value = 1;
    loaded.value = false;
    meta.value = { total: 4 };
    getData();
  }, 300);
});

watch(currentSiteChunk, async () => {
  if (!loaded.value) return;
  const index = currentSiteChunk.value.findIndex(val => !val?.id);
  if (index === -1) return null;
  const itemIndex = chunkSize.value * activeSitePage.value + index - 1;
  const requestedChunk = Math.ceil(itemIndex / CHUNK_SIZE);
  if (chunksLoading.value.includes(requestedChunk)) return;
  if (requestedChunk) chunksLoading.value.push(requestedChunk);

  await getData(requestedChunk);
  const chunkIndex = chunksLoading.value.findIndex(v => v === requestedChunk);

  chunksLoading.value.splice(chunkIndex, 1);
});

function deleteWebsite(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Do you want to delete the website ${item.domain}?`,
    subtitle:
      "This cannot be undone and will cause the website to no longer work",
    func: async () => {
      await archiveApprovedDomain(item.id);
      snackbar.showSuccessSnackbar({
        message: `Successfully deleted ${item.domain}`,
        timeout: 5000
      });
      const index = dataCardLoadedElements.value.findIndex(
        v => v?.id === item.id
      );
      dataCardLoadedElements.value.splice(index, 1);
      meta.value.total--;

      if (currentSiteChunk.value.length > 0 || meta.value.total == 0) return;
      if (activeSitePage.value === 0) activeSitePage.value++;
      activeSitePage.value--;
    }
  });
}

let cancelToken = null;
async function getData(chunk = 1) {
  const urlSearch = new URLSearchParams();
  urlSearch.append("page", chunk.toString());
  urlSearch.append("count", CHUNK_SIZE.toString());
  urlSearch.append("view", "my");
  if (searchText.value) {
    urlSearch.append("filter[domain]", searchText.value);
  }
  try {
    if (cancelToken !== null) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = getHttpClient().CancelToken.source();

    const res = await getApprovedDomains(urlSearch, cancelToken);
    meta.value = res.meta;
    if (!dataCardLoadedElements.value.filter(v => v?.id).length) {
      dataCardLoadedElements.value = new Array(meta.value?.total).fill(null);
    }

    res.items.forEach((element, index) => {
      dataCardLoadedElements.value.splice(
        (chunk - 1) * CHUNK_SIZE + index,
        1,
        element
      );
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loaded.value = true;
  }
}

function formatStartPage(value) {
  return startPageItems.find(i => i.value === value)?.title;
}

function codeDialog(item) {
  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: item.htmlSnippet,
    title: "Quote & Apply Snippet",
    subtitle: "Just copy and paste this into your site!"
  });
}
getData();
</script>

<style scoped>
.domain-text-no-avatar {
  left: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 256px;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.06);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.domain-text {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 256px;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.06);
  font-weight: 400;
}
</style>
