<template>
  <v-card>
    <v-card-title> Permit Email </v-card-title>
    <v-card-text>
      <text-field
        v-model="email"
        v-bind="emailValidation"
        data-testid="permit-email"
        label="Email"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        text="Cancel"
        variant="outlined"
        class="text-none"
        @click="dialog.closeDialog()"
      />
      <app-button
        text="Permit"
        color="primary"
        class="text-none"
        data-testid="permit-email-save"
        :loading="saving"
        @click="addToList"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import { ref } from "vue";
import { email as emailValidator } from "@/util/vuelidateValidators";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { addToEmailExceptionList } from "@/api/email-exception-list.service";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const email = ref("");
const saving = ref(false);
const v$ = useVuelidate(
  {
    email: {
      required: v => emailValidator(v)
    }
  },
  { email },
  { $scope: null, $autoDirty: true }
);

const emailValidation = computedValidation(v$.value.email, {
  required: "Required"
});

async function addToList() {
  saving.value = true;
  try {
    await addToEmailExceptionList(email.value);
    dialog.closeDialog({ created: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
