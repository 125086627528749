<template>
  <v-data-table
    class="transparent-data-table"
    data-testid="admin-documents-table"
    disable-pagination
    hide-default-footer
    disable-sort
    disable-filtering
    :mobile="null"
    mobile-breakpoint="sm"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    :items-per-page="-1"
  >
    <template v-if="user.isGroupTwoPlus" #top>
      <div>
        <app-button
          class="text-none"
          color="accent"
          data-testid="upload-new-document"
          @click="showUploadDocumentDialog"
        >
          <v-icon :icon="mdiPlus" /> Upload Document
        </app-button>
      </div>
    </template>

    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :model-value="item.createdAt" />
    </template>

    <template #[`item.documentActions`]="{ item }">
      <div class="row">
        <app-button
          class="text-none"
          color="primary"
          variant="text"
          density="comfortable"
          :icon="mdiDownload"
          data-testid="download-document"
          v-bind="downloadDocument(item)"
        />
        <app-button
          v-if="user.isGroupTwoPlus"
          class="text-none"
          color="error"
          variant="text"
          density="comfortable"
          :icon="mdiDelete"
          data-testid="delete-document"
          :disabled="!item.additional.canDelete"
          @click="deleteRow(item)"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { downloadFileAsLink } from "@/util/helpers";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useTable } from "@/composables/table.composable";

import { markRaw } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { useUserStore } from "@/stores/user";
import { mdiPlus, mdiDownload, mdiDelete } from "@mdi/js";
import { useDisplay } from "vuetify";

const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
let store;

const user = useUserStore();
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}
const headers = [
  new TableHeader({ text: "Name", value: "name", map: "name" }),
  new TableHeader({
    text: "Publish Date",
    value: "createdAt",
    map: "createdAt"
  })
];

if (!props.readonly) {
  headers.push(
    new TableHeader({
      text: "Actions",
      value: "documentActions",
      map: ""
    })
  );
}

const table = useTable({
  headers
});

const { mdAndDown } = useDisplay();
const dialog = useDialogStore();
async function showUploadDocumentDialog() {
  await dialog.showDialog({
    component: markRaw(UploadDocumentDialog),
    uploadFunction: async ({ file: files }) => {
      await Promise.allSettled(files.map(f => store.uploadDocument(f)));
      updateTable();
    }
  });
}

function downloadDocument(item) {
  return downloadFileAsLink(
    getDocumentUrl(item.additional.uid),
    mdAndDown.value
  );
}

async function deleteRow(item) {
  await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Remove document ${item.name} from this Advisor?`,
    subtitle: "This cannot be undone",
    func: () => store.deleteRelatedDocument(item.additional.uid)
  });
  updateTable();
}

function updateTable() {
  table.items.value = Object.values(store.relatedDocuments);
}

updateTable();
</script>
