<template>
  <autocomplete-field
    v-model="model"
    v-model:search="searchText"
    autocomplete="false"
    :prepend-inner-icon="mdiCube"
    placeholder="Search Tasks"
    label="Task"
    item-title="text"
    return-object
    no-filter
    hide-no-data
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="items"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { getProvisioningTasks } from "@/api/employee-provisioning-tasks.service";
import { mdiCube } from "@mdi/js";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { toRefs, watch, ref } from "vue";

const props = defineProps({
  success: Boolean,
  stage: { type: String, required: true },
  errorMessages: { type: Array, default: () => [] },
  modelValue: { type: [Object, String], default: () => {} },
  disabled: Boolean,
  hideDetails: Boolean
});

const snackbar = useSnackbarStore();
const { modelValue } = toRefs(props);

const emit = defineEmits(["update:model-value", "blur"]);

const items = ref([]);
const model = ref(null);
const searchText = ref(null);
const loading = ref(false);

if (props.modelValue?.id) {
  items.value = [props.modelValue];
  // eslint-disable-next-line vue/no-setup-props-destructure
  model.value = props.modelValue;
}

watch(modelValue, value => {
  items.value.splice(0, items.value.length);
  if (value?.id) {
    model.value = value;
    items.value.push(value);
    return;
  }
  model.value = null;
});

watch(model, v => emit("update:model-value", v));

let timer;
watch(searchText, value => {
  if (!value) return;

  if (items.value.some(v => v?.name === value) && items.value.length === 1) {
    return;
  }

  if (timer) clearTimeout(timer);

  timer = setTimeout(() => search(value), 200);
});

async function search(v) {
  loading.value = true;
  try {
    const res = await getProvisioningTasks({
      text: v,
      page: "1",
      count: "10",
      stage: props.stage
    });
    if (!res) return;
    items.value.splice(0, items.value.length);
    items.value.push(...res.items);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
