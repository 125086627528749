<template>
  <v-menu offset-y :top="text">
    <template #activator="{ props: menu }">
      <v-tooltip location="bottom" :disabled="text">
        <template #activator="{ props: tooltip }">
          <app-button
            v-bind="{ ...menu, ...tooltip }"
            :icon="mdiPlaylistPlus"
            :class="{ rounded: text }"
            variant="text"
            class="opacity-60"
            data-testid="model-create-menu"
            :style="width ? `width: ${width}` : null"
            :color="color || 'inherit'"
          />
        </template>
        <span>Create Menu </span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="object in models"
        :key="object.value"
        :data-testid="`model-create-menu-item-${object.text}`"
        v-bind="object.props"
        :prepend-icon="object.icon"
        :title="object.text"
      />
    </v-list>
  </v-menu>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { PERMISSION } from "@/constants/permissions.constants";
import {
  mdiAccount,
  mdiAccountCircle,
  mdiBarcode,
  mdiBookInformationVariant,
  mdiBriefcase,
  mdiBriefcaseVariant,
  mdiCalendar,
  mdiCashRegister,
  mdiChartLine,
  mdiClipboardAccount,
  mdiFileDocument,
  mdiFileDocumentMultiple,
  mdiHumanGreeting,
  mdiMagnetOn,
  mdiNewspaper,
  mdiPlaylistPlus,
  mdiPointOfSale
} from "@mdi/js";
import { markRaw } from "vue";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import { mergePdfs } from "@/api/pdf-tools.service";
import { useDialogStore } from "@/stores/dialog";
defineProps({
  width: { type: String, required: false, default: null },
  color: { type: String, required: false, default: null },
  text: Boolean
});

const dialog = useDialogStore();
const userStore = useUserStore();

const models = [
  {
    text: "Advisor",
    icon: mdiAccount,
    props: { to: { name: "AdvisorCreate" } }
  },
  {
    text: "PDF Merge/Unlock",
    icon: mdiFileDocumentMultiple,
    props: {
      onClick: () =>
        dialog.showDialog({
          component: markRaw(UploadDocumentDialog),
          title: "Merge and Unlock PDFs",
          subtitle:
            "Upload one or more PDFs and merge them into one. Or unlock non-password protected PDFs",
          accept: ".pdf",
          single: false,
          uploadFunction: async ({ file }) => {
            const blob = await mergePdfs(file);
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
    }
  }
];

if (userStore.isGroupTwoPlus) {
  models.push(
    {
      text: "Appointment",
      icon: mdiCalendar,
      props: { to: { name: "AppointmentCreate" } }
    },
    {
      text: "Case",
      icon: mdiBriefcaseVariant,
      props: { to: { name: "CaseCreate" } }
    },
    {
      text: "Informal Inquiry",
      icon: mdiBookInformationVariant,
      props: { to: { name: "CaseCreateInformal" } }
    },
    {
      text: "Lead",
      icon: mdiMagnetOn,
      props: { to: { name: "LeadCreate" } }
    },
    {
      text: "Personnel",
      icon: mdiAccountCircle,
      props: { to: { name: "PersonnelCreate" } }
    }
  );
}

if (userStore.permissions[PERMISSION.ACCOUNTING]) {
  models.push(
    {
      text: "Payment",
      icon: mdiCashRegister,
      props: { to: { name: "PaymentCreate" } }
    },
    {
      text: "Product",
      icon: mdiBarcode,
      props: { to: { name: "ProductCreate" } }
    },
    {
      text: "Statement",
      icon: mdiNewspaper,
      props: { to: { name: "StatementCreate" } }
    },
    {
      text: "Transaction",
      icon: mdiPointOfSale,
      props: { to: { name: "TransactionCreate" } }
    }
  );
}

if (userStore.loginable?.show_backnine) {
  models.push(
    {
      text: "Application Request",
      icon: mdiFileDocument,
      props: { to: { name: "ApplicationRequest" } }
    },
    {
      text: "Client",
      icon: mdiHumanGreeting,
      props: { to: { name: "ContractPartyCreate" } }
    },
    {
      text: "Impaired Risk Quote",
      icon: mdiChartLine,
      props: { to: { name: "ImpairedRiskQuoteCreate" } }
    },
    {
      text: "Submit Case",
      icon: mdiBriefcase,
      props: { to: { name: "CaseSubmission" } }
    },
    {
      text: "Quote Request",
      icon: mdiClipboardAccount,
      props: { to: { name: "CreateQuote" } }
    }
  );
}

models.sort((a, b) => a.text.localeCompare(b.text));
</script>
