import { Payor, setPayorFromRequest } from "@/models/Payor";
import { setCompBuilderFromRequest } from "@/models/CompBuilder";
import { setDocumentFromRequest, Document } from "@/models/Document";
import { Training, setTrainingFromRaw } from "@/models/Training";

export const LINES = [
  { title: "Annuity", value: "annuity" },
  { title: "Disability", value: "disability" },
  { title: "Linked Benefit", value: "linked_benefit" },
  { title: "Life", value: "life" },
  { title: "LTC", value: "ltc" },
  { title: "Variable Life", value: "variable_life" }
];

export const LINE_PERCENTAGES = [5, 50, 100];

export function Product(model = {}) {
  return {
    activeYears: model?.activeYears || null,
    advisorGuide: model?.advisorGuide || null,
    advisorUseOnly: model?.advisorUseOnly || null,
    ageNearest: model?.ageNearest || null,
    carrier: Carrier(model?.carrier),
    chargeback: model?.chargeback || null,
    chronicIllnessRider: model?.chronicIllnessRider || null,
    compBuilders: model?.compBuilders || [],
    consumerGuide: model?.consumerGuide || null,
    conversionText: model?.conversionText || null,
    conversion: model?.conversion || null,
    criticalIllnessRider: model?.criticalIllnessRider || null,
    documents: model?.documents || [],
    eDelivery: model?.eDelivery || null,
    engines: model?.engines || null,
    firstYearCommission: model?.firstYearCommission || null,
    formSet: model?.formSet || [],
    freeWithdrawals: model?.freeWithdrawals || null,
    guides: model?.guides || [],
    id: model?.id || null,
    instantAps: model?.instantAps || false,
    firelightProductCode: model?.firelightProductCode || null,
    line: model?.line || null,
    linePercentage: model?.linePercentage || null,
    limitToPreferredAge: Boolean(model?.limitToPreferredAge),
    limitToStandardAge: Boolean(model?.limitToStandardAge),
    ltcRider: model?.ltcRider || null,
    maxAge: model?.maxAge || null,
    maxDeathBenefit: model?.maxDeathBenefit || null,
    maxPayout: model?.maxPayout || null,
    maxPremium: model?.maxPremium || null,
    minAge: model?.minAge || null,
    minDeathBenefit: model?.minDeathBenefit || null,
    minPremium: model?.minPremium || null,
    name: model?.name || null,
    nameWithAge: model?.nameWithAge || null,
    noCostChronicIllnessRider: model?.noCostChronicIllnessRider || null,
    noCostCriticalIllnessRider: model?.noCostCriticalIllnessRider || null,
    notes: model?.notes || [],
    parameters: model?.parameters || "",
    payDuration: model?.payDuration || [],
    payorOptions: model?.payorOptions || [],
    payouts: model?.payouts || [],
    planType: model?.planType || null,
    primaryProduct: model?.primaryProduct || null,
    productBuildCharts: model?.productBuildCharts || [],
    productCitizenshipProfiles: model?.productCitizenshipProfiles || [],
    productSmokerCharts: model?.productSmokerCharts || [],
    rollingTarget: model?.rollingTarget || null,
    schedules: model?.schedules || new Schedules(model?.schedules),
    section1035Exchange: model?.section1035Exchange || null,
    showConversion: model?.showConversion || false,
    solves: model?.solves || [],
    streetExcess: model?.streetExcess || null,
    streetFirst: model?.streetFirst || null,
    streetPua: model?.streetPua || null,
    streetRenewal: model?.streetRenewal || null,
    strifeEnabled: model?.strifeEnabled || null,
    surrenderPeriod: model?.surrenderPeriod || null,
    temporaryInsuranceProfileProducts:
      model?.temporaryInsuranceProfileProducts || [],
    totalExcess: model?.totalExcess || null,
    totalFirst: model?.totalFirst || null,
    totalPua: model?.totalPua || null,
    totalRenewal: model?.totalRenewal || null,
    training: model?.training ? Training(model?.training) : null,
    traits: model?.traits || null,
    type: model?.type || null,
    underwritingGuide: model?.underwritingGuide || null,
    versions: model?.versions || [],
    vitalityRider: model?.vitalityRider || null
  };
}

export function setProductFromRequest(raw = {}) {
  const model = Product();
  const percentOrNull = (val, fractionDigits = 2) => {
    if (val === null) return null;
    return (Number(val) * 100).toFixed(fractionDigits) || null;
  };
  const numberOrNull = val => {
    if (val || val === 0) return val;
    return null;
  };
  model.payorOptions = raw?.payor_options?.map(val => ({
    id: val.id,
    name: val.name,
    payableType: val.payableType
  }));

  model.activeYears = raw?.active_years;
  model.advisorUseOnly = raw?.advisor_use_only;
  model.ageNearest = raw?.age_nearest;
  model.carrier = setCarrierFromRequest(raw?.carrier);
  model.chargeback = raw?.chargeback;
  model.chronicIllnessRider = raw?.chronic_illness_rider;
  model.conversion = raw?.conversion;
  model.conversionText = raw?.conversion_text;
  model.criticalIllnessRider = raw?.critical_illness_rider;

  model.documents = raw?.documents?.map(setDocumentFromRequest);
  model.eDelivery = raw?.carrier_e_delivery;
  model.firstYearCommission = raw?.first_year_commission;
  model.formSet = raw?.form_set?.id;
  model.freeWithdrawals = raw?.free_withdrawals;
  model.id = raw?.id;
  model.firelightProductCode = raw?.firelight_product_code;
  model.limitToPreferredAge = Boolean(raw?.limit_to_preferred_age);
  model.limitToStandardAge = Boolean(raw?.limit_to_standard_age);
  model.instantAps = raw?.instant_aps;
  model.line = raw?.line;
  model.linePercentage = +percentOrNull(raw?.line_percentage, 0);
  model.ltcRider = raw?.ltc_rider;
  model.maxAge = raw?.max_age;
  model.maxDeathBenefit = numberOrNull(raw?.max_death_benefit);
  model.maxPayout = raw?.max_payout;
  model.maxPremium = numberOrNull(raw?.max_premium);
  model.minAge = raw?.min_age;
  model.minDeathBenefit = numberOrNull(raw?.min_death_benefit);
  model.minPremium = numberOrNull(raw?.min_premium);
  model.name = raw?.name;
  model.nameWithAge = raw?.name_with_age;
  model.noCostChronicIllnessRider = raw?.no_cost_chronic_illness_rider;
  model.noCostCriticalIllnessRider = raw?.no_cost_critical_illness_rider;
  model.planType = raw?.plan_type;
  model.primaryProduct = raw?.primary_product;
  model.rollingTarget = raw?.rolling_target;
  model.schedules = setSchedulesFromRequest(raw?.schedules);
  model.section1035Exchange = raw?.section_1035_exchange;
  model.showConversion = raw?.show_conversion;
  model.streetExcess = percentOrNull(raw?.street_excess);
  model.streetFirst = percentOrNull(raw?.street_first);
  model.streetPua = percentOrNull(raw?.street_pua);
  model.streetRenewal = percentOrNull(raw?.street_renewal);
  model.strifeEnabled = raw?.strife_enabled;
  model.surrenderPeriod = raw?.surrender_period;
  model.totalExcess = percentOrNull(raw?.total_excess);
  model.totalFirst = percentOrNull(raw?.total_first);
  model.totalPua = percentOrNull(raw?.total_pua);
  model.totalRenewal = percentOrNull(raw?.total_renewal);
  if (raw?.training) model.training = setTrainingFromRaw(raw.training);
  model.traits = raw?.traits;
  model.type = raw?.type;
  model.versions = raw?.versions;
  model.vitalityRider = raw?.vitality_rider;

  if (Array.isArray(raw?.payouts)) {
    model.payouts = raw.payouts.map(setPayoutFromRequest);
  }
  if (Array.isArray(raw?.solves)) model.solves = raw.solves;
  if (Array.isArray(raw?.pay_duration)) model.payDuration = raw.pay_duration;
  if (Array.isArray(raw?.product_build_charts)) {
    model.productBuildCharts = raw.product_build_charts.map(
      setProductBuildChartFromRequest
    );
  }

  if (Array.isArray(raw?.product_citizenship_profiles)) {
    model.productCitizenshipProfiles = raw.product_citizenship_profiles.map(
      setProductCitizenshipProfileFromRequest
    );
  }

  if (Array.isArray(raw?.product_smoker_charts)) {
    model.productSmokerCharts = raw.product_smoker_charts.map(
      setProductSmokerChartFromRequest
    );
  }

  if (Array.isArray(raw?.guides)) {
    model.guides = raw.guides.map(setProductAdvisorGuideFromRequest);
  }

  if (Array.isArray(raw?.temporary_insurance_profiles)) {
    model.temporaryInsuranceProfileProducts =
      raw.temporary_insurance_profiles.map(
        setTemporaryInsuranceProfileFromRequest
      );
  }

  if (Array.isArray(raw?.carrier?.comp_builders)) {
    model.compBuilders = raw.carrier.comp_builders.map(
      setCompBuilderFromRequest
    );
  }
  model.advisorGuide = model.guides.find(g => g.id === raw?.advisor_guide?.id);
  model.consumerGuide = model.guides.find(
    g => g.id === raw?.consumer_guide?.id
  );
  model.underwritingGuide = model.guides.find(
    g => g.id === raw?.underwriting_guide?.id
  );

  return model;
}

function Carrier(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    avatarUrl: model?.avatarUrl || null,
    instantAps: model?.instantAps || null
  };
}

function setCarrierFromRequest(raw = {}) {
  const model = Carrier();
  model.id = raw?.id;
  model.name = raw?.name;
  model.avatarUrl = raw?.avatar_url;
  model.instantAps = raw?.instant_aps;
  return model;
}

export function Schedules(model = {}) {
  return {
    onyx: model?.onyx || null,
    diamond: model?.diamond || null,
    gold: model?.gold || null,
    platinum: model?.platinum || null,
    silver: model?.silver || null,
    bronze: model?.bronze || null
  };
}

function setSchedulesFromRequest(raw = {}) {
  const model = Schedules();

  model.onyx = raw?.onyx;
  model.diamond = raw?.diamond;
  model.gold = raw?.gold;
  model.platinum = raw?.platinum;
  model.silver = raw?.silver;
  model.bronze = raw?.bronze;

  return model;
}

export function BuildChart(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || ""
  };
}

function setBuildChartFromRequest(raw = {}) {
  const model = BuildChart();
  model.id = raw?.id;
  model.name = raw?.name;
  return model;
}

export function ProductBuildChart(model = {}) {
  return {
    buildChart: model?.buildChart ? BuildChart(model?.buildChart) : null,
    id: model?.id || null,
    productId: model?.productId || null
  };
}

export function setProductBuildChartFromRequest(raw = {}) {
  const model = ProductBuildChart();
  model.buildChart = setBuildChartFromRequest(raw?.build_chart);
  model.id = raw?.id;
  model.productId = raw?.product_id;
  return model;
}

export function ProductBuildChartToCreateRequest(productBuildChart) {
  return {
    product_build_chart: {
      build_chart_id: productBuildChart.buildChart.id,
      product_id: productBuildChart.productId
    }
  };
}

export function CitizenshipProfile(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null
  };
}

export function setCitizenshipProfileFromRequest(raw = {}) {
  const model = CitizenshipProfile();
  model.id = raw?.id;
  model.name = raw?.name;
  return model;
}

export function ProductCitizenshipProfile(model = {}) {
  return {
    id: model?.id || null,
    productId: model?.productId || null,
    citizenshipProfile: model?.citizenshipProfile
      ? CitizenshipProfile(model?.citizenshipProfile)
      : null
  };
}

export function setProductCitizenshipProfileFromRequest(raw = {}) {
  const model = ProductCitizenshipProfile();
  model.id = raw?.id;
  model.citizenshipProfile = setCitizenshipProfileFromRequest(
    raw?.citizenship_profile
  );
  return model;
}

export function ProductCitizenshipProfileToCreateRequest(model) {
  return {
    product_citizenship_profile: {
      citizenship_profile_id: model.citizenshipProfile.id,
      product_id: model.productId
    }
  };
}

export function ProductTemporaryInsuranceProfile(model = {}) {
  return {
    temporaryInsuranceProfile: TemporaryInsuranceProfile(
      model?.temporaryInsuranceProfile
    )
  };
}

export function ProductTemporaryInsuranceProfileToCreateRequest(model) {
  return {
    temporary_insurance_profile_id: model.temporaryInsuranceProfile.id
  };
}

export function TemporaryInsuranceProfile(model = {}) {
  return {
    complete: model?.complete || false,
    daysOfCoverage: model?.daysOfCoverage || null,
    id: model?.id || null,
    maxBindAgeInYears: model?.maxBindAgeInYears || null,
    maxFaceAmount: model?.maxFaceAmount || null,
    maxFaceAmountIfExistingCoverageFromSameCarrier:
      model?.maxFaceAmountIfExistingCoverageFromSameCarrier || null,
    minBindAgeInDays: model?.minBindAgeInDays || null,
    name: model?.name || null,
    maxFaceAmountToBind: model?.maxFaceAmountToBind || null
  };
}

export function setTemporaryInsuranceProfileFromRequest(raw = {}) {
  const model = TemporaryInsuranceProfile();
  model.complete = raw?.complete;
  model.daysOfCoverage = raw?.days_of_coverage;
  model.id = raw?.id;
  model.maxBindAgeInYears = raw?.max_bind_age_in_years;
  model.maxFaceAmount = raw?.max_face_amount;
  model.maxFaceAmountIfExistingCoverageFromSameCarrier =
    raw?.max_face_amount_if_existing_coverage_from_same_carrier;
  model.minBindAgeInDays = raw?.min_bind_age_in_days;
  model.maxFaceAmountToBind = raw?.max_face_amount_to_bind;
  model.name = raw?.name;
  return model;
}

export function ProductAdvisorGuide(model = {}) {
  return {
    category: model?.category,
    uid: model?.uid,
    id: model?.id,
    name: model?.name,
    createdAt: model?.createdAt
  };
}

function setProductAdvisorGuideFromRequest(raw = {}) {
  const model = ProductAdvisorGuide();
  model.category = raw?.category;
  model.uid = raw?.uid;
  model.id = raw?.id;
  model.name = raw?.name;
  model.createdAt = raw?.created_at;
  return model;
}

export function setProductAdvisorGuideFromDocument(doc = Document()) {
  const model = ProductAdvisorGuide();
  model.category = doc.category;
  model.uid = doc.uid;
  model.id = doc.id;
  model.name = doc.name;
  model.createdAt = doc.createdAt;
  return model;
}

export function Payout(model = {}) {
  return {
    id: model?.id || null,
    payor: model?.payor ? Payor(model?.payor) : null,
    endYear: model?.endYear || null,
    percent: model?.percent || null,
    startYear: model?.startYear || null,
    compBuilder: model?.compBuilder || null,
    paymentType: model?.paymentType || null,
    commissionType: model?.commissionType || null
  };
}

export function setPayoutFromRequest(model = {}) {
  const payout = Payout();
  payout.commissionType = model?.commission_type;
  payout.compBuilder = setCompBuilderFromRequest(model?.comp_builder);
  payout.endYear = model?.end_year;
  payout.percent = model?.percent;
  payout.startYear = model?.start_year;
  payout.id = model?.id;

  payout.payor = setPayorFromRequest(model?.payor);
  return payout;
}

export function PayoutToCreateRequest(payout, productId) {
  return {
    payout: {
      commission_type: payout.commissionType,
      comp_builder_id: payout.compBuilder.id,
      end_year: payout.endYear,
      start_year: payout.startYear,
      payor_id: payout.payor.id,
      percent: Number(payout.percent) / 100,
      product_id: productId
    }
  };
}

export function PayoutToUpdateRequest(payout) {
  return {
    payout: {
      commission_type: payout.commissionType,
      comp_builder_id: payout.compBuilder.id,
      end_year: payout.endYear,
      start_year: payout.startYear,
      payor_id: payout.payor.id,
      percent: Number(payout.percent) / 100
    }
  };
}

export function ProductCreateToCreateRequest(product) {
  const toDecimal = v => +(Number(v || 0) / 100).toFixed(6);
  return {
    active_years: +product.activeYears,
    age_nearest: Boolean(product.ageNearest),
    backnine_policy_fee: 125,
    carrier_e_delivery: Boolean(product.eDelivery),
    carrier_id: product.carrier.id,
    chargeback: product.chargeback,
    line: product.line.toLowerCase(),
    line_percentage: toDecimal(product.linePercentage),
    name: product.name,
    min_age: product.minAge,
    max_age: product.maxAge,
    primary_product: Boolean(product.primaryProduct),
    rolling_target: product.rollingTarget,
    street_excess: toDecimal(product.streetExcess),
    street_first: toDecimal(product.streetFirst),
    street_renewal: toDecimal(product.streetRenewal),
    street_pua: toDecimal(product.streetPua),
    total_excess: toDecimal(product.totalExcess),
    total_first: toDecimal(product.totalFirst),
    total_renewal: toDecimal(product.totalRenewal),
    total_pua: toDecimal(product.totalPua),
    traits: product.traits,
    type: product.type,
    surrender_period: +product.surrenderPeriod
  };
}

export function ProductCreate() {
  return {
    activeYears: null,
    ageNearest: null,
    carrier: null,
    chargeback: null,
    eDelivery: null,
    firelightProductCode: null,
    line: null,
    linePercentage: null,
    minAge: null,
    maxAge: null,
    name: null,
    policyFee: null,
    primaryProduct: null,
    rollingTarget: null,
    streetExcess: null,
    streetFirst: null,
    streetPua: null,
    streetRenewal: null,
    surrenderPeriod: null,
    totalExcess: null,
    totalFirst: null,
    totalPua: null,
    totalRenewal: null
  };
}

export function ProductSmokerChart(model = {}) {
  return {
    id: model?.id || null,
    productId: model?.productId || null,
    smokerChart: model?.smokerChart ? SmokerChart(model?.smokerChart) : null
  };
}

export function setProductSmokerChartFromRequest(raw = {}) {
  const model = ProductSmokerChart();
  model.id = raw?.id;
  model.smokerChart = setSmokerChartFromRequest(raw?.smoker_chart);
  return model;
}

export function ProductSmokerChartToCreateRequest(model = {}) {
  return {
    product_smoker_chart: {
      smoker_chart_id: model.smokerChart.id,
      product_id: model.productId
    }
  };
}

export default function SmokerChart(model = {}) {
  return {
    id: model?.id,
    name: model?.name || "",
    carrierName: model?.carrierName || "",
    complete: model?.complete || false,
    documentUid: model?.documentUid
  };
}

export function setSmokerChartFromRequest(raw = {}) {
  const model = SmokerChart();
  model.id = raw?.id;
  model.name = raw?.name;
  model.carrierName = raw?.carrier?.name;
  model.complete = raw?.complete;
  model.documentUid = raw?.document?.uid;
  return model;
}

export function ProductAlias(model = {}) {
  return {
    id: model?.id || 0,
    engine: model?.engine || "",
    parameters: model?.parameters || "",
    includedStates: model?.includedStates || [],
    excludedStates: model?.excludedStates || []
  };
}

export function setProductAliasFromRequest(raw = {}) {
  const model = ProductAlias();
  model.id = raw?.id;
  model.engine = raw?.engine;
  model.parameters = JSON.stringify(raw?.parameters);
  model.includedStates = [];
  if (raw?.included_states?.length) {
    model.includedStates.push(...raw.included_states);
  }
  model.excludedStates = [];
  if (raw?.excluded_states?.length) {
    model.excludedStates.push(...raw.excluded_states);
  }

  return model;
}
export function ProductAliasToRequest(model) {
  return {
    engine: model.engine,
    parameters: JSON.parse(model.parameters),
    included_states: model.includedStates || [],
    excluded_states: model.excludedStates || []
  };
}
export const INSTANT_APS_OPTIONS = [
  {
    title: "Use Carrier Settings",
    value: null
  },
  {
    title: "Supports Human API",
    value: true
  },
  {
    title: "Does Not Support Human API",
    value: false
  }
];

const PRODUCT_API_PAY_DURATIONS = [
  { title: "Single Pay (1)", value: 1 },
  { title: "Five Pay (5)", value: 5 },
  { title: "Ten Pay (10)", value: 10 },
  {
    title: "Pay to Age 65 (-65)",
    value: -65
  },
  {
    title: "Lifetime (-1)",
    value: -1
  }
];

for (let i = 1; i <= 125; i++) {
  if ([1, 5, 10].includes(i)) continue;
  PRODUCT_API_PAY_DURATIONS.push({ title: `${i}`, value: i });
}

export { PRODUCT_API_PAY_DURATIONS };

export const CONVERSION_ITEMS = [
  {
    value: true,
    title: "Conversion Only"
  },
  {
    value: null,
    title: "Conversion and Non Conversion"
  },
  {
    value: false,
    title: "Non Conversion Only"
  }
];
