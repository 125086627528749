import { formatBytes } from "@/util/helpers";

export const DOCUMENT_CATEGORY = {
  ADVISOR_PRODUCT_GUIDE: "Advisor Product Guide",
  CARRIER_CONTACT_LIST: "Carrier Contact List",
  CONSUMER_PRODUCT_GUIDE: "Consumer Product Guide",
  UNDERWRITING_GUIDE: "Underwriting Guide",
  OTHER: "Other",
  GROUP_TWO_PLUS_ACCESS: "Group 2+ Access",

  SIGNATURE_AUTHORIZATION: "Signature Authorization",

  RESUME: "Resume",
  EMPLOYMENT_AGREEMENT: "Employment Agreement",

  APS: "APS"
};

export const CASE_DOCUMENT_CATEGORIES = [
  { title: DOCUMENT_CATEGORY.OTHER, value: null },
  { title: DOCUMENT_CATEGORY.APS, value: DOCUMENT_CATEGORY.APS }
];

export const CARRIER_DOCUMENT_CATEGORIES = [
  DOCUMENT_CATEGORY.ADVISOR_PRODUCT_GUIDE,
  DOCUMENT_CATEGORY.CARRIER_CONTACT_LIST,
  DOCUMENT_CATEGORY.CONSUMER_PRODUCT_GUIDE,
  DOCUMENT_CATEGORY.UNDERWRITING_GUIDE,
  DOCUMENT_CATEGORY.OTHER,
  DOCUMENT_CATEGORY.GROUP_TWO_PLUS_ACCESS
];

export function Document(model = {}) {
  return {
    canDelete: model?.canDelete || null,
    category: model?.category || null,
    createdAt: model?.createdAt || null,
    ext: model?.ext || null,
    id: model?.id || null,
    uid: model?.uid || null,
    mimeType: model?.mimeType || null,
    name: model?.name || null,
    ownable: model?.ownable || null,
    contentSize: model?.contentSize || 0,
    fileSize: model?.fileSize || 0
  };
}

export function setDocumentFromRequest(model = {}) {
  const document = Document();
  document.uid = model?.uid;
  document.canDelete = model?.can_delete;
  document.category = model?.category;
  document.createdAt = model?.created_at;
  document.ext = model?.ext;
  document.id = model?.id;
  document.mimeType = model?.mime_type;
  document.name = model?.name;
  document.ownable = model?.ownable;
  document.contentSize = model?.content_size;
  if (document.contentSize) {
    document.fileSize = formatBytes(document.contentSize);
  }
  return document;
}
